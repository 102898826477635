@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Inter', sans-serif !important;
}

.RentalAppliArea {
    width: 1000px;
    background-color: #fff;
    margin: -280px auto 0 auto;
    padding: 64px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.RentalAppliHead {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
}

.RentalAppliHead img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    object-position: center;
    margin-bottom: 25px;
}

.RentalAppliHeadTitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 130%;
    text-align: center;
    color: #2C2C2C;
    margin: 0;
    width: 100%;
}

.FooterWrap {
    width: 100%;
    padding: 0 168px;
}

.footerMain {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 95px 0 75px;
    margin-top: 90px;
}

.footerMainLeft {
    width: 20%;
}

.footerMainLeft img {
    max-width: 240px;
    max-height: 100px;
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: left;
}

.footerMainRight {
    width: 75%;
    padding-top: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.footerMainRight h3 {
    color: #2c2c2c;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 25px;
    line-height: 18px;
    margin: 0;
    letter-spacing: 0.01em;
}

.footerMainRight ul {
    margin: 0;
    padding: 0;
}

.footerMainRight ul li {
    list-style: none;
    color: #2c2c2c;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 3px;
    text-transform: none;
}

.footerMainRight ul li a {
    color: #2c2c2c;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 3px;
    text-transform: none;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    display: inline-block;
}

.footerMainRight ul li a:hover {
    color: #2A5BEE;
}

.RescheduleSec {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.RescheduleImg {
    min-width: 40px;
    width: 50px;
    min-height: 40px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 12px;
    overflow: hidden;
    background-color: #fbf7fc;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-shadow: rgb(99 99 99 / 10%) 0px 2px 8px 0px;
    border: 3px solid #fff;
}

.RescheduleImg .defaultImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.RescheduleImg p {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
    color: #502688;
    letter-spacing: 1px;
}

.ProspectName {
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    margin: 0 0 12px 0;
    color: #505050;
}

.ProspectDetails {
    font-size: 15px;
    font-weight: 500;
    line-height: 150%;
    margin: 0;
    padding: 6px 0;
    color: #505050;
    display: flex;
    align-items: center;
}

.ProspectDetails svg {
    width: 17px;
    height: auto;
    margin-right: 12px;
}

.RescheduleProfileSec {
    width: 300px;
    border-right: 1px solid #e0e0e0;
    padding: 0 30px;
}

.DateTimeSec {
    width: calc(100% - 300px);
    padding: 0 30px;
}

.AddTourPopTT {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #343434;
    margin: 0px 0 0 0;
}

.ModalFormGroup {
    width: 100%;
    padding: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.AddTourModal {
    display: flex;
    flex-wrap: wrap;
}

.AddTourTimeArea {
    width: 250px;
}

.TimeSlotTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #343434;
    margin: 0 0 15px 0;
}

.AddTourTimeArea ul {
    width: 100%;
    margin: 0;
    padding: 0 15px 0 0 !important;
    height: 340px;
    overflow: auto;
}

.ButtonAction {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 40px 30px 0 30px;
}

.CancelPopupBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.CancelPopupBU:hover {
  background: #f1ecf3 !important;
  color: #343434 !important;
  border: 1px solid #e1e1e1 !important;
}

.SubmitPopupBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  background: #2A5BEE !important;
  border: 1px solid #2A5BEE !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin-left: 12px !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.SubmitPopupBU:hover {
  background: #1e47c5 !important;
  border: 1px solid #1e47c5 !important;
}

.RescheduleMessageSec {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 550px;
}

.RescheduleMessageSec img {
    width: 110px;
    margin-bottom: 40px;
}

.RescheduleMessageSec .MessageTitle {
    width: 100%;
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    color: #2c2c2c;
    margin: 0 0 15px 0;
}

.RescheduleMessageSec .MessageText {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #505050;
    margin: 0;
}

.AddTourErrorMessage {
    color: #f44336;
    font-size: 12px;
    margin-bottom: 6px;
}

@media screen and (min-width: 360px) {
    .RentalAppliArea {
        width: 90%;
        padding: 64px 0;
    }
    .FooterWrap {
        width: 90%;
        padding: 0 0px;
        margin: 0 auto;
    }
    .footerMainLeft,
    .footerMainRight {
        width: 100%;
    }
    .footerMainRight div {
        width: 100%;
        text-align: center;
    }
    .footerMainRight ul {
        padding-bottom: 20px;
        padding-left: 0;
    }
    .footerMainLeft img {
        object-position: center;
        margin: 0 auto 60px auto;
    }
}

@media screen and (min-width: 768px) {
    .footerMainRight>div:last-of-type {
        padding-top: 40px;
    }
}

@media screen and (min-width: 992px) {
    .RentalAppliArea {
        width: 950px;
        padding: 64px 0px;
    }
    .FooterWrap {
        width: 100%;
        padding: 0 78px;
        margin: 0 auto;
    }
    .footerMainRight div {
        width: auto;
        text-align: left;
    }
}

@media screen and (min-width: 1200px) {
    .RentalAppliArea {
        width: 1000px;
        padding: 64px 20px;
    }
    .FooterWrap {
        width: 100%;
        padding: 0 168px;
    }
}

@media screen and (min-width: 1366px) {
    .footerWrap {
        padding: 0 168px;
    }
    .footerMainLeft {
        width: 20%;
    }
    .footerMainRight {
        width: 80%;
    }
    .footerMainLeft img {
        object-position: left;
        margin: 0;
    }
}

@media screen and (min-width: 1500px) {
    .footerMainLeft {
        width: 20%;
    }
    .footerMainRight {
        width: 75%;
    }
}

@media screen and (max-width: 991px) {
    .RentalAppliArea {
        padding: 64px 30px;
    }
    .RescheduleProfileSec {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #e0e0e0;
        padding: 30px 0px;
    }
    .DateTimeSec {
        width: 100%;
        padding: 30px 0px;
    }
    .ButtonAction {
        padding: 40px 0px 0 0px;
    }
}

@media screen and (max-width: 700px) {
    .RentalAppliArea {
        padding: 64px 10px;
    }
    .AddTourTimeArea {
        width: 170px;
    }
}

@media screen and (max-width: 575px) {
    .RentalAppliArea {
        padding: 64px 15px;
    }
    .AddTourTimeArea {
        width: 100%;
    }
}