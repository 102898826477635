@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");

.pageTitleWrap {
  width: 100%;
  /* background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07); */
  padding: 1.8rem 1.8rem 2.5rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* z-index: 1; */
  /*margin-bottom: 30px;*/
  /* top: 66px;
  left: auto;
  right: 0;
  position: sticky;
  z-index: 999; */
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem+.breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem+.breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.mainCard {
  padding: 0;
  border-radius: 0.5rem;
  margin: 0 1.8rem;
  background: transparent;
  border: none;
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 0%);
  padding-bottom: 100px !important;
}

.ModalFormGroup {
  width: 100%;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding-bottom: 0;
}

.card .cardbody {
  padding: 1.5rem;
}

.ActionsBU {
  width: auto !important;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #502688 !important;
  margin: 0 !important;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
}

.CalendarActionBU .ActionsBU {
  padding: 11px 20px 11px 15px !important;
  background-color: #ff8271 !important;
}

.CalendarActionBU .ActionsBU:hover {
  background-color: #e16e5f !important;
}

.ActionsBU.WithoutBG {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #333 !important;
  background-color: transparent !important;
  margin: 0;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
}

.ActionsBU.Red {
  background-color: #ff8271 !important;
}

.ActionsBU:after {
  display: none;
}

.ActionsBU:hover,
.btn-check:active + .btn-primary.ActionsBU,
.btn-check:checked + .btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058;
  border: none;
}

.ActionsBU.Red:hover,
.btn-check:active + .btn-primary.ActionsBU.Red,
.btn-check:checked + .btn-primary.ActionsBU.Red,
.ActionsBU.Red.btn-primary.active,
.ActionsBU.Red.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU.Red {
  background-color: #ed7464;
  border: none;
}

.ActionsBU:focus {
  outline: none;
}

.ActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: -2px;
  margin-left: 10px;
}

.CalendarActionBU .ActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 10px;
}

.ActionsBU:after {
  display: none;
}

.ButtonAction {
  padding: 12px 0 0 0;
  justify-content: flex-start;
  display: flex;
  align-items: center;
}

.Width100 {
  width: 100%;
  position: relative;
}

.DahhboardHead {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
}

.SwitchDiv {
  width: 300px !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.SwitchDiv p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
}

.SwitchDiv > div {
  width: 100%;
  font-size: 0.875rem;
}

.EditActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  padding: 10px 10px !important;
  min-width: 80px;
  display: flex;
  align-items: center;
}

.EditActionsBUListItem.Delete,
.EditActionsBUListItem.Delete:hover,
.EditActionsBUListItem.Delete:focus,
.EditActionsBUListItem.Delete:active {
  color: #f44336 !important;
}

.EditActionsBUListItem svg {
  width: 17px;
  height: auto;
  margin-right: 12px;
  stroke-width: 2px;
  color: #343434;
}

.EditActionsBUListItem.Delete svg {
  color: #f44336;
}

.EditActionsBUListItem:hover,
.EditActionsBUListItem:focus,
.EditActionsBUListItem:active {
  color: #343434 !important;
  background: #f8f8f8 !important;
}

.CalendarActionBU {
  position: relative;
}

.AddTourPopTT {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #343434;
  margin: 20px 0 0 0;
}

.AddTourModal {
  display: flex;
  flex-wrap: wrap;
}

.AddTourTimeArea {
  width: 250px;
  position: relative;
}

.AddTourTimeArea ul {
  width: 100%;
  margin: 0;
  padding: 0 15px 0 0 !important;
  height: 340px;
  overflow: auto;
}

.AddTourTimeArea ul li {
  list-style: none;
  width: 100%;
  border: 1px solid #502688;
  border-radius: 8px;
  padding: 10px 16px;
  margin: 0 0 14px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.AddTourTimeArea ul li:hover {
  background-color: rgb(109 77 159 / 10%);
}

.AddTourTimeArea ul li.TimeSelected {
  background-color: #502688;
}

.AddTourTimeArea ul li.TimeSlotBooked {
  border: 1px solid #efefef;
  background-color: #efefef;
  cursor: context-menu;
}

.AddTourTimeArea ul li.TimeSelected .AddTourTime {
  color: #fff;
}

.AddTourTimeArea ul li.TimeSlotBooked .AddTourTime {
  color: #666666;
}

.AddTourTimeArea ul li.TimeSelected .AddTourOpenHouse {
  color: #efefef;
}

.TimeSlotTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #343434;
  margin: 0 0 15px 0;
}

.AddTourOpenHouse {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666;
}

.AddTourOpenHouse svg {
  width: 18px;
  color: #efd763;
  fill: #efd763;
  margin-right: 5px;
}

.AddTourScheduled {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666666;
}

.AddTourScheduled svg {
  width: 18px;
  color: #666666;
  margin-right: 5px;
}

.PopupFilterRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  position: relative;
  padding-right: 15px;
}

.FilterCard {
  width: 48%;
  padding: 12px 0px 12px 0;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterCard.Full {
  width: 100%;
}

.FilterCard.Select > div > div {
  min-height: 42px;
  border-color: #e1e1e1;
  border-radius: 8px;
}

.FullCalendar {
  width: 100%;
  padding: 30px 30px;
}

.AddProspectBU {
  width: auto;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0 0 0;
  border-radius: 8px;
  color: #ff8271;
  text-transform: capitalize;
}

.AddProspectBU svg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.AddprospectFirstStepForm {
  width: 100%;
  padding-top: 20px;
}

.AddprospectFirstStepFormTitle {
  font-size: 16px;
  font-weight: 500;
  color: #343434;
  line-height: 21px;
}

.FirstStepFormFullSec {
  width: 100%;
  padding-bottom: 20px;
}

.HalfStepFormFullSec {
  width: 48%;
  padding-bottom: 20px;
}

.HalfStepFormRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.CalendarEditPopup {
  width: 100%;
  padding: 10px 0;
}

.CalendarEditPopup ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.CalendarEditPopup ul li {
  list-style: none;
  padding: 6px 0;
  display: flex;
  align-items: center;
}

.CalendarEditPopup ul li .CalendarEditPopupTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  color: #666666;
  min-width: 110px;
}

.CalendarEditPopup ul li .CalendarEditPopupCon {
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  color: #343434;
  position: relative;
  text-decoration: none;
  transition: all .3s ease-in-out;
}

.CalendarEditPopup ul li a.CalendarEditPopupCon:hover {
  color: #f48272;
}

.CalendarEditPopup ul li a.CalendarEditPopupCon:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  background: #fff;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  transition: all .3s ease-in-out;
}

.CalendarEditPopup ul li a.CalendarEditPopupCon:hover:after {
  width: 100%;
  background: #f48272;
}

.AddTourErrorMessage {
  color: #f44336;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  bottom: -15px;
}

.ChatIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  margin-left: 12px;
  padding: 3px !important;
  background: transparent !important;
}

.ChatIcon:focus {
  outline: none;
}

.ChatIcon svg {
  width: 100% !important;
  height: auto !important;
  fill: #cfd3d7;
  stroke: #fff;
  stroke-width: 2.5 !important;
}

@media screen and (min-width: 360px) {
  .mainCard {
    margin: 0 1.8rem;
  }
}

@media screen and (min-width: 576px) {
  .mainCard {
    margin: 0 1.8rem;
  }
}

@media (max-width: 959px) {
  .pageTitleWrap {
    padding: 1rem 1.8rem 2rem 1.8rem;
  }
  .breadcrumb {
    display: none;
  }
}

@media (max-width: 479px) {
  .pageTitleWrap {
    padding: 1rem 0.8rem 1.5rem 0.8rem;
  }
  .pageTitle {
    width: 100%;
    order: 2;
  }
  .breadcrumb {
    width: 100%;
    order: 1;
    padding-bottom: 12px;
  }
  .mainCard {
    margin: 0 0rem;
  }
  .DahhboardHead {
    padding: 0 0.8rem;
  }
  .card {
    border-radius: 0rem;
    margin-bottom: 0rem;
    border: none;
    box-shadow: unset;
  }
  .FullCalendar {
    padding: 15px 16px;
  }
}
