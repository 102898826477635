.pageTitleWrap {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07);
  padding: 1rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  top: 66px;
  left: auto;
  right: 0;
  position: sticky;
  z-index: 999;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem + .breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem + .breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.mainCard {
  padding: 0;
  border-radius: 0.5rem;
  margin: 0 1.8rem;
  /* position: relative; */
  background: transparent;
  border: none;
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 0%);
  padding-bottom: 90px !important;
  /* float: left; */
}

.SMReferDeBigDiv {
  width: 100%;
  padding: 0 0px;
  /* float: left; */
}

.Card {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin-bottom: 0;
  /* position: relative; */
  background: white;
  border: none;
  box-shadow: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding-bottom: 0;
}

.cardbody {
  padding: 1.5rem;
}

.ActionRefMain {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 15px;
}

.TitleSummary {
  display: flex;
  align-items: center;
  width: 100%;
}

.TitleSummary p.Title {
  color: #343434;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.18px;
  margin: 0;
}

.InsideConCheck {
  /*width: 25%;*/
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px;
}

.SMBigSideBody {
  position: relative;
}

.BorderDivSumm {
  width: 100%;
  margin-top: 30px;
  /* border: 1px solid #e5e8eb;
    border-radius: 6px;
    padding: 15px 25px;
    margin: 15px 0 30px 0; */
}

.Row {
  display: flex;
  flex-wrap: wrap;
  margin: -8px -8px;
}

.SummFormFull {
  width: 100%;
  padding: 15px 0px;
}

.SummFormFull p {
  font-size: 0.875rem;
  margin: 0;
}

.SummFormSemiHalf {
  width: 25%;
  padding: 8px 8px;
  position: relative;
}

.SummFormSemiHalf button {
  position: absolute;
  background: #eb5328;
  color: #fff;
  border-radius: 50%;
  top: 1px;
  right: 1px;
  min-width: 20px;
  min-height: 20px;
  text-align: center;
  padding: 0;
  width: 25px;
  height: 25px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SummFormSemiHalf button svg {
  width: 18px;
  height: 18px;
  margin-top: 0;
}

.SummFormSemiHalf img {
  border-radius: 8px;
  width: 100%;
  height: 160px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  /* box-shadow: 0 0 5px 0 #3333333b; */
}

.SummFormLabel {
  color: #333;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 18px;
  margin: 0 0 8px 0 !important;
}

.SummFormLabelForm {
  font-size: 13px;
  line-height: 28px;
  font-weight: 500;
  color: #333;
  margin: 0 0 5px 0;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 50px;
  height: 44px;
  padding: 10px 13px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.FormConTwo {
  display: block;
  width: 50%;
  min-width: 50px;
  height: 44px;
  padding: 10px 13px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
}

.FormConThree {
  display: block;
  width: 60%;
  min-width: 50px;
  height: 44px;
  padding: 10px 13px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
}

.FormConTwo::placeholder,
.FormConThree::placeholder,
.formControl::placeholder {
  color: #bfbfbf;
  opacity: 1;
}

.FormConTwo::-ms-input-placeholder,
.FormConThree::-ms-input-placeholder,
.formControl::-ms-input-placeholder {
  color: #bfbfbf;
}

.formControlTextArea {
  display: block;
  width: 100%;
  min-width: 150px;
  height: 120px;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #201d1d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
}

.ReferExpMain {
  display: table;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  margin: 15px 0;
  /* overflow: hidden; */
  width: 100%;
}

.ReferBodySummCommNote {
  display: flex;
  padding-top: 8px;
}

.ReferBodySummCommNote img {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
}

.ReferBodySummCommBody {
  width: 100%;
  padding-left: 25px;
}

.NoteListTitle {
  display: flex;
}

.SummCommBodyName {
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  color: #230058;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-right: 70px;
}

.SummCommBodyName span {
  color: #9f9f9f;
  padding-left: 8px;
  font-weight: 500;
}

.NoteListIcons {
  display: flex;
  /* position: absolute;
  top: 22px;
  right: 30px; */
}

.NoteListIcons svg {
  width: 18px;
  height: 18px;
  color: #333;
  stroke-width: 1;
  transition: all 0.3s ease-in-out;
}

.RateStarDiv svg {
  width: 18px;
  height: 18px;
  color: #333;
  stroke-width: 1;
  transition: all 0.3s ease-in-out;
}

.StarNote:hover svg {
  color: #ff8271;
  fill: #ff8271;
}

.StarNoteActive svg {
  color: #ff8271;
  fill: #ff8271;
}

.RateStarDiv {
  margin-bottom: 15px;
}

.RateStarDiv a {
  margin-right: 3px;
}

.FilesUpCardDD,
.FilesUpCardDD:active,
.FilesUpCardDD:checked,
.FilesUpCardDD:hover,
.FilesUpCardDD:focus {
  position: absolute !important;
  right: 0;
  top: 0;
  min-width: 20px;
  margin: 0;
  padding: 0 !important;
  background-color: transparent !important;
  color: #000 !important;
  border: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.FilesUpCardDD.NoPadd,
.FilesUpCardDD.NoPadd:active,
.FilesUpCardDD.NoPadd:checked,
.FilesUpCardDD.NoPadd:hover,
.FilesUpCardDD.NoPadd:focus {
  padding: 0 !important;
}

.FilesUpCardDD:after {
  display: none !important;
}

.FilesUpCardDD svg {
  width: 18px;
  stroke-width: 2;
}

.DropDownMain {
  background: none;
  border: none;
  padding: 0 0 0 15px;
  margin-top: -4px;
  width: 35px;
}

.SummCommBodyDate {
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  color: #a7abc3;
}

.SummCommBodyText {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: #505050;
  margin-bottom: 0;
}

.NotesCommentIconsec {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.NotesCommentLink {
  font-size: 13px;
  font-weight: 500;
  color: #230058;
  line-height: normal;
  margin-bottom: 0;
}

.NotesCommentLink svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  stroke-width: 1;
  color: #a9a9a9;
  margin-bottom: 0px;
}

.ReferNoteSec {
  width: 100%;
  flex-wrap: wrap;
  padding: 17px 48px 17px 90px;
}

.ReffScrollDiv {
  width: 100%;
  max-height: 800px;
  overflow: auto;
  margin-bottom: 15px;
  padding-right: 8px;
}

.ReffScrollDiv .ReferBodySummCommNotes {
  width: 100%;
}

.ReferBodySummCommNotes {
  display: flex;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  /* overflow: hidden; */
  padding: 25px;
  width: 100%;
  margin: 15px 0;
  position: relative;
}

.ReferBodySummCommNotes img {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
}

.formGroup {
  margin-bottom: 12px;
}

.formGroup p {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 11px;
  color: #dc3545;
}

.ErrorM {
  width: 100%;
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-size: 11px;
  line-height: normal;
  color: #dc3545;
}

.errorSpan {
  color: #ff000d;
  font-weight: bold;
  margin-top: 0.25rem;
  font-size: 13px;
}

.formGroupLabel {
  margin-bottom: 8px;
  display: block;
}

.formGroupTextArea {
  width: 100%;
  resize: none;
  height: 150px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px 10px;
}

.ReferCommentArea {
  width: 100%;
  border: 1px solid #e5e8eb !important;
  border-radius: 6px;
  height: 100px;
  padding: 6px 10px;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  color: #343434;
}

.ReferSubmitBuNotes {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 40px;
  color: #fff;
  background: #502688;
  border-radius: 8px;
  border: none;
  padding: 0 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ReferSubmitBuNotes:hover {
  background: #230058;
}

.ReferCancelBuNotes {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 40px;
  color: #fff;
  background: #ff8271;
  border-radius: 8px;
  border: none;
  padding: 0 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ReferCancelBuNotes:hover {
  background: #e97565;
}

.HistoryTitle {
  padding: 10px 0px;
  display: flex;
  align-items: center;
}

.HistoryTitle img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.HistoryTitleCon {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.HistoryTitleCon span {
  font-size: 14px;
  color: #a7abc3;
  font-weight: 400 !important;
  margin-left: auto;
}

.BorderDivSummHis {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 5px 0 15px 0;
}

.BorderDivSummComm {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
}

.StatusChangeBU {
  font-size: 13px;
  font-weight: 500;
  background: #999;
  color: #fff;
  padding: 4px 10px;
  margin-left: 8px;
  border-radius: 24px;
  line-height: 17px;
  letter-spacing: 0.14px;
}

.StatusChangeBU svg {
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
  margin-right: 5px;
}

.YellowBu {
  background: #FFF3E8;
  color: #E99A37;
}

.GreenBu {
  background: #E8FFF2;
  color: #5AC55E;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border: 1px solid #efefef;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.searchInput {
  width: 100%;
  color: #404040;
  border: none;
  font-size: 13px;
  font-weight: 400;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  color: #404040;
  width: 18px;
  margin-right: 12px;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: -webkit-flex;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  height: 38px;
  margin-left: 15px;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
  background-color: #e97565;
  color: #fff;
}

.AddNoteBu svg {
  width: 20px;
  height: 20px;
}

.AddCommDiv {
  margin: 15px 0;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 15px 15px 15px;
}

.SMProCusSmallDiv {
  width: 450px;
  padding: 0 0px;
}

.SMProCusSmallDiv .Card {
  overflow: hidden;
  padding: 10px 0 0 0;
}

.CaseSummSideUL {
  padding: 0;
  margin: 0;
}

.CaseSummSideUL li {
  list-style: none;
  border-bottom: 0px solid #e5e8eb;
  padding: 10px 12px;
}

.CaseSummSideUL li.Dropdown {
  padding: 10px 5px 20px 5px;
  font-size: 0.875rem;
}

.CaseSummSideUL li:last-child {
  border-bottom: none;
}

.ULtitle {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #94c5df;
  margin: 0 0 6px 0;
}

.ULData {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #f2f2f2;
  margin: 0;
}

.ULData a,
.ULData a:hover {
    color: #ff8271;
    border-bottom: 1px solid;
    padding-bottom: 2px;
}

.TagDiv {
  font-size: 13px;
  font-weight: 500;
  background: #999;
  color: #fff;
  padding: 5px 10px;
  margin-left: 8px;
  border-radius: 24px;
  line-height: 17px;
  letter-spacing: 0.14px;
}

.TagDiv.Red {
  background: #ffe8e8;
  color: #e23725;
}

.ButtonVoiletSolid {
  padding: 14px 25px;
  background-color: #502688;
  margin: 0px 15px 0 0;
  font-size: 13px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #502688;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolid:hover {
  background-color: #884ba7;
  border: 1px solid #884ba7;
}

.ButtonVoiletLine {
  padding: 14px 25px;
  background-color: #fff;
  margin: 0px 15px 0 0;
  font-size: 13px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #502688;
  border: 1px solid #502688;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletLine:hover {
  background-color: #502688;
  color: #fff;
}

.DeEditBU {
  margin-left: 15px;
}

.DeEditBU svg {
  width: 18px;
  height: 18px;
  color: #8a8a8a;
}

.UploadLabel {
  padding: 22px 15px 22px 15px;
  background-color: transparent;
  font-size: 14px;
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  font-weight: 600;
  align-content: center;
  justify-content: center;
  line-height: 18px;
  border-radius: 0;
  color: #502688;
  text-align: center;
  vertical-align: middle;
  /* border: 1px dashed #ff82717a; */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23502688FF' stroke-width='2' stroke-dasharray='5%2c 8' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  /* padding-left: CalC(50% - 50px); */
  position: relative;
}

/* .UploadLabel span {
    width: 100%;
} */

/* .UploadLabel:hover {
  background-color: #230058;
} */

.UploadLabel svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.UploadLabel div {
  display: none;
}

.UploadLabel span {
  width: 100%;
}

.UploadLabel div.UpIcon {
  display: block;
  margin-bottom: 12px;
}

.UploadLabel div.UpIcon img {
  width: 40px;
  height: 40px;
}

.UploadText {
  color: #666;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.12px;
  margin: 10px 0 0 0;
}

.FileUpDuBUDiv {
  padding: 8px 8px;
  width: 25%;
}

.FileUpDuBUDiv input {
  display: none;
}

.NoDataMain {
  width: 100%;
  margin: 50px 0;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 45px;
  margin: auto;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 30px 0 !important;
  font-weight: 700;
  color: #e5e5e5;
  line-height: unset;
  text-transform: capitalize;
}

.LoaderCard {
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding: 0;
  margin: 1.5rem 0;
}

.AppointmentsHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.AppoAddTab {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AppoDropDown {
  width: 32%;
  margin-bottom: 15px;
}

.NotesRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.NotesCard {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 10px 0;
  /*overflow: hidden;*/
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.06);
  transition: all 0.3s ease-in-out;
}

.NotesCard:hover {
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.15);
}

.PropeCardInFR {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
  position: relative;
}

.MainReqConDivBig {
  padding: 0 15px;
  width: 37%;
  display: flex;
  flex-wrap: wrap;
}

.InsideConName {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 0;
  padding: 8px 0px 8px 0;
  /* min-width: 350px; */
}

.InsideConName span {
  color: #b7b7b7;
  font-weight: 500;
}

.MainReqConDivSmall {
  padding: 0 15px;
  width: 37%;
}

.MainReqConUR {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.MainReqConDe {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 8px 15px 8px 0;
  text-align: left;
  color: #666;
  display: flex;
  width: 50%;
}

.MainReqConDe span {
  color: #999;
  padding-right: 5px;
}

.MainReqConUR .MainReqConDe:last-child {
  padding-right: 0px;
}

.MainReqConAction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 25%;
  justify-content: flex-end;
  padding: 0 15px;
}

.NotesRow a,
.NotesRow a:hover,
.NotesRow button,
.NotesRow button:hover {
  margin-left: auto;
  color: #666;
  padding: 0;
  min-width: 10px;
}

.NotesRow a svg,
.NotesRow button svg {
  width: 18px;
  height: 18px;
}

.DesText {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: #505050;
  margin-bottom: 0;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 2px !important;
}

.BackArrow {
  color: #505055 !important;
  background: #fff;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  margin-right: 16px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

/* .BackArrow:after {
  content: '';
  position: absolute;
  width: Calc(100% - 25px);
  height: 1px;
  background: #333;
  bottom: 0px;
  right: 0;
  opacity: 0;
  transition: all .2s ease-in-out;
} */

.BackArrow:hover {
  color: #333 !important;
}

/* .BackArrow:hover:after {
  opacity: 1;
} */

.BackArrow svg {
  width: 20px;
  height: 20px;
}

.EditBUDe {
  width: 100%;
  padding: 10px 16px;
  background: #502688;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  margin: 10px 0 0 0;
}

.CustomSelect > div {
  border-radius: 8px;
  border-color: #e1e1e1;
  min-height: 44px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding-left: 0px;
  z-index: 99;
}

.BigFilterCard {
  width: auto;
  min-width: 170px;
  max-width: 300px;
  padding: 0 8px;
}

button.TableAddBUSecondary {
  align-items: center;
  padding: 10px 13px 10px 16px;
  background: #fff !important;
  display: flex;
  min-height: 0;
  border: 1px solid #502688 !important;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 1.5;
  color: #502688 !important;
  margin: 0 !important;
  margin: 0 0px !important;
  border-radius: 8px;
}

button.TableAddBUSecondary .ColorRed {
  color: #eb534a;
  padding-left: 5px;
}

button.TableAddBUSecondary:hover,
button.TableAddBUSecondary:active {
  background: #fff !important;
  color: #502688 !important;
  border: 1px solid #502688 !important;
}

button.TableAddBUSecondary:after {
  display: none;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}

.BigFilterCardDBu {
  display: flex !important;
  flex-wrap: wrap;
  padding: 8px 16px !important;
  margin-top: 8px !important;
  width: 150px !important;
}

.BigFilterCardDBu a {
  width: 100%;
  display: inline-block;
  color: #343434;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 8px 0;
}

.DisFlex {
  display: flex;
}

.ActionsBU {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #502688 !important;
  margin: 0;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
}

.ActionsBU:after {
  display: none;
}

.ActionsBU:hover,
.btn-check:active + .btn-primary.ActionsBU,
.btn-check:checked + .btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058;
  border: none;
}

.ActionsBU:focus {
  outline: none;
}

.ActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: -2px;
  margin-left: 10px;
}

.ActionsBU:after {
  display: none;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500;
  color: #343434;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 10px;
}

.ActionsBUListItem.Delete,
.ActionsBUListItem.Delete:hover,
.ActionsBUListItem.Delete:focus {
  color: #f97665;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus {
  color: #343434;
  background: #f8f8f8;
}

.ActionsBUListItem:last-child {
  border: none;
}

.EditActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 10px 10px !important;
  min-width: 80px;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif !important;
}

.EditActionsBUListItemSmall {
  font-size: 13px;
  padding: 8px 10px !important;
}

.EditActionsBUListItem.Delete,
.EditActionsBUListItem.Delete:hover,
.EditActionsBUListItem.Delete:focus,
.EditActionsBUListItem.Delete:active {
  color: #f44336 !important;
}

.EditActionsBUListItem svg {
  width: 17px;
  height: auto;
  margin-right: 12px;
  stroke-width: 2px;
  color: #343434;
}

.EditActionsBUListItem.Delete svg {
  color: #f44336;
}

.EditActionsBUListItem:hover,
.EditActionsBUListItem:focus,
.EditActionsBUListItem:active,
.EditActionsBUListItemSmall:hover,
.EditActionsBUListItemSmall:focus,
.EditActionsBUListItemSmall:active {
  color: #343434 !important;
  background: #f8f8f8 !important;
}

.FilterBU {
  width: auto;
  padding: 0 0 0 8px;
}

.SummeryTitle {
  width: auto;
  background-color: #fff;
  border: none;
  display: flex;
  align-items: center;
  padding: 15px 0;
  color: #502688;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.18px;
}

.SummeryTitle img {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.SummeryTitle svg {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}

.WorkOrderCard {
  width: 100%;
  padding: 15px 0;
}

.WorkOrderCardUnder {
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #f5f5f5;
}

.WorkOrderCardHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.WorkOrderCardHead .TitleSec {
  display: flex;
  /* align-items: center; */
}

.WorkOrderCardHead .TitleSec img {
  width: 20px;
  height: 24px;
  margin-right: 12px;
}

.WorkOrderCardHead .TitleSec p {
  color: #343434;
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.18px;
  margin: 0;
}

.WorkOrderAddBU {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  padding: 10px 16px;
  border-radius: 8px;
  background: #ff8271;
  display: flex;
  align-items: center;
  border: none;
}

.WorkOrderAddBU svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.WorkOrderCardBody {
  width: 100%;
  padding-top: 10px;
  overflow: auto;
}

.WorkOrderTable {
  width: 100%;
}

.WorkOrderTable.Table1 {
  min-width: 400px;
}

.WorkOrderTable.Table2 {
  min-width: 600px;
}

/* .WorkOrderTable thead tr th:nth-child(1) {
    width: 20%;
}

.WorkOrderTable thead tr th:nth-child(2) {
    width: 20%;
}

.WorkOrderTable thead tr th:nth-child(3) {
    width: 40%;
}

.WorkOrderTable thead tr th:nth-child(4) {
    width: 20%;
} */

.WorkOrderTable tr th {
  padding: 11px 10px;
  color: #230058;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.WorkOrderTableBody {
  padding: 16px 10px;
  border-top: 1px solid #e3e3e4;
  background: #fbf7fc;
}

.WorkOrderTable tbody tr:nth-child(even) td.WorkOrderTableBody {
  background: #fff;
}

.WorkOrderTableBody div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.WorkOrderTableBody div.Action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.WorkOrderTableBody div p {
  color: #505050;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}

.WorkOrderTableBody div.Action a,
.WorkOrderTableBody div.Action button {
  display: inline-block;
  margin-left: 5px;
  color: #5b5a5b;
  background-color: transparent;
}

.WorkOrderTableBody div.Action a svg,
.WorkOrderTableBody div.Action button svg {
  width: 20px;
  height: 20px;
}

.WorkOrderReceiptLink {
  color: #502688;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: underline !important;
  display: flex;
  align-items: center;
}

.WorkOrderReceiptLink svg {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.WorkOrderReceiptLink:hover {
  color: #230058;
  text-decoration: underline !important;
}

.RequestBodyPop {
  overflow: unset !important;
}

.ModalFormGroup {
  width: 100%;
  padding: 16px 0;
}

.PopFormGroup {
  width: 100%;
  padding: 0;
  position: relative;
  z-index: 9;
}

.PopFormGroupTwo {
  width: 100%;
  padding: 0;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.UploadReceiptBU {
  color: #ff8271 !important;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 21px;
  padding: 10px 10px 10px 0 !important;
  border-radius: unset !important;
  background-color: #fff !important;
}

.UploadReceiptBU svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.TimePick {
  /* border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 0.45rem 16px; */
  width: 100%;
  /* height: 44px;
  position: relative; */
  /* margin: 8px 0; */
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #495057;
}

.TimePick > div {
  position: relative;
  width: 100%;
  /* z-index: 2; */
}

/* .TimePick > div > div {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #495057;
  margin: 0;
} */

.DatePick,
.DatePick > div {
  width: 100%;
}

.Width36 {
  width: 36% !important;
  padding: 10px 0 !important;
}

.Width48 {
  width: 48% !important;
  padding: 10px 0 !important;
}

.Width40 {
  width: 40% !important;
  padding: 10px 0 !important;
}

.Width26 {
  width: 27% !important;
  padding: 10px 0 !important;
}

.FormGroupFull {
  width: 100% !important;
  padding: 10px 0 !important;
}

.FormGroupHalf {
  width: 48% !important;
  padding: 0px 0 !important;
}

.FormFullSec {
  width: 60% !important;
  padding: 10px 0 !important;
}

.ProfileCardEditSec {
  width: 100%;
  padding: 25px 0 0px 0;
}

.ProfileCardEditSec form {
  width: 100%;
}

.ProfileCardEditSec .FormGroupFull {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
  padding: 0 0px 0px 0px;
  font-weight: 400;
  color: #919191;
  margin-bottom: 22px;
}

.ProfileCardEditSec .FormGroupFull.FormTimeZone {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.ProfileCardEditSec .TimePick {
  width: 48%;
  position: relative;
  margin: 0px 0;
}

.ProfileCardEditSec .TimePick svg.ClockIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 7px;
  color: #585858;
  transform: translate(0px, -50%);
}

.ProfileCardEditSec .TimeP {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: #bfbfbf;
}

.ProfileCardEditSec .ButtonArea {
  display: flex;
  padding: 40px 0px 10px 0px;
}

.ProfileCardEditSec .ButtonVoiletSolid {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  padding: 10px 16px !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 0.5rem !important;
  margin: 0 !important;
  border-bottom-right-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
}

.ProfileCardEditSec .ButtonVoiletSolid:hover {
  background: #e16e5f !important;
}

.ProfileCardEditSec .ButtonVoiletLine {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  padding: 10px 16px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0.5rem !important;
  margin: 0 15px 0 0 !important;
  border-bottom-left-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
}

.ProfileCardEditSec .ButtonVoiletLine:hover {
  background: #f1ecf3 !important;
}

.NoDataMainWithBU {
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: relative;
  box-shadow: 0px 0px 20px 0px #f5f5f5;
}

.NoDataMainWithBU>div {
  width: 450px;
  display: block;
  text-align: center;
}

.NoDataIMGWithBU {
  width: 100px;
  height: 100px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 22px;
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoDataIMGWithBU img {
  width: 100%;
  opacity: .4;
}

.NoDataTextWithBU {
  text-align: center;
  font-size: 28px;
  padding: 0px 0 !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
  margin: 0 0 8px 0;
}

.NoDataTextSubWithBU {
  text-align: center;
  font-size: 18px;
  padding: 0px 0 !important;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.AddLeaseBUWithBU {
  padding: 9px 25px 9px 21px;
  background-color: #ff8271;
  margin: 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.AddLeaseBUWithBU:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.AddLeaseBUWithBU svg {
  width: 22px;
  margin-right: 7px;
}

.BreadcrumbHeadSec {
  width: 100%;
  padding: 0 0px 25px 0px;
}

.PageTitleSec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 0 0;
  position: relative;
}

.PageTitleSec>div {
  display: flex;
  align-items: center;
}

.SMProCusRow {
  margin: 35px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  /* float: left; */
  width: -webkit-fill-available;
  width: -moz-available;
}

.CardSmall {
  width: 100%;
  height: 100%;
  background: #406b81;
  padding: 15px 5px;
  position: relative;
}

.RequestCarouselSec {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 35px;
}

.ProEditSec {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.EditActionsBU {
  width: 40px !important;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #39414d !important;
  background-color: rgb(255 255 255 / 90%) !important;
  margin: 0 !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.EditActionsBU:hover {
  background-color: rgb(255 255 255 / 100%) !important;
  color: #39414d !important;
}

.EditActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 24px;
  height: 24px;
  margin-bottom: 0;
  margin-left: 0;
}

.BackAddBU {
  width: 35px;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 4px;
  color: #505050 !important;
  background: #f0f0f0 !important;
}

.BackAddBU svg {
  width: 20px !important;
  height: auto !important;
  stroke-width: 2 !important;
}

.ButtonAction {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.ButtonSec {
  width: 100%;
}

.AddBU {
  padding: 15px 25px;
  background-color: #ff8271;
  margin: 5px 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #ff8271 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.AddBU:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.CancelBU {
  padding: 15px 25px;
  background-color: #fff;
  margin: 5px 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #343434;
  border: 1px solid #e1e1e1 !important;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.CancelBU:hover {
  background-color: #f1ecf3;
  /* border: 1px solid #230058;
  color: #fff; */
}

.GropSelectTitle {
  font-size: 12px !important;
  color: #bcbcbc !important;
  text-transform: uppercase;
  margin-bottom: -8px;
  margin-top: 5px;
}

.GropSelectList {
  padding: 8px 16px !important;
}

.GropSelectList:last-child {
  margin-bottom: 15px !important;
}

.GropSelectList img {
  width: 22px;
  height: 20px;
  margin-right: 12px;
}

.SummaryUpdateBU {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  background: #ff8271;
  border: 1px solid #ff8271;
  border-radius: 8px;
  padding: 10px 16px;
  margin-top: 12px;
  text-transform: capitalize;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.SummaryUpdateBU:hover {
  background: #e16e5f;
  border: 1px solid #e16e5f;
}

.UploadFileList {
  width: 100%;
  display: flex;
  align-items: center;
}

.UploadFileList svg {
  min-width: 18px;
  stroke-width: 2px;
}

.UploadFileList p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #656565;
  margin: 0 0 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UploadFileList button {
  margin-left: 8px !important;
}

.UploadFileList button svg {
  width: 22px !important;
}

.MainReqConDropBU {
  padding: 0 10px;
  padding-right: 0 !important;
  margin-left: auto;
}

.ReferStatusBU {
  padding: 5px 8px 5px 13px !important;
  background: #e2e2e2 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  /* color: #9f9f9f; */
  transition: all 0.3s !important;
  margin: 0 !important;
  line-height: unset !important;
  /* height: 26px !important; */
  display: flex;
  align-items: center;
}

.ReferStatusBUNew {
  padding-right: 10px !important;
}

.ReferStatusBU:after {
  display: none !important;
}

.ReferStatusBU:focus {
  border: none;
  box-shadow: none !important;
}

.ReferStatusBU svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-right: 5px;
}

.ReferStatusBU svg.DownArrowStatus {
  width: 18px;
  height: 18px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.ReferStatusBU.StatusBuRed,
.ReferStatusBU.StatusBuRed:active,
.ReferStatusBU.StatusBuRed:checked {
  background: #ffe8e8 !important;
  color: #e23725 !important;
  border-color: #ffe8e8 !important;
}

.ReferStatusBU.StatusBuRed:hover {
  background: #e23725 !important;
  color: #fff !important;
  border-color: #e23725 !important;
}

.ReferStatusBU.StatusBuYellow,
.ReferStatusBU.StatusBuYellow:active,
.ReferStatusBU.StatusBuYellow:checked {
  background-color: #fff3e8 !important;
  border-color: #fff3e8 !important;
  color: #e99a37 !important;
}

.ReferStatusBU.StatusBuYellow:hover {
  background-color: #e99a37 !important;
  border-color: #e99a37 !important;
  color: #fff !important;
}

.ReferStatusBU.StatusBuGreen,
.ReferStatusBU.StatusBuGreen:active,
.ReferStatusBU.StatusBuGreen:checked {
  background-color: #e8fff2 !important;
  border-color: #e8fff2 !important;
  color: #5ac55e !important;
}

.ReferStatusBU.StatusBuGreen:hover {
  background-color: #5ac55e !important;
  border-color: #5ac55e !important;
  color: #fff !important;
}

.ReferStatusBU.Open,
.ReferStatusBU.Open:active,
.ReferStatusBU.Open:checked {
  background-color: rgba(161, 105, 198, 0.3) !important;
  border-color: rgba(161, 105, 198, 0.3) !important;
  color: #502688 !important;
}

.ReferStatusBU.Open:hover {
  background-color: #502688 !important;
  border-color: #502688 !important;
  color: #fff !important;
}

.ReferStatusBU.InProgress,
.ReferStatusBU.InProgress:active,
.ReferStatusBU.InProgress:checked {
  background-color: #fff3e8 !important;
  border-color: #fff3e8 !important;
  color: #e99a37 !important;
}

.ReferStatusBU.InProgress:hover {
  background-color: #e99a37 !important;
  border-color: #e99a37 !important;
  color: #fff !important;
}

.ReferStatusBU.Resolved,
.ReferStatusBU.Resolved:active,
.ReferStatusBU.Resolved:checked {
  background-color: #e8fff2 !important;
  border-color: #e8fff2 !important;
  color: #5ac55e !important;
}

.ReferStatusBU.Resolved:hover {
  background-color: #5ac55e !important;
  border-color: #5ac55e !important;
  color: #fff !important;
}

.ReferStatusBU.Cancelled,
.ReferStatusBU.Cancelled:active,
.ReferStatusBU.Cancelled:checked {
  background: #ffe8e8 !important;
  color: #e23725 !important;
  border-color: #ffe8e8 !important;
}

.ReferStatusBU.Cancelled:hover {
  background: #e23725 !important;
  color: #fff !important;
  border-color: #e23725 !important;
}

.SummeryHead {
  display: flex;
  align-items: center;
}

.ArchivedBadge {
  background-color: #dfe2e6;
  color: #595959 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 6px 12px !important;
  border-radius: 15px;
  margin: 0 0 0 auto;
  height: 28px;
  line-height: normal;
  white-space: nowrap;
  display: inline-block;
}

@media screen and (min-width: 360px) {
  .mainCard {
    margin: 0 0;
  }
  .breadcrumb li.breadcrumbItem.active {
    max-width: 19ch;
  }
  .ReferExpMain {
    display: block;
  }

  .ReferBodySummCommNote,
  .ReferBodySummCommNotes {
    flex-wrap: wrap;
  }

  .ReferBodySummCommNotes {
    padding: 10px;
  }

  .ReferBodySummCommBody {
    padding-left: 0px;
    padding-top: 0px;
  }

  .SummCommBodyName {
    padding-right: 0px;
  }

  .ReferNoteSec {
    padding: 17px 17px 17px 17px;
  }

  .SummFormSemiHalf {
    width: 50%;
  }

  .HistoryTitleCon {
    display: grid;
  }

  .HistoryTitleCon span {
    margin-left: unset;
  }

  .HistoryTitle {
    align-items: unset;
  }

  .ButtonVoiletSolid,
  .ButtonVoiletLine {
    width: 100%;
  }

  .FileUpDuBUDiv {
    width: 50%;
  }

  .NotesCard {
    padding: 15px 15px;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConAction {
    width: 100%;
  }

  .NotesRow a,
  .NotesRow button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .AppoDropDown {
    width: 100%;
  }

  .ButtonVoiletLine {
    margin: 15px 0px 15px 0;
  }

  .InsideConName {
    padding: 8px 30px 8px 0;
  }
  .BreadcrumbHeadSec {
    padding: 0 15px 25px 15px;
  }
  .SMProCusSmallDiv {
    min-width: 100%;
    max-width: 100%;
  }

  .SMReferDeBigDiv {
    width: 100%;
  }
  .cardbody {
    padding: 1rem;
  }
}

@media screen and (min-width: 576px) {
  .mainCard {
    margin: 0 1.8rem;
  }
  .breadcrumb li.breadcrumbItem.active {
    max-width: 25ch;
  }
  .ReferBodySummCommNotes {
    padding: 15px;
  }

  .SummFormSemiHalf {
    width: 50%;
  }

  .HistoryTitleCon {
    display: flex;
  }

  .HistoryTitleCon span {
    margin-left: auto;
  }

  .HistoryTitle {
    align-items: center;
  }

  .ButtonVoiletSolid,
  .ButtonVoiletLine {
    width: auto;
  }

  .FileUpDuBUDiv {
    width: 50%;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  .ButtonVoiletLine {
    margin: 0px 15px 0 0;
  }
  .BreadcrumbHeadSec {
    padding: 0 0px 25px 0px;
  }

  .SummFormSemiHalf {
    width: 33.33%;
  }

  .FileUpDuBUDiv {
    width: 33.33%;
  }
}

@media screen and (min-width: 768px) {
  .ReferBodySummCommNote,
  .ReferBodySummCommNotes {
    flex-wrap: nowrap;
  }

  .ReferBodySummCommBody {
    padding-left: 25px;
    padding-top: 0px;
  }

  .SummCommBodyName {
    padding-right: 70px;
  }

  .ReferNoteSec {
    padding: 17px 48px 17px 90px;
  }

  .MainReqConDivBig {
    width: 40%;
  }

  .MainReqConDivSmall {
    width: 50%;
  }

  .MainReqConAction {
    width: 10%;
  }

  .NotesRow a,
  .NotesRow button {
    position: relative;
    top: 0;
    right: 5px;
  }

  .AppoDropDown {
    width: 32%;
  }

  .InsideConName {
    padding: 8px 0px 8px 0;
  }
  .cardbody {
    padding: 1.5rem;
  }
}

@media screen and (min-width: 992px) {
  .ReferExpMain {
    display: table;
  }

  .MainReqConDivBig {
    width: 37%;
  }

  .MainReqConDivSmall {
    width: 50%;
  }

  .MainReqConAction {
    width: 13%;
  }
  .SummFormSemiHalf {
    width: 25%;
  }

  .FileUpDuBUDiv {
    width: 25%;
  }
}

@media screen and (min-width: 1100px) {
  .NotesCard {
    width: 49%;
  }
  .SMProCusSmallDiv {
    min-width: 280px;
    max-width: 280px;
  }

  .SMReferDeBigDiv {
    width: Calc(100% - 280px);
  }
}

@media screen and (min-width: 1200px) {
  .ReferBodySummCommNotes {
    padding: 20px;
  }
  .SMProCusSmallDiv {
    min-width: 300px;
    max-width: 300px;
  }

  .SMReferDeBigDiv {
    width: Calc(100% - 300px);
  }
}

@media screen and (min-width: 1280px) {
  .NotesCard {
    width: 100%;
  }
}

@media screen and (min-width: 1300px) {}

@media screen and (min-width: 1400px) {
  .SummFormSemiHalf {
    width: 20%;
  }
  .FileUpDuBUDiv {
    width: 20%;
  }
}

@media screen and (min-width: 1500px) {
  .NotesCard {
    padding: 15px 25px;
  }

  .NotesCard {
    width: 49%;
  }
}

@media screen and (min-width: 1600px) {
  .NotesCard {
    padding: 15px 25px;
  }
  .SummFormSemiHalf {
    width: 16.6%;
  }
  .FileUpDuBUDiv {
    width: 16.6%;
  }
}

@media screen and (min-width: 1800px) {
}

@media (max-width: 767px) {
  .BreadcrumbHeadSec nav {
    display: none;
  }
  .PageTitleSec {
    padding: 25px 0 0 0;
  }
  .PageTitleSec>div.Dropdown {
    position: absolute;
    right: 10px;
    bottom: -74px;
    z-index: 1;
  }
  .SMProCusRow {
    margin: 0px 0px 0px 0px;
  }
  .WorkOrderAddBU span {
    display: none;
  }
  .WorkOrderAddBU svg {
    margin-right: 0px;
  }
  .BackAddBU {
    display: flex !important;
  }
  .ModalFormGroup {
    padding: 25px 0;
  }
  .ReferBodySummCommNotes img {
    display: none;
  }
  .FilesUpCardDD,
  .FilesUpCardDD:active,
  .FilesUpCardDD:checked,
  .FilesUpCardDD:hover,
  .FilesUpCardDD:focus {
    right: -20px;
    top: -15px;
  }
  .DetailsFooter {
    display: none;
  }
}

@media (max-width: 575px) {
  .PageTitleSec>div.Dropdown {
    right: -5px;
  }
  .PopFormGroupTwo {
    flex-wrap: wrap;
  }
  .Width40 {
    width: 100% !important;
  }

  .Width26 {
    width: 48% !important;
  }
}

@media (max-width: 479px) {
  .ButtonAction {
    display: flex;
    justify-content: space-between;
  }
  .ButtonSec {
    display: flex;
    justify-content: space-between;
  }
  .AddBU {
    margin: 0;
    width: 47%;
  }
  .CancelBU {
    margin: 0;
    width: 47%;
  }
}