.CallBar {
  position: fixed;
  top: 20px;
  left: 50%;
  z-index: 1000;
  width: 380px !important;
  background: #fff;
  backdrop-filter: blur(10px);
  transform: translate(-50%, 0%);
  padding: 6px;
  border-radius: 60px;
  overflow: hidden;
  border: 1px solid #ebebeb;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex !important;
  align-items: center;
}

.CallBarAvatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background: #fbf7fc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.CallBarAvatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.CallBarAvatar p {
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
  color: #502688;
  letter-spacing: 1px;
}

.CallBarDetails .Name {
  font-size: 18px;
  margin: 0 0 2px 0;
  color: #13052a;
  font-weight: 600;
}

.CallBarDetails .Phone {
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  color: #000;
  opacity: .6;
}

.CallDisBU {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background: #ff8271;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CallDisBU svg {
  width: 26px;
  height: 26px;
  transform: rotate(136deg);
  fill: #fff;
  stroke: none;
}

.CallRingText {
  color: #eaa043;
  font-size: 14px;
  margin: 0 12px 0 auto;
  font-weight: 500;
}

.CallCallingText {
  color: #27c27b;
  font-size: 14px;
  margin: 0 12px 0 auto;
  font-weight: 500;
}