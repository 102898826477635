@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap");

#tenantSig1 {
  font-family: "Tangerine", cursive;
  font-weight: 700;
}

.btZFeb,
.dXQott,
.bQEwkc,
.AppContent .MuiPaper-root {
  padding: 0px !important;
}

*:focus {
  outline: none;
  box-shadow: none;
}

a,
a:hover {
  text-decoration: none !important;
  cursor: pointer;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.btn {
  font-size: 14px;
  position: relative;
  width: auto;
  display: -webkit-inline-box;
  display: inline-flex;
  font-weight: 600;
  -webkit-box-align: center;
  align-items: center;
  padding: 11px 16px;
  line-height: 14px;
  border-radius: 0.5rem;
  border: none;
  transition: all 0.3s;
  margin-right: 10px;
  cursor: pointer;
}

.btn:focus {
  outline: none;
}

.btn:last-child {
  margin-right: 0;
}

.btn-primary {
  background: #ea983e;
  color: #fff;
}

.btn-primary:hover {
  background: #d49045;
}

.btn-delete {
  background: #fa1e0e;
  color: #fff;
}

.btn-delete:hover {
  background: #d61c0f;
}

.btn-secondary {
  background: #1565c0;
  color: #fff;
}

.btn-secondary:hover {
  background: #1158a9;
}

button {
  cursor: pointer;
}

button:focus,
input:focus {
  outline: none;
}

:focus {
  outline: none;
}

.CustomMenuWithBU {
  padding: 12px 0 0 0;
}

.CustomMenuWithBU .e-grid {
  border-style: none;
}

.CustomMenuWithBU .e-toolbar.e-control[class*="e-toolbar"] {
  width: 95% !important;
  border-top: 0px solid #e0e0e0;
}

.CustomMenuWithBU.FullSearch .e-toolbar.e-control[class*="e-toolbar"] {
  width: 100% !important;
}

.CustomMenuWithBU .e-toolbar.e-control[class*="e-toolbar"] .e-toolbar-items {
  background: #ffffff;
}

.CustomMenuWithBU.smartdevice .e-toolbar.e-control[class*="e-toolbar"] .e-toolbar-items {
  background: #ffffff;
  margin-bottom: 15px;
}

.CustomMenuWithBU .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  position: relative;
  width: 100%;
  margin-top: 0px;
}

.CustomMenuWithBU .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-search-wrapper {
  display: block;
  padding: 0;
  margin: 0;
}

.CustomMenuWithBU .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-search-wrapper .e-search {
  width: 100%;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px;
  opacity: 1;
  padding: 0 10px;
}

.CustomMenuWithBU.smartdevice .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-search-wrapper .e-search {
  width: 100%;
  max-width: 350px;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px;
  opacity: 1;
  padding: 0 10px;
}

.CustomMenuWithBU .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-search-wrapper .e-search input {
  min-height: 30px;
  padding-left: 25px;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.CustomMenuWithBU.AddDevice .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-search-wrapper .e-search input {
  min-height: 36px;
  padding-left: 30px;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.CustomMenuWithBU .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-search-wrapper .e-search .e-search-icon {
  position: absolute;
  left: 8px;
  top: 46%;
  transform: translate(0px, -50%);
  font-size: 16px;
  opacity: 0.7;
}

.CustomMenuWithBU.AddDevice .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-search-wrapper .e-search .e-search-icon {
  position: absolute;
  left: 12px;
  top: 43%;
  transform: translate(0px, -50%);
  font-size: 16px;
  opacity: 0.7;
}

.CustomMenuWithBU .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-search-wrapper .e-search::after,
.CustomMenuWithBU .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-search-wrapper .e-search::before {
  display: none;
}

.CustomMenuWithBU .e-grid .e-gridheader {
  border-top-color: #fff;
}

.CustomMenuWithBU .e-gridcontent table tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.CustomMenuWithBU .e-gridheader .e-headercontent table thead tr {
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
}

.CustomMenuWithBU .e-gridcontent table tr {
  cursor: pointer;
}

.CustomMenuWithBU .e-gridcontent .e-content table tr td {
  color: #505050;
  font-size: 14px;
}

.CustomMenuWithBU.User .e-gridcontent .e-content table tr td:nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 59px;
}

.CustomMenuWithBU .e-gridcontent table tr:nth-child(odd) {
  background: #fbf7fc;
}

.e-pager .e-currentitem {
  background: #ff8271;
  transition: all 0.3s ease-in-out;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: #230058;
}

.MuiSwitch-colorSecondary {
  padding: 8px;
}

.MuiSwitch-colorSecondary .MuiIconButton-label {
  border: 1px solid #cbcbcb;
  border-radius: 50%;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #fff;
}

.MuiSwitch-colorSecondary.Mui-checked .MuiIconButton-label {
  border: 1px solid #230058;
  border-radius: 50%;
}

.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
  color: #ffffff !important;
}

.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  border: 1px solid #230058;
}

.MuiSwitch-root .Mui-checked+.MuiSwitch-track {
  background-color: #331768 !important;
  opacity: 1 !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #230058;
  opacity: 1;
}

.fhAByC {
  display: none;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  height: 38px;
}

.TableAddBU a:hover {
  background: #e16e5f;
  color: #fff;
}

.TableAddBU svg {
  width: 20px;
  height: 20px;
}

input,
textarea {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol" !important;
}

/***************************************************/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  overflow-y: auto;
}

.modal-dialog {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 3.5rem);
  margin: 1.75rem auto;
  max-width: 500px;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid rgba(0,0,0,.2); */
  /* border-radius: .3rem; */
  outline: 0;
  border: none;
  box-shadow: none;
  border-radius: 0.5rem;
}

.modal-header {
  height: 60px;
  padding: 0 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: #ebebeb;
  border-bottom: none;
  justify-content: space-between;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header.Delete {
  background-color: #fa1e0e;
}

.modal-header .modal-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.5;
  margin: 0;
}

.modal-header.Delete .modal-title {
  color: #fff;
}

.modal-header button.close,
.close.ModalBU {
  background-color: white;
  text-shadow: none;
  opacity: 1;
  margin: 0;
  font-size: 23px;
  padding: 0;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: flex;
  border-radius: 50%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: 0;
  float: right;
  font-weight: 500;
  line-height: 1;
  color: #000;
}

.modal-header button.close span,
.close.ModalBU span {
  line-height: 12px;
  padding-top: 0px;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem;
}

.SM-DeleteTE {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  height: 60px;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer> :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer> :not(:last-child) {
  margin-right: 0.25rem;
}

.e-upload-progress-wrap {
  display: block;
  height: 8px;
  padding-bottom: 6px;
  padding-top: 6px;
  width: 100%;
}

.e-upload-progress-wrap.hide {
  display: none !important;
}

.e-progress-inner-wrap {
  border-radius: 0;
  display: block;
  height: 2px;
  width: 100%;
  background-color: #e0e0e0;
}

.e-upload-progress-bar {
  border-radius: 0;
  display: block;
  height: 2px;
  width: 100%;
  background-color: #e0e0e0;
}

.e-upload-progress-bar.e-upload-progress {
  background: #e3165b;
}

.e-upload-progress-bar.e-upload-success {
  background: #22b24b;
}

.e-upload-progress-bar.e-upload-failed {
  background: #f44336;
}

.stCustomDelete {
  display: flex;
}

.stCustomDelete #selectedFiles {
  margin-top: 15px;
}

.stCustomDelete #selectedFiles img {
  max-height: 80px !important;
  max-width: 280px !important;
}

.stCustomDelete a {
  display: inline-block;
  margin-left: -12px;
  margin-top: 5px;
  height: 25px;
}

.stCustomDelete svg {
  stroke-width: 2;
  color: #ff4444;
}

.form-control {
  font-size: 0.875rem;
  border-color: #e1e1e1;
  border-radius: 0.5rem;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.5em + 0.75rem + 3px);
  outline: none;
}

.DatePicker {
  width: 100%;
  margin: 0;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  height: calc(1.5em + 0.75rem + 3px);
}

.DatePicker input.MuiInputBase-input {
  padding: 4px 0;
}

.DatePicker .MuiInput-underline:before,
.DatePicker .MuiInput-underline:after {
  display: none;
}

.react-DatepickerWrapper {
  width: 100%;
}

.form-group.form-check {
  padding-left: 1rem;
  padding-top: 0;
}

.TimePicker {
  width: 100%;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.375rem 0.75rem 0.375rem 3rem;
}

.form-group {
  margin-bottom: 1rem;
  padding-top: 11px;
  position: relative;
}

.form-group label {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-right: 5px;
}

.form-group .e-upload.e-control.e-keyboard {
  display: none;
}

.TimePicker input.MuiInputBase-input {
  padding: 4px 0;
}

.TimePicker .MuiInput-underline:before,
.TimePicker .MuiInput-underline:after {
  display: none;
}

.TimePicker {
  width: 100%;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.375rem 0.75rem 0.375rem 3rem;
}

.ClockIconWithField {
  position: absolute;
  top: 37px;
  background: #ced4da;
  width: 36px;
  height: 37.44px;
  text-align: center;
  padding-top: 8px;
}

.ClockIconWithField svg {
  width: 16px;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2 {
  width: 100% !important;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2.select2-container .select2-selection {
  border: 1px solid #ced4da;
  min-height: calc(2.25rem + 2px);
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  outline: 0;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border-radius: 4px;
  cursor: text;
  box-sizing: border-box;
  display: block;
  -webkit-user-select: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 4px 5px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2.select2-container .select2-selection .select2-selection__choice {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container .select2-search--inline {
  float: left;
}

table.SM-ProHoursDiv {
  width: 100%;
}

table {
  border-collapse: collapse;
}

#disabledSelect {
  background-color: #e9ecef;
}

.SM-CustomTextArea {
  height: 200px !important;
  resize: none;
}

.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

/***************************************************/

/****************** Modal ***************************/

.MuiDialog-paper {
  border-radius: 10px;
}

.MuiDialogTitle-root {
  padding: 20px 30px;
  background-color: #fff;
  border-bottom: none;
  border-radius: 10px;
  /* border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; */
}

.NewPopUp .MuiTypography-root {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: flex-start;
  justify-content: space-between;
  height: auto;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  color: #343434;
}

.NewPopUp .MuiTypography-root button {
  background: none;
  border: none;
  color: #343434;
  padding: 0;
}

.NewPopUp .MuiTypography-root button svg {
  width: 32px;
  height: 32px;
  stroke-width: 1;
}

.MuiDialogContent-root {
  padding: 2px 30px;
}

.ModalTitle {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.5;
  margin: 0;
}

.PopsWithOutScroll .MuiDialog-paper,
.PopsWithOutScroll .MuiDialogContent-root {
  overflow: unset;
}

.toasts-holder {
  top: 75px !important;
}

.MuiDialogActions-root {
  padding: 30px 30px 24px 30px;
  justify-content: flex-start;
}

/****************** New Modal ***************************/
.NewPopUp .MuiDialogTitle-root {
  padding: 0;
  background: #fff;
}

.NewPopUp .MuiTypography-root {
  background: #fff;
  height: auto;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.NewPopUp .CheckBox .MuiTypography-root {
  background: #fff;
  height: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.NewPopUp .MuiTypography-root button {
  color: #343434;
}

.NewPopUp .MuiTypography-root .MuiPickersYear-root button:hover,
.NewPopUp .MuiTypography-root .MuiPickersYear-root button:focus {
  background-color: rgb(109 77 159 / 10%) !important;
}

.NewPopUp .MuiTypography-root .MuiPickersYear-root button.Mui-selected {
  background-color: #502688 !important;
  color: #fff;
}

.NewPopUp .MuiTypography-root button svg {
  width: 30px;
  height: 30px;
  stroke-width: 1.5;
}

.NewPopUp .MuiDialogContent-root,
.NewPopUp .MuiDialogContent-root .MuiDialogContentText-root {
  padding: 0;
  margin: 0;
}

.NewPopUp .MuiDialogContent-root::-webkit-scrollbar {
  display: none;
}

.NewPopUp .MuiDialogContent-root {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.NewPopUp .MuiDialogActions-root {
  padding: 12px 0 0 0;
  justify-content: flex-start;
}

.NewPopUp .MuiPaper-root {
  padding: 24px 32px;
  overflow: unset !important;
  border-radius: 10px !important;
}

.NewPopUp.CustomWidthPops .MuiPaper-root {
  max-width: 1100px;
}

.NewPopUp.CustomWidthLeasePops .MuiPaper-root {
  max-width: 1100px;
}

.NewPopUp.AddTenantDash .MuiPaper-root {
  padding: 24px 32px;
  overflow: auto !important;
  border-radius: 10px !important;
}

.CustomWidthPopUs.NewPopUp .MuiDialog-paperWidthSm {
  max-width: 660px;
}

.NewPopUpForLease.NewPopUp .MuiPaper-root {
  padding: 20px 20px;
  overflow: unset !important;
}

.NewPopUp .ModalFormGroup {
  width: 100%;
  padding: 25px 0 !important;
}

.NewPopUp.CustomWidthPopUs .ModalFormGroup {
  width: 100%;
  padding: 15px 0;
}

.NewPopUp .SubmitPopupBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin-left: 12px !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .SubmitPopupBU:hover {
  background: #e16e5f !important;
  border: 1px solid #e16e5f !important;
}

.NewPopUp .ArchivePopupBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #502688 !important;
  background: #fff !important;
  border: 1px solid #502688 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin-left: 12px !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .ArchivePopupBU:hover {
  background: #502688 !important;
  color: #fff !important;
}

.NewPopUp .AddPopupBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin-right: 16px !important;
  margin-left: 0 !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .AddPopupBU:hover {
  background: #e16e5f !important;
  border: 1px solid #e16e5f !important;
}

.NewPopUp .CancelPopupBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .CancelPopupBU:hover {
  background: #f1ecf3 !important;
  color: #343434 !important;
  border: 1px solid #e1e1e1 !important;
}

.NewPopUp .FileCanBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0px !important;
  padding: 12px 30px !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .FileCanBU:hover {
  background: #ebeef7 !important;
  color: #343434 !important;
  border: 1px solid #ebeef7 !important;
}

.NewPopUp .FileReBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  background: #2A5BEE !important;
  border: 1px solid #2A5BEE !important;
  border-radius: 0px !important;
  padding: 12px 30px !important;
  margin-right: 16px !important;
  margin-left: 15px !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .FileReBU:hover {
  background: #1a43c0 !important;
  border: 1px solid #1a43c0 !important;
}

.DeletePopupBU {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  background: #f44336;
  border: 1px solid #f44336;
  border-radius: 8px;
  padding: 10px 16px;
  margin-right: 16px;
  margin-left: 0 !important;
}

.DeletePopupBU:hover {
  background: #b82116;
  border: 1px solid #b82116;
}

/****************** New Modal ***************************/

/****************** Modal ***************************/

/* ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: #1565c0;
    background-image: -webkit-linear-gradient( 45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
} */

.customCalendarMain .e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: #1565c0;
}

.customCalendarMain .e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
  background: #1565c0;
}

.customCalendarMain .e-quick-popup-wrapper .e-event-popup .e-popup-header {
  background-color: #1565c0;
}

.customCalendarMain .e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: #1565c0;
}

.customCalendarMain .e-btn.e-flat.e-primary,
.customCalendarMain .e-css.e-btn.e-flat.e-primary {
  color: #1565c0;
}

.customCalendarMain .e-schedule .e-vertical-view .e-header-cells.e-current-day {
  color: #1565c0;
}

.customCalendarMain .e-float-input:not(.e-error) input:focus~label.e-float-text {
  color: #1565c0;
}

.customCalendarMain .e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
  background: #1565c0;
}

.customCalendarMain .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.customCalendarMain .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before {
  background: #1565c0;
}

.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before {
  background: #1565c0;
}

.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after {
  background: #1565c0;
}

.e-float-input:not(.e-error) input:focus~label.e-float-text {
  color: #1565c0;
}

.e-float-input.e-control-wrapper:not(.e-error) input:valid~label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input~label.e-label-top.e-float-text {
  color: #1565c0;
}

.e-float-input.e-control-wrapper:not(.e-error) input~label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input[readonly]~label.e-label-top.e-float-text {
  color: #1565c0;
}

.e-float-input:not(.e-error) input:focus~label.e-float-text,
.e-float-input:not(.e-error) input[readonly]:focus~label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus~label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input:focus~label.e-float-text {
  color: #1565c0;
}

.e-btn.e-flat.e-primary:hover {
  color: #1565c0;
}

.e-btn.e-flat.e-primary:active {
  background-color: hsla(214, 88%, 68%, 0.24);
  color: hsl(212, 80%, 42%);
}

.e-float-input.e-control-wrapper:not(.e-error) textarea:valid~label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) textarea~label.e-label-top.e-float-text {
  color: #1565c0;
}

.e-checkbox-wrapper .e-frame.e-check {
  background-color: #1565c0;
}

.e-datetime-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: #1565c0;
}

.e-input-group:not(.e-disabled) .e-input-group-icon:active,
.e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:active {
  color: #1565c0;
}

.e-btn.e-flat.e-primary {
  color: #1565c0;
}

.e-calendar .e-content td.e-today.e-selected span.e-day {
  border: 1px solid #1565c0;
}

/************************************************* Theme Customize ****************************************************/

.Dashboard__Root-sc-1ndgjm1-0 {
  display: block !important;
}

.jfnHfM,
.bgYPmJ,
.Footer__Wrapper-sc-1rkj73c-0,
.Dashboard__Paper-sc-1ndgjm1-3,
body {
  background: #fbf7fc !important;
}

.MuiFab-root {
  display: none !important;
}

.MuiGrid-container .MuiFab-primary {
  display: block !important;
}

body,
button {
  font-family: "Inter", sans-serif !important;
}

.MuiSwitch-switchBase {
  color: #eeeeee;
}

.MuiSwitch-track {
  opacity: 1;
  background-color: #cbcbcb;
}

/************************************************* Theme Customize ****************************************************/

.MuiBox-root-16,
.MuiBox-root-17 {
  font-weight: 600;
}

.hide {
  display: none !important;
}

.e-upload-files {
  display: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.padLeft15 {
  padding-left: 15px;
}

.e-btn,
.e-css.e-btn {
  background-color: #1565c0;
  color: #fff;
}

svg {
  stroke-width: 2;
}

.SelectServiceItems,
.SelectServicePops ul.MuiMenu-list {
  padding: 0 !important;
}

.SelectServicePops .MuiPaper-root.MuiMenu-paper {
  padding: 0;
  box-shadow: 1px 1px 8px 0 rgb(0 0 0 / 17%);
  top: 118px !important;
}

.LogsFilter .MuiPaper-root.MuiMenu-paper {
  padding: 0;
  box-shadow: 1px 1px 8px 0 rgb(0 0 0 / 17%);
  top: 215px !important;
}

.MuiExpansionPanelDetails-root.ServiceListSubCata {
  display: flex;
  padding: 0;
}

.ServiceListSubCata .MuiFormControl-root {
  width: 100%;
}

.ServiceListSubCata label.MuiFormControlLabel-root {
  /*background: #f5f5f5;*/
  margin: 0;
  padding: 2px 16px 2px 45px;
  /*border-bottom: 1px solid #ebebeb;*/
}

.ServiceListSubCata label.MuiFormControlLabel-root .MuiIconButton-root {
  padding: 4px 9px;
}

.ServiceListSubCata label.MuiFormControlLabel-root .MuiTypography-body1 {
  font-size: 13px;
}

.ServiceListSubCata label.MuiFormControlLabel-root:last-child {
  border-bottom: 0px solid #ebebeb;
}

.SelectServiceList .MuiCardContent-root {
  padding: 0 !important;
}

.SelectServiceList .MuiCardContent-root .MuiPaper-root {
  border-bottom: 0px solid;
  border-color: rgba(0, 0, 0, 0.12);
  margin: 0 !important;
}

.SelectServiceList .MuiCardContent-root .MuiPaper-root .MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: 40px;
}

/*.SelectServiceList
  .MuiCardContent-root
  .MuiPaper-root
  .MuiExpansionPanelSummary-root.Mui-expanded
  .MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 0px 0;
}*/

.SelectServiceList .MuiCardContent-root .MuiExpansionPanel-root:before {
  display: none;
}

.SelectServiceList .MuiCardContent-root .MuiPaper-root .MuiExpansionPanelSummary-root {
  padding: 0px 10px 0 30px;
  min-height: 30px;
}

.SelectServiceList .MuiCardContent-root .MuiPaper-root .MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content {
  margin: 10px 0;
}

.SelectServiceList .MuiCardContent-root .MuiPaper-root .MuiExpansionPanelSummary-root .MuiIconButton-root {
  padding: 10px 12px;
}

.FAQSearch {
  /*margin: 0 15px 10px 15px;*/
  position: relative;
  z-index: 2;
}

.FAQSearch .MuiInputLabel-outlined {
  transform: translate(14px, 13px) scale(1) !important;
}

.FAQSearch .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.FAQSearch .MuiOutlinedInput-root {
  padding: 0px 65px 0 0;
}

.FAQSearch .MuiOutlinedInput-root input {
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  font-size: 0.875rem;
  height: 26px !important;
}

.FAQSearch .MuiOutlinedInput-root input:hover {
  outline: none;
}

.FAQSearch .MuiAutocomplete-endAdornment {
  opacity: 0;
}

.FAQSearch .MuiOutlinedInput-notchedOutline,
.FAQSearch .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ced4da;
  /*background: #fff;*/
}

.customCalendarMain .e-schedule .e-month-view .e-appointment {
  background: #1565c0;
}

.uploadMain .MuiPaper-root {
  background: none;
  margin-top: 0;
}

.uploadMain .MuiButton-root {
  padding: 0;
  min-width: 24px;
  background: none;
}

.uploadMain .MuiPopover-paper {
  box-shadow: 0 0px 7px 2px rgb(62 85 120 / 10%);
}

.ListScroll .MuiCard-root {
  box-shadow: none !important;
}

/********************************* Client Profile choose filter ********************************/

.SMProfileStatusClick .MuiFormControlLabel-root {
  margin-bottom: 0;
}

.clientFilter .MuiPaper-root.MuiMenu-paper {
  box-shadow: 1px 1px 8px 0 rgb(0 0 0 / 17%);
}

.SMProfileStatusClick .MuiCheckbox-root {
  padding: 1px 9px;
}

.SMProfileStatusClick .MuiTypography-root {
  font-size: 14px;
  color: #9f9f9f;
  cursor: pointer;
  font-weight: 400;
  line-height: 22px;
}

.SMProfileStatusClick:first-of-type {
  margin-top: 15px;
}

.SMProfileStatusClick:last-child {
  margin-bottom: 15px;
}

.SMProfileStatusClick:hover {
  background-color: transparent;
}

.SM-ReferStatusList {
  color: #9f9f9f;
  font-size: 13.36px;
  padding: 6px 10px;
}

.SM-ReferStatusList svg {
  width: 17px;
  height: 17px;
  margin-right: 12px;
  stroke-width: 2;
}

.SM-ReferDetailsList {
  color: #505050;
  font-size: 13.36px;
  padding: 6px 10px;
}

.SM-ReferDetailsList a {
  color: #505050;
  font-size: 13.36px;
}

/*.SM-ReferDetailsList input[type=checkbox] {
    margin-right: 15px;
    cursor: pointer;
}*/

.SM-ReferDetailsList svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  stroke-width: 1;
  color: #a9a9a9;
  margin-bottom: -2px;
}

.SM-ReferDetailsList input[type="checkbox"]+label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  /*padding: 0.2em;*/
  position: relative;
}

.SM-ReferDetailsList input[type="checkbox"] {
  display: none;
}

.SM-ReferDetailsList input[type="checkbox"]+label:before {
  content: "\2714";
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 14px;
  height: 14px;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 15px;
  margin-top: -5px;
  vertical-align: middle;
  color: transparent;
  transition: 0.2s;
}

.SM-ReferDetailsList input[type="checkbox"]:checked+label:before {
  background-color: #1565c0;
  border-color: #1565c0;
}

.SM-ReferDetailsList input[type="checkbox"]:checked+label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  position: absolute;
  width: 8px;
  height: 4px;
  content: "";
  left: 3px;
  top: 6px;
  transform: rotate(-45deg);
}

/********************************* Client Profile choose filter ********************************/

.imgFileClassGlobal .e-upload {
  border-radius: 0.5rem !important;
}

.ClientsFilesUpload {
  padding: 0 !important;
}

.ClientsFilesUpload .e-upload {
  border: 0px !important;
}

.ClientsFilesUpload .e-upload .e-file-select-wrap {
  padding: 0 !important;
}

.ClientsFilesUpload .e-upload .e-file-select-wrap .e-btn {
  padding: 5px 15px 5px 40px;
  background-color: #1565c0;
  font-size: 14px;
  width: auto;
  display: inline-flex;
  font-weight: 500;
  -webkit-box-align: center;
  align-items: center;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  border: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 40px;
  text-transform: capitalize;
}

.ClientsFilesUpload .e-upload .e-file-select-wrap .e-file-drop {
  display: none !important;
}

.FilterByDisGlo .css-yk16xz-control {
  border-color: #e2e2e2 !important;
  border-radius: 6px !important;
  min-height: 36px !important;
}

.FilterByDisGlo .css-tlfecz-indicatorContainer {
  padding: 7px !important;
}

.FirstLocatePopsMain .MuiDialog-paper,
.FirstLocatePopsMain .MuiDialogContent-root {
  overflow-y: unset;
}

.Loader svg {
  width: 100%;
}

.TableLoader {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 10px 22px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.TableLoader svg {
  height: 970px;
}

.ReffLoader svg {
  height: 550px;
}

.FAQCataListLoader svg {
  height: 400px;
  padding: 0 15px;
}

.CardWithImg svg {
  height: 320px;
}

.FromLoader svg {
  height: 420px;
}

.FilesCardLoader,
.FAQCard,
.CardWithImg {
  padding: 15px;
}

.LatestArLoader {
  width: 100%;
}

.LatestArLoader svg,
.HeaderLoader svg {
  height: 30px;
}

.DashCard {
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;
  border-radius: 6px;
  background: #fff;
  padding: 15px;
}

.DashCard svg {
  height: 140px;
}

.CalendarLoader svg {
  height: 600px;
}

.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day,
.e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day {
  color: #ea983e;
}

.e-schedule .e-month-view .e-current-date .e-date-header,
.e-schedule .e-month-agenda-view .e-current-date .e-date-header {
  background-color: #ea983e;
}

.CalendarMoreDetails {
  display: flex;
  justify-content: flex-end;
}

.CalendarMoreDetails a div {
  cursor: pointer !important;
  color: #ea983e !important;
}

.CalendarSearch {
  width: calc(100% - 100%);
  background: #fff;
  position: absolute;
  top: 20px;
  opacity: 0;
  display: flex;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

.CalendarSearch.serachClass {
  width: calc(100% - 40px);
  opacity: 1;
  z-index: 5;
}

.ReferExpMain .MuiExpansionPanel-root:before {
  display: none;
}

.ReferExpMain .MuiExpansionPanel-root.Mui-expanded {
  margin: 0;
}

.ReferExpMain .MuiExpansionPanelSummary-root,
.ReferExpMain .MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: 90px;
  padding: 0 15px 0 25px;
}

.ReferExpMain .MuiExpansionPanelSummary-root.Mui-expanded .MuiExpansionPanelSummary-content.Mui-expanded {
  position: relative;
}

.ReferExpMain .MuiExpansionPanelSummary-root.Mui-expanded .MuiExpansionPanelSummary-content.Mui-expanded::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -35px;
  background: #e5e8eb;
}

.ReferExpMain .MuiExpansionPanelSummary-content.Mui-expanded,
.ReferExpMainSumm .MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 12px 0;
}

.FileManagementMain .e-upload-files {
  display: block;
}

.FileManagementMain .e-dialog.e-draggable .e-dlg-header-content {
  height: 60px;
  padding: 19px 20px;
  background-color: #ebebeb;
}

.FileManagementMain .e-dialog .e-dlg-header {
  font-size: 17px !important;
  font-weight: 600 !important;
  margin-bottom: 0;
  line-height: 1.5;
  margin: 0;
}

.FileManagementMain .e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  background-color: white;
  text-shadow: none;
  opacity: 1;
  margin: -1px ​0 0 0;
  font-size: 23px;
  padding: 0;
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 0;
  float: right;
  font-weight: 500;
  line-height: 1;
  color: #000;
}

.FileManagementMain .e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 10px;
  margin-top: -1px;
}

.FileManagementMain .e-dialog .e-dlg-header-content+.e-dlg-content {
  padding: 1.5rem;
}

.FileManagementMain .e-dialog .e-footer-content {
  padding: 0.6rem;
  border-top: 1px solid #dee2e6;
}

.FileManagementMain .e-footer-content .e-btn.e-flat.e-primary {
  font-size: 14px;
  position: relative;
  width: auto;
  display: inline-flex;
  font-weight: 600;
  align-items: center;
  padding: 11px 16px;
  line-height: 14px;
  border-radius: 0.5rem;
  border: none;
  transition: all 0.3s ease-in-out;
  margin-right: 10px;
  cursor: pointer;
  background: #ea983e;
  color: #fff;
  min-width: 80px;
}

.FileManagementMain .e-footer-content .e-btn.e-flat.e-primary:hover {
  background: #d49045;
}

.FileManagementMain .e-footer-content .e-btn.e-flat {
  font-size: 14px;
  position: relative;
  width: auto;
  display: inline-flex;
  font-weight: 600;
  align-items: center;
  padding: 11px 16px;
  line-height: 14px;
  border-radius: 0.5rem;
  border: none;
  transition: all 0.3s ease-in-out;
  margin-right: 10px;
  cursor: pointer;
  background: #1565c0;
  color: #fff;
  min-width: 80px;
}

.FileManagementMain .e-footer-content .e-btn.e-flat:hover {
  background: #1158a9;
}

.FileManagementMain .e-dlg-content {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

.FileManagementMain .e-spinner-pane .e-spinner-inner .e-spin-material {
  stroke: #ea983e;
}

.FileManagementMain .e-treeview .e-list-item.e-active>.e-text-content .e-list-text {
  color: #ea983e;
}

.FileManagementMain .e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: #502688;
}

.e-checkbox-wrapper:hover .e-frame.e-check,
.e-css.e-checkbox-wrapper:hover .e-frame.e-check {
  background-color: #230058;
  border-color: transparent;
  color: #fff;
}

.FileManagementMain .e-toolbar-right {
  display: none !important;
}

.FileManagementMain .e-filemanager .e-grid .e-fe-pdf {
  background-color: rgb(218, 15, 15);
}

/***********************************Stepper********************************************/
.StepperCuz {
  padding: 0 !important;
  margin: 0 -8px;
  flex-wrap: wrap;
}

.StepperCuz .MuiStep-root {
  flex: 1;
  position: relative;
  width: 100%;
}

.StepperCuz .MuiStepButton-root {
  /*padding: 12px 15px !important;
    margin: 0 !important;
    justify-content: left !important;
    background: #1565c0;
    box-sizing: border-box !important;
    border-radius: 5px;*/
  padding: 0px !important;
  margin: 0 !important;
  justify-content: left !important;
  overflow: hidden;
  box-sizing: border-box !important;
  border-radius: 5px;
  position: relative;
}

.StepperCuz .MuiStepLabel-root.MuiStepLabel-alternativeLabel {
  flex-direction: unset !important;
  width: 100%;
}

.StepperCuz .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  padding-right: 0;
  position: absolute;
  left: 15px;
}

.StepperCuz .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  /*margin-top: 2px !important;
    margin-left: 10px;
    color: #fff !important;*/
  margin-top: 0px !important;
  margin-left: 0px;
  color: #aaaaaa !important;
  background: #eeeeee;
  padding: 12px 15px 12px 50px !important;
  text-align: left;
}

.StepperCuz .MuiStepLabel-label.MuiStepLabel-alternativeLabel.MuiStepLabel-active {
  /*margin-top: 2px !important;
    margin-left: 10px;
    color: #fff !important;*/
  color: #fff !important;
  background: #1565c0;
}

.StepperCuz .MuiStepConnector-alternativeLabel {
  display: none !important;
}

.StepperCuz .MuiStepIcon-root.MuiStepIcon-active {
  color: #4484cd;
}

@media (min-width: 360px) {
  .StepperCuz .MuiStep-root {
    flex: unset;
    padding-bottom: 15px;
  }

  .StepperCuz .MuiStep-root:last-child {
    padding-bottom: 0;
  }
}

@media (min-width: 768px) {
  .StepperCuz .MuiStep-root {
    flex: 1;
    padding-bottom: 0;
  }
}

/***********************************Stepper********************************************/

.ArchiveDiv label.MuiFormControlLabel-root,
.ArchiveDiv {
  margin: 0;
  margin-left: 20px;
}

.ArchiveDiv label.MuiFormControlLabel-root span.MuiFormControlLabel-label {
  color: #505050;
  font-size: 14px;
  font-weight: 500;
}

.FilterMulti div.basic-multi-select {
  width: 100%;
}

.FilterSingle div.basic-single {
  width: 100%;
}

.ReffModuleTab .MuiBox-root {
  padding: 20px 0 !important;
}

.SerachOrgMap {
  padding: 0 15px;
}

.AppliStatusBu .MuiPaper-root.MuiMenu-paper {
  box-shadow: 1px 1px 8px 0 rgb(0 0 0 / 17%);
}

.DropDListWIcon {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0 !important; */
  padding: 8px !important;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.DropDListWIcon:active {
  background-color: #e9ecef !important;
}

.FileUpDuBUDiv .btn-check:active+.btn-primary,
.FileUpDuBUDiv .btn-check:checked+.btn-primary,
.FileUpDuBUDiv.show>.btn-primary.dropdown-toggle {
  background-color: #4d1f94 !important;
  border: none;
  box-shadow: none !important;
}

.FileUpDuBUDiv .btn-primary.active,
.FileUpDuBUDiv .btn-primary:active,
.FileUpDuBUDiv .btn-primary:focus {
  background-color: #230058 !important;
  border: none;
  box-shadow: none !important;
}

.ActionsBUT:hover,
.btn-check:active+.btn-primary.ActionsBUT,
.btn-check:checked+.btn-primary.ActionsBUT,
.ActionsBUT.btn-primary.active,
.ActionsBUT.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBUT {
  background-color: #ff8271 !important;
  border: none;
  box-shadow: none !important;
}

.DropDListWIcon:last-child {
  border-bottom: none !important;
}

/* .CustomHeadingSelect .css-1kc27e3-control,
.CustomHeadingSelect .css-9r0ab9-control {
    opacity: 0;
} */

.CustomHeadingSelect div.css-1kc27e3-control div:nth-child(1) div:nth-child(1),
.CustomHeadingSelect div.css-9r0ab9-control div:nth-child(1) div:nth-child(1) {
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  color: #505050;
}

.DropDListWIcon svg {
  width: 17px;
  height: 17px;
  margin-right: 12px;
  stroke-width: 2;
}

.DropDownMenuBoxCus {
  /* transform: translate(0px, 40px) !important; */
  /*transition: all .3s ease-in-out;*/
  /* display: block !important; */
  border: none !important;
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 15%);
}

/* .DropDownMenuBoxCus.dropdown-menu.show {
    transform: translate(0px, 23px) !important;
} */

.DropDownMenuBoxCus .DropDListWIcon.dropdown-item.active,
.DropDownMenuBoxCus .DropDListWIcon.dropdown-item:active,
.DropDownMenuBoxCus .DropDListWIcon.dropdown-item:hover {
  /* background-color: #e9ecef;
    color: #502688 !important; */
  color: #343434 !important;
  background: #f8f8f8;
}

.daterangepicker.show-calendar:before,
.daterangepicker.show-calendar:after {
  display: none;
}

.daterangepicker td.in-range {
  background-color: #f6f1fd !important;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #230058 !important;
  border-color: transparent;
  color: #fff;
}

.ReferExpMain .MuiExpansionPanelSummary-content.Mui-expanded,
.ReferExpMainSumm .MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 12px 0;
}

.ReferExpMainSumm .MuiButtonBase-root {
  align-items: start;
  padding: 0 20px;
}

.ReferExpMainSumm .MuiButtonBase-root.MuiIconButton-root {
  align-items: start;
  padding: 20px 0px;
}

.MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #502688;
}

.MuiSelect-select .MuiBox-root>div {
  flex-wrap: wrap;
  margin: 0px;
}

.MuiSelect-select .MuiBox-root.SelectLease>div {
  flex-wrap: wrap;
  margin: 0;
}

.MuiSelect-select .MuiBox-root div[draggable="false"] {
  margin: 3px;
}

.MuiSelect-select .MuiBox-root div[draggable="false"] .MuiChip-root {
  font-size: 13px;
  height: 27px;
  background-color: #fbf7fc;
  color: #502688;
}

.MuiSelect-select .MuiBox-root.SelectLease .MuiChip-root,
.MuiSelect-select .MuiBox-root.DropListNew .MuiChip-root {
  font-size: 13px;
  height: 27px;
  background-color: #fbf7fc;
  color: #502688;
}

.fc-daygrid-day-events {
  max-height: 0 !important;
}

/*********************************** Tab Customization for client profile **************************************/

.TabMainClientProfile .MuiAppBar-colorDefault {
  background-color: #fff;
}

.TabMainClientProfile .MuiTabScrollButton-root.Mui-disabled {
  width: 0px;
}

.TabMainClientProfile .MuiTabs-root {
  border-bottom: 1px solid #dee2e6;
  width: 100%;
}

.TabMainClientProfile.noBorder .MuiTabs-root {
  /* border-bottom: 0px solid #dee2e6; */
  width: 100%;
}

.TabMainClientProfile .MuiTabs-scrollable {
  border-bottom: none !important;
}

.TabMainClientProfile .MuiTab-textColorPrimary.Mui-selected,
.TabMainClientProfile .MuiTab-textColorInherit.Mui-selected {
  color: #502688;
}

.TabMainClientProfile .PrivateTabIndicator-root-17 {
  width: 100%;
  bottom: 0;
  height: 2px;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #230058;
}

.TabMainClientProfile .MuiTabs-indicator {
  background-color: #502688;
}

.TabMainClientProfile.ClientDetails .MuiAppBar-colorDefault {
  display: grid;
}

.TabMainClientProfile .MuiAppBar-colorPrimary {
  color: rgba(0, 0, 0, 0.54);
  background-color: transparent;
  box-shadow: none;
  position: relative;
  z-index: 1;
}

.MoreOptions {
  min-width: 90px !important;
}

.MoreOptions .MuiSvgIcon-root {
  display: none;
}

.MoreOptions svg.MoreIcon {
  position: absolute;
  width: 22px;
  height: auto;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  color: #ff8271;
  transition: all 0.2s ease-in-out;
}

/* .MoreOptions .MuiFormLabel-root.Mui-focused ~ svg.MoreIcon {
  transform: translate(0, -50%) rotate(180deg);
} */

.MoreOptions .MuiFormLabel-root {
  font-size: 14px !important;
  color: #ff8271 !important;
  font-weight: 500 !important;
  min-width: 100px;
  top: 13px;
  font-family: "Inter", sans-serif !important;
  transform: translate(14px, 0px) scale(1) !important;
}

.MoreOptions .MuiFormLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled {
  display: none;
}

.MoreOptions .MuiInputBase-root .MuiSelect-select {
  padding: 12px 32px 12px 14px;
  font-size: 14px !important;
  color: #ff8271 !important;
  font-weight: 500 !important;
  font-family: "Inter", sans-serif !important;
}

.MoreOptions .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  display: none;
}

.DatePickerCustom .MuiFormControl-marginNormal {
  margin: 0;
}

.DatePickerCustom .MuiInput-root {
  display: flex;
  width: 100%;
  min-width: 150px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DatePickerCustom .MuiInput-root::before,
.DatePickerCustom .MuiInput-root::after {
  display: none;
}

.DatePickerCustom .MuiInput-root input {
  max-width: 166px;
}

.DatePickerCustom .MuiInput-root button {
  padding: 0px;
}

.DatePickerCustom .MuiInput-root button svg {
  width: 20px;
  height: 20px;
}

.LeaseInfoDate {
  position: relative;
  background-color: #fff;
}

.LeaseInfoDate>div {
  width: 100%;
}

.LeaseInfoDate svg {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translate(0, -50%);
}

.LeaseInfoDate .CalIcon {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 13px;
  top: 13px;
  z-index: 1;
  color: #2c2c2c;
}

.LeaseInfoDate .MuiFormControl-marginNormal {
  margin: 0;
}

.LeaseInfoDate .MuiFormLabel-root {
  left: 15px !important;
  transform: translate(0, 16px) scale(1) !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #c5bfc5;
}

.LeaseInfoDate .MuiFormLabel-root.MuiFormLabel-filled {
  display: none;
}

.LeaseInfoDate .MuiInput-root {
  display: flex;
  background: transparent;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 16px;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;
  /* position: relative;
    z-index: 2; */
}

.LeaseInfoDate .MuiInput-root::before,
.LeaseInfoDate .MuiInput-root::after {
  display: none;
}

.LeaseInfoDate .MuiInput-root input {
  /* max-width: 166px; */
  padding: 2.7px 0;
}

.LeaseInfoDate .MuiInput-root button {
  padding: 0px;
  opacity: 0;
}

.LeaseInfoDate .MuiInput-root button svg {
  width: 20px;
  height: 20px;
  position: relative;
  right: -5px;
  top: 0;
}

.LeaseInfoDate .MuiInputAdornment-root {
  position: absolute;
  width: 100%;
  height: 44px;
  max-height: 44px;
  left: 0;
  margin: 0;
}

.LeaseInfoDate .MuiInputAdornment-root button {
  width: 100%;
  height: 44px;
}

/*********************************** Tab Customization for client profile **************************************/

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #f48272;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #230058;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover,
.MuiIconButton-colorSecondary:hover {
  background-color: rgb(244 130 114 / 4%);
}

.ClientDetails .MuiBox-root {
  padding: 15px 0px;
}

.ClientDetails .ChatMessageSec .MuiBox-root {
  padding: 0px 0px;
}

.MuiPopover-paper {
  box-shadow: 0px 0px 8px 0 #3333331f;
}

.form-control {
  background: transparent !important;
}

.form-control {
  font-size: 0.875rem !important;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  color: #fff !important;
  background-color: #ff8271 !important;
}

.MuiPaginationItem-textPrimary.Mui-selected:hover,
.MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible {
  background-color: #e77565;
}

.TimePick .MuiInput-underline:before,
.TimePick .MuiInput-underline:after {
  display: none;
}

.TimePick .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
}

.TimePick .MuiFormControl-marginNormal {
  margin: 0;
  width: 100%;
}

.TimePick .MuiIconButton-root {
  padding: 0;
  color: #a1a1a1;
}

/* .TimePick label {
  position: relative;
  top: 3px;
  font-weight: 400;
  color: #bfbfbf;
  font-size: 14px;
  line-height: 21px;
  transform: translate(0, 0px) scale(1) !important;
}

.TimePick label.MuiFormLabel-filled {
  display: none;
} */

.TimePick .MuiFormControl-root .MuiInputBase-root {
  border-radius: 8px;
}

.TimePick .MuiFormControl-root .MuiInputBase-root input {
  padding: 14px 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.TimePick .MuiFormControl-root .MuiFormLabel-root.Mui-focused {
  color: #502688;
}

.TimePick .MuiFormControl-root .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.TimePick .MuiFormControl-root .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #502688;
}

.MuiPickersArrowSwitcher-root.MuiTimeClock-arrowSwitcher {
  display: none;
}

.MuiTimeClock-root .MuiClock-clock .MuiClock-pin,
.MuiTimeClock-root .MuiClock-clock .MuiClockPointer-root {
  background-color: #502688;
}

.MuiTimeClock-root .MuiClock-clock .MuiClockPointer-root .MuiClockPointer-thumb {
  background-color: #502688;
  border: 16px solid #502688;
}

.MuiPickersLayout-actionBar button {
  color: #343434;
}

.MuiPickersLayout-actionBar button:last-child {
  color: #502688;
}

.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint {
  background-color: #230058;
}

.MuiPickersDay-daySelected {
  background-color: #230058;
}

.MuiPickersToolbar-toolbar.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding {
  background-color: #230058;
  padding-left: 20px;
}

.MuiPickersToolbar-toolbar.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding h2 {
  font-size: 3.75rem;
  font-weight: 300;
}

.MuiPickersToolbar-toolbar.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding button:after {
  display: none;
}

.MuiPickersToolbar-toolbar button:first-child {
  position: relative;
}

.MuiPickersToolbar-toolbar button:first-child::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: transparent;
  right: -17px;
  top: 6px;
  border: 1px solid #cbcbcb;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
}

.MuiPickersClockPointer-thumb {
  border: 14px solid #230058;
}

.MuiButton-textPrimary {
  color: #230058;
}

.e-grid .e-rowcell,
.e-grid .e-headercell,
.e-grid .e-detailheadercell {
  padding: 10px 10px !important;
}

.CustomMenuWithBU.AddDevice .e-grid .e-rowcell,
.CustomMenuWithBU.AddDevice .e-grid .e-headercell,
.CustomMenuWithBU.AddDevice .e-grid .e-detailheadercell {
  padding: 16px 10px !important;
}

.MuiPickersBasePicker-container {
  flex-direction: column !important;
}

.MuiPickersDatePickerRoot-toolbar {
  max-width: 100% !important;
}

/*************************** Card row table custom *******************************/

.CardRowCUS .e-grid,
.CardRowCUS .e-grid .e-gridheader {
  border: none !important;
}

.CardRowCUS .e-grid .e-gridheader table.e-table {
  border-spacing: 0 0px;
}

.CardRowCUS .e-grid .e-toolbar.e-control[class*="e-toolbar"] {
  display: none;
}

.CardRowCUS .e-grid .e-table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.CardRowCUS .e-grid .e-headercelldiv {
  font-size: 12px;
  color: #333;
  font-weight: 500;
  text-transform: uppercase;
}

.CardRowCUS .e-grid .e-table.e-sortfilter {
  border-spacing: 0 0px;
}

.CardRowCUS .e-grid .e-gridcontent tr td {
  border-top: 1px solid #e5e8eb !important;
  border-bottom: 1px solid #e5e8eb !important;
  vertical-align: top;
  overflow: hidden !important;
}

.CardRowCUS .e-grid .e-gridcontent tr td.e-active {
  background: #fbf7fc !important;
}

.CardRowCUS .e-grid .e-gridcontent tr td:first-child {
  border-left: 1px solid #e5e8eb !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  padding-left: 40px !important;
}

.CardRowCUS .e-grid .e-gridcontent tr td:first-child::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background: #3aaa35;
  left: 0;
  top: 0;
}

.CardRowCUS .e-grid .e-gridcontent tr.e-row-bill td:first-child::before {
  background: #808080;
}

.CardRowCUS .e-grid .e-gridcontent tr.e-row-pending td:first-child::before {
  background: #ffbc00;
}

.CardRowCUS .e-grid .e-gridcontent tr.e-row-failed td:first-child::before {
  background: #f44336;
}

.CardRowCUS .e-grid .e-gridcontent tr.e-row-completed td:first-child::before,
.CardRowCUS .e-grid .e-gridcontent tr.e-row-credit td:first-child::before {
  background: #3aaa35;
}

.CardRowCUS .e-grid .e-gridcontent tr td:last-child {
  border-right: 1px solid #e5e8eb !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  vertical-align: middle;
}

.CardRowCUS .e-grid .e-headercell {
  padding: 0px 10px !important;
}

.CardRowCUS .e-grid .e-headercell:first-child {
  padding-left: 40px !important;
}

/*************************** Card row table custom *******************************/

.Pagination {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px 2px;
}

.Pagination.TablePaginationNew {
  padding: 0;
}

.Pagination.TablePaginationNew.List {
  padding: 0 15px;
}

.Pagination.TablePaginationNew .MuiTableCell-root {
  border: none;
}

.Pagination.TablePaginationNew .MuiTableCell-root .MuiToolbar-root {
  padding: 0;
}

.Pagination.TablePaginationNew .MuiTableCell-root .MuiToolbar-root p {
  margin: 0;
}

.Pagination.TablePaginationNew .MuiTableCell-root .MuiToolbar-root div.MuiInputBase-root div.MuiSelect-select {
  padding: 8px 8px 5px;
  padding-right: 25px;
  border: 1px solid #737174;
  border-radius: 5px;
  text-align: center;
  text-align-last: center;
}

.Pagination .MuiPaginationItem-root {
  height: 25px;
  min-width: 25px;
}

.PagiCount {
  font-size: 13px;
}

@media (max-width: 479px) {
  .Pagination {
    justify-content: center;
  }

  .PagiCount {
    width: 100%;
    text-align: center;
    padding-top: 12px;
  }
}

.request-opened-new {
  color: #fff;
  background: #f44336;
  padding: 4px 12px;
  border-radius: 5px;
  font-size: 12px;
  margin: 0 5px;
  white-space: nowrap;
}

.request-ongoing-new {
  color: #000;
  background: #fb3;
  padding: 4px 12px;
  border-radius: 5px;
  font-size: 12px;
  margin: 0 5px;
  white-space: nowrap;
}

.request-closed-new {
  color: #fff;
  background: #17dd1b;
  padding: 4px 12px;
  border-radius: 5px;
  font-size: 12px;
  margin: 0 5px;
  white-space: nowrap;
}

.request-opened-new svg,
.request-ongoing-new svg,
.request-closed-new svg {
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
  margin-right: 5px;
}

.FooterBack .FooterMain {
  z-index: 1;
  padding: 1px 20px;
}

.Primary .progress-bar {
  background-color: #00c84e !important;
}

.Secondary .progress-bar {
  background-color: #ff8271 !important;
}

.ChartTitle span {
  text-transform: capitalize;
  font-size: 18px;
}

.MuiTooltip-popper {
  z-index: 99999 !important;
}

.MuiTooltip-popper>div {
  background-color: rgba(0, 0, 0, 0.9);
}

/* .MuiTooltip-popper>* {
  font-size: 11px !important;
} */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.e-treeview .e-list-item.e-active>.e-text-content .e-list-text {
  color: #f48272 !important;
}

.PopupBodyText {
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin: 0px 0;
}

.InactivatePopupBU {
  background: #502688;
  border: 1px solid #502688;
  color: #fff;
  transition: all ease-in-out 0.3s;
  min-width: 90px;
  margin: 0 16px 0 0 !important;
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.InactivatePopupBU:hover {
  background: #230058;
  border: 1px solid #230058;
  color: #fff;
}

/* .CancelPopupBU {
  background: #fff;
  border: 1px solid #502688;
  color: #502688;
  transition: all ease-in-out 0.3s;
  min-width: 90px;
  margin: 0 !important;
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.CancelPopupBU:hover {
  background: #230058;
  border: 1px solid #230058;
  color: #fff;
} */

.DeleteBU {
  text-align: right;
  padding: 0px;
  color: #666;
  min-width: 20px;
  display: flex;
  align-items: center;
}

.DeleteBU svg {
  width: 20px;
}

.toasts-holder {
  max-height: calc(65px - 0px) !important;
  width: fit-content;
}

/* .CustomHeadingSelect>div>div:nth-child(1)>div:nth-child(2) {
    position: absolute;
} */

.MainLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background: rgb(255 255 255 / 45%);
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999999999;
}

.MainLoader img {
  width: 80px;
}

.LoadingText {
  text-align: center;
  padding: 15px 0 0 0;
  font-size: 14px;
}

.MuiTab-root {
  padding: 10px 24px !important;
  font-size: 14px !important;
  color: #666 !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  opacity: 1;
}

.ActionsBU:hover,
.btn-check:active+.btn-primary.ActionsBU,
.btn-check:checked+.btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBU,
.btn-check:focus+.btn-primary.ActionsBU,
.ActionsBU.btn-primary:focus {
  background-color: #230058;
  border: none;
  box-shadow: none !important;
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.ActionsBU.Red:hover,
.btn-check:active+.btn-primary.ActionsBU.Red,
.btn-check:checked+.btn-primary.ActionsBU.Red,
.ActionsBU.Red.btn-primary.active,
.ActionsBU.Red.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBU.Red,
.btn-check:focus+.btn-primary.ActionsBU.Red,
.ActionsBU.Red.btn-primary:focus {
  background-color: #ed7464 !important;
  border: none;
}

.ActionsBULine:hover,
.btn-check:active+.btn-primary.ActionsBULine,
.btn-check:checked+.btn-primary.ActionsBULine,
.ActionsBULine.btn-primary.active,
.ActionsBULine.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBULine,
.btn-check:focus+.btn-primary.ActionsBULine,
.ActionsBULine.btn-primary:focus {
  background-color: #230058 !important;
  border: none;
  box-shadow: none !important;
  color: #fff !important;
}

.ActionsBUList {
  padding: 5px 5px !important;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: 1px 3px 20px 0px rgb(0 0 0 / 10%);
}

.ActionsBU:after,
.ActionsBUWB:after,
.ProsDrBU:after {
  display: none !important;
}

.dropdown-menu {
  min-width: 8rem !important;
  padding: 5px !important;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.x-hellosign-embedded__modal-close {
  position: relative !important;
  top: 0;
  right: 0 !important;
  height: 40px;
  width: auto !important;
  background-color: #1a1a1a;
  display: flex;
  justify-content: right;
}

.x-hellosign-embedded__modal-close-button {
  height: auto !important;
  width: auto !important;
  box-shadow: none !important;
}

.ProfileEditModal .MuiDialog-paper {
  max-width: 800px !important;
}

.ProfileEditModal .MuiDialog-paper .MuiDialogContent-root {
  padding: 0 !important;
}

.e-upload.e-control-wrapper {
  display: none !important;
}

#leaseDropArea .e-upload.e-control-wrapper {
  display: block !important;
  border: none !important;
}

#leaseDropArea .e-file-select-wrap {
  display: none !important;
}

#leaseDropArea .e-upload-files {
  display: block !important;
}

.pac-container {
  z-index: 99999 !important;
}

#root>div {
  width: 100%;
  max-width: 100% !important;
  display: block !important;
  /* background: #fff !important; */
}

#root>div.CommunicationPopupSec {
  width: 100%;
  max-width: 400px !important;
  display: flex !important;
  /* background: #fff !important; */
}

#root>div.CallBar {
  width: 100%;
  max-width: 380px !important;
  display: flex !important;
  /* background: #fff !important; */
}

#root>div.ChatNotificationSec {
  width: 440px;
  max-width: 100% !important;
  display: block !important;
  /* background: #fff !important; */
}

.Parallax {
  width: 100%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  height: 420px;
}

.Parallax .react-parallax-bgimage {
  height: 630px !important;
}

.imageValidationError {
  /* color: #F44336;
  font-size: 12px;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 10px;
  left: 50%;
  background: #ffffff;
  padding: 5px 15px;
  border-radius: 4px;
  transform: translate(-50%, 0px);
  width: 280px; */
  position: fixed;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 5px 15px;
  white-space: pre-line;
  min-height: 50px;
  margin-bottom: 15px;
  border-radius: 5px;
  animation-name: propertydetails_fade-in-up__7ssf4;
  animation-duration: 1s;
  animation-fill-mode: both;
  font-size: 15px;
  color: rgb(185, 74, 72);
  background-color: rgb(242, 222, 222);
  border-color: rgb(238, 211, 215);
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
}

#features {
  position: relative;
  padding-top: 82px;
}

#blogs {
  position: relative;
  padding-top: 40px;
}

.MuiMenuItem-root {
  font-size: 14px !important;
  min-height: auto !important;
}

.MuiMenuItem-root span.MuiListItemText-primary {
  font-size: 14px !important;
}

.MuiMenuItem-root span.MuiCheckbox-root {
  padding: 6px 10px;
}

.FormGroup {
  width: 100%;
  padding-bottom: 30px;
  /* font-size: .875rem; */
  position: relative;
}

.FormGroup .MuiFormControl-root,
.FormGroup .MuiTextField-root {
  width: 95%;
  /* margin-bottom: 20px !important; */
}

.FormGroup input,
.FormGroup textarea {
  border-radius: 5px;
  /* font-size: 18px; */
  font-weight: 400;
  color: #080c13;
  width: 95%;
  resize: none;
  border: none;
}

.FormGroup .MuiFormLabel-root {
  font-size: 14px !important;
  margin-bottom: 0.5em !important;
  color: rgb(0 0 0 / 30%);
}

.FormGroup.PinTypeSec .MuiFormLabel-root {
  font-size: 14px !important;
  margin-bottom: 0.5em !important;
  color: rgb(255 255 255 / 90%);
}

.FormGroup.CusRental .MuiFormLabel-root {
  font-size: 15px !important;
  margin-bottom: 0.4em !important;
  color: rgb(0 0 0 / 40%);
  left: 6px;
}

.FormGroup.SelectRoom.Active .MuiFormLabel-root {
  color: rgb(255 255 255 / 50%);
}

.FormGroup.AddDevice .MuiFormLabel-root {
  top: -4px;
}

.FormGroup .MuiFormControl-root label.MuiInputLabel-shrink.MuiFormLabel-root {
  font-size: 1rem !important;
  transform: translate(14px, -9px) scale(0.75);
}

.FormGroup.CusRental .MuiFormControl-root label.MuiInputLabel-shrink.MuiFormLabel-root {
  font-size: 1rem !important;
  transform: translate(14px, -10px) scale(0.8);
}

.FormGroup.AddDevice .MuiFormControl-root label.MuiInputLabel-shrink.MuiFormLabel-root {
  font-size: 1rem !important;
  transform: translate(14px, -5px) scale(0.75);
}

/* .FormGroup legend {
    float: unset !important;
    font-size: calc(.75rem + .02vw) !important;
} */

.FormGroup .MuiSelect-selectMenu {
  min-height: 1.5em !important;
}

.WhiteBG {
  background-color: #fff;
}

/*iframe {
  display: none;
}*/

.Contact iframe {
  display: unset;
}

.RentalParallax {
  width: 100%;
  height: 500px;
}

.RentalParallax>div {
  width: 100%;
}

.RentalParallax>div img {
  width: 100%;
  object-fit: cover;
  height: 500px;
}

.FormAccording {
  width: 100%;
  padding: 1px 0;
}

.RentalAppliCaro {
  margin: 15px 0 0 0;
}

.FormAccording .MuiAccordion-root,
.FormAccording .MuiAccordion-root.Mui-expanded {
  border-bottom: 0px solid #dfe1e9;
  box-shadow: 0 0 20px 0 #f5f5f5;
  margin: 16px 0 16px 0 !important;
}

.FormAccording.ProspectTask .MuiAccordion-root,
.FormAccording.ProspectTask .MuiAccordion-root.Mui-expanded {
  border-bottom: 0px solid #dfe1e9;
  box-shadow: 0 0 20px 0 #f5f5f5 !important;
  margin: 16px 0 16px 0 !important;
}

.FormAccording.DeviceAlert .MuiAccordion-root,
.FormAccording.DeviceAlert .MuiAccordion-root.Mui-expanded {
  border-bottom: 0px solid #dfe1e9;
  box-shadow: 0 0 20px 0 #f5f5f5 !important;
  margin: 16px 0 16px 0 !important;
  border-radius: 6px !important;
}

.FormAccording.Shadow .MuiAccordion-root {
  background: #ffffff;
  box-shadow: 0px 0px 20px #f5f5f5;
  border-radius: 8px;
  padding: 3px 14px;
  margin: 12px 0 16px 0 !important;
  border: none;
  position: relative;
  overflow: hidden;
}

/* .FormAccording.Shadow .MuiPaper-root:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background: #3aaa35;
  left: 0;
  top: 0;
} */

.FormAccording.Shadow .MuiPaper-root.cls-bill:after {
  background: #808080;
}

.FormAccording.Shadow .MuiPaper-root.cls-pending:after {
  background: #ffbc00;
}

.FormAccording.Shadow .MuiPaper-root.cls-failed:after {
  background: #f44336;
}

.FormAccording.Shadow .MuiPaper-root.cls-credit:after,
.FormAccording.Shadow .MuiPaper-root.cls-completed:after {
  background: #3aaa35;
}

.FormAccording.Shadow .MuiAccordionSummary-content {
  margin: 12px 0 !important;
}

.FormAccording .MuiAccordion-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0) !important;
}

.FormAccording .MuiAccordion-root:last-of-type {
  border-bottom: 0px solid #dfe1e9;
}

.FormAccording .MuiAccordionSummary-root,
.FormAccording.Theme .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  min-height: 60px;
  padding: 16px;
  border-bottom: 0px solid #dfe1e9;
  margin-bottom: -2.5px;
}

.FormAccording.TenantDetails .MuiAccordionSummary-root,
.FormAccording.Theme.TenantDetails .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  min-height: 60px;
  padding: 0;
  border-bottom: 0px solid #dfe1e9;
  margin-bottom: -2.5px;
}

.FormAccording.ProspectTask .MuiAccordionSummary-root,
.FormAccording.Theme.ProspectTask .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  min-height: 60px;
  padding: 16px;
  border-bottom: 0px solid #dfe1e9;
  margin-bottom: -2.5px;
}

.FormAccording.DeviceAlert .MuiAccordionSummary-root,
.FormAccording.Theme.DeviceAlert .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  min-height: 60px;
  padding: 0px;
  border-bottom: 0px solid #dfe1e9;
  margin-bottom: -2.5px;
}

.FormAccording .MuiAccordionSummary-root .MuiAccordionSummary-content,
.FormAccording .MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
  padding: 0 15px 0 5px;
}

.FormAccording.TenantDetails .MuiAccordionSummary-root .MuiAccordionSummary-content,
.FormAccording.TenantDetails .MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
  padding: 0 30px 0 0;
  margin: 0px 0 !important;
}

.FormAccording.ReverseArrow .MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 0;
  padding: 0 0 0 0px;
}

/* .FormAccording .MuiButtonBase-root.MuiIconButton-root,
.FormAccording .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  right: unset;
  left: 14px;
  padding-left: 0;
  padding-right: 0;
  color: #2c2c2c;
  margin: 0;
} */

.FormAccording.DeviceAlert .MuiButtonBase-root.MuiIconButton-root,
.FormAccording.DeviceAlert .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  right: 14px;
  left: unset;
  padding-left: 0;
  padding-right: 0;
  color: #2c2c2c;
  margin: 0;
}

/* .FormAccording.TenantDetails .MuiButtonBase-root.MuiIconButton-root, */
.FormAccording.TenantDetails .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  right: 14px;
  left: unset;
  padding-left: 0;
  padding-right: 0;
  color: #2c2c2c;
  margin: 0;
}

.FormAccording.TenantDetails .MuiButtonBase-root.MuiIconButton-root svg,
.FormAccording.TenantDetails .MuiAccordionSummary-expandIconWrapper svg {
  width: 24px;
  height: auto;
}

.FormAccording.ReverseArrow .MuiAccordionSummary-expandIconWrapper {
  position: relative;
  right: unset;
  left: unset;
  padding-left: 0;
  padding-right: 0;
  color: #2c2c2c;
  margin: 0;
}

.FormAccording.ProspectTask .MuiButtonBase-root.MuiIconButton-root {
  /* position: absolute;
  right: unset;
  left: 14px;
  padding-left: 0;
  padding-right: 0;
  color: #2c2c2c; */
  margin: 0;
  padding: 0;
}

.FormAccording.DeviceAlert .MuiButtonBase-root.MuiIconButton-root {
  position: absolute;
  right: unset;
  left: 14px;
  padding-left: 0;
  padding-right: 0;
  color: #2c2c2c;
  margin: 0;
}

.FormAccording.DeviceAlert.Payment .MuiButtonBase-root.MuiIconButton-root {
  position: relative;
  right: unset;
  left: unset;
  padding-left: unset;
  padding-right: unset;
  color: rgba(0, 0, 0, 0.26);
  margin: unset;
}

.FormAccording.ProspectTask .ProspectTaskTable .MuiButtonBase-root.MuiIconButton-root {
  position: relative;
  right: unset;
  left: unset;
  padding-left: unset;
  padding-right: unset;
  color: #2c2c2c;
  margin: unset;
}

.FormAccording .MuiAccordion-root.Mui-expanded .MuiButtonBase-root.MuiIconButton-root {
  color: #2a5bee;
}

.FormAccording.Theme .MuiAccordion-root.Mui-expanded .MuiButtonBase-root.MuiIconButton-root {
  color: #502688;
}

.FormAccording.Theme .MuiAccordion-root.Mui-expanded .MuiButtonBase-root.MuiIconButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}

.FormAccording .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root p {
  color: #2a5bee;
}

.FormAccording.Theme .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root p {
  color: #505050;
}

.FormAccording .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  border-bottom: 2.5px solid #2a5bee;
}

.FormAccording.Theme.Shadow .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  border-bottom: 0px solid #230058;
}

.FormAccording.Theme .MuiAccordion-root:before {
  display: none;
}

.FormAccording .MuiAccordion-root .MuiCollapse-container .MuiAccordionDetails-root {
  padding: 0px 16px 16px 16px;
}

.FormAccording.ProspectTask .MuiAccordion-root .MuiCollapse-container .MuiAccordionDetails-root {
  padding: 0px 16px 16px 16px;
}

.FormAccording.DeviceAlert .MuiAccordion-root .MuiCollapse-container .MuiAccordionDetails-root {
  padding: 0px 16px 16px 16px;
}

.HistoryAccording .MuiAccordion-root {
  border-bottom: 0px solid #dfe1e9;
  margin-bottom: 0px !important;
  box-shadow: none;
  border-radius: 0;
  background: transparent;
}

.HistoryAccording.WhiteBG .MuiAccordion-root {
  margin-bottom: 0px !important;
}

.HistoryAccording.Shadow .MuiAccordion-root {
  background: #ffffff;
  box-shadow: 0px 0px 20px #f5f5f5;
  border-radius: 8px;
  padding: 3px 14px;
  margin: 12px 0 16px 0 !important;
  border: none;
}

.HistoryAccording.Shadow .MuiAccordionSummary-content {
  margin: 12px 0 !important;
}

.HistoryAccording .MuiAccordion-root {
  border-top: 1px solid #355668;
  margin: 0 !important;
}

.HistoryAccording .MuiAccordion-root:first-child {
  border-top: none;
}

.HistoryAccording.WhiteBG .MuiAccordion-root:last-of-type {
  border-top: 1px solid #dee2e6;
  margin: 0;
}

.HistoryAccording .MuiAccordion-root::before {
  display: none;
}

.HistoryAccording .MuiAccordionSummary-root {
  min-height: 80px;
  padding: 0 20px 0 20px;
  /* border-bottom: 2.5px solid #DFE1E9; */
  margin-bottom: -2.5px;
  background: transparent;
}

.HistoryAccording .MuiAccordionSummary-content {
  margin: 12px 0 !important;
}

.HistoryAccording .MuiAccordionSummary-expandIconWrapper {
  color: #fff;
}

.HistoryAccording.WhiteBG .MuiAccordionSummary-expandIconWrapper {
  color: #505050;
}

.HistoryAccording .MuiButtonBase-root.MuiIconButton-root {
  position: absolute;
  right: 28px;
  padding-left: 0;
  padding-right: 0;
  color: #ffffff;
}

.HistoryAccording .MuiAccordion-root.Mui-expanded .MuiButtonBase-root.MuiIconButton-root {
  color: #ffffff;
}

.HistoryAccording.Theme .MuiAccordion-root.Mui-expanded .MuiButtonBase-root.MuiIconButton-root {
  color: #ffffff;
}

.HistoryAccording .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root p {
  color: #ffffff;
}

.HistoryAccording.Theme .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root p {
  color: #ffffff;
}

.HistoryAccording .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  border-bottom: 1px solid #4b525c;
}

.HistoryAccording.Theme .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  border-bottom: 1px solid #355668;
}

.HistoryAccording.Theme.WhiteBG .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  border-bottom: 1px solid #dee2e6;
}

.HistoryAccording.Theme.Shadow .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  border-bottom: 0px solid #230058;
}

.HistoryAccording .MuiAccordion-root .MuiCollapse-container .MuiAccordionDetails-root {
  padding: 0;
}

.carouselWith-custom-dots {
  padding-bottom: 70px;
  max-height: 860px;
}

.carouselWith-custom-dots ul li {
  flex: auto !important;
  margin: 0 2px;
}

.carouselWith-custom-dots ul li button.MuiButtonBase-root span.MuiTouchRipple-root {
  display: none;
}

.carouselWith-custom-dots ul li .MuiCardContent-root {
  padding: 0px;
}

.carouselWith-custom-dots .react-multiple-carousel__arrow::before {
  font-size: 60px;
}

.carouselWith-custom-dots .react-multiple-carousel__arrow--right {
  right: calc(0% + 0px);
  width: 150px;
  height: 100%;
  border-radius: 0;
  background: none;
  background-image: linear-gradient(to right,
      rgba(67, 66, 66, 0),
      rgba(67, 66, 66, 0.8));
  transition: all 0.3s;
}

.carouselWith-custom-dots .react-multiple-carousel__arrow--right:hover {
  background: none;
  background-image: linear-gradient(to right,
      rgba(67, 66, 66, 0),
      rgba(25, 25, 25, 0.8));
}

.carouselWith-custom-dots .react-multiple-carousel__arrow--left {
  left: calc(0% + 0px);
  width: 150px;
  height: 100%;
  border-radius: 0;
  background: none;
  background-image: linear-gradient(to right,
      rgba(67, 66, 66, 0.8),
      rgba(67, 66, 66, 0));
  transition: all 0.3s;
}

.carouselWith-custom-dots .react-multiple-carousel__arrow--left:hover {
  background: none;
  background-image: linear-gradient(to right,
      rgba(25, 25, 25, 0.8),
      rgba(67, 66, 66, 0));
}

.carouselWith-custom-dots .react-multiple-carousel__arrow--left:focus,
.carouselWith-custom-dots .react-multiple-carousel__arrow--right:focus {
  outline: none;
}

.carouselWith-custom-dots .react-multi-carousel-dot-list {
  background: #fff;
  z-index: 9999;
}

.carouselWith-custom-dots .react-multi-carousel-dot-list button.custom-dot {
  padding: 0;
  opacity: 0.4;
}

.carouselWith-custom-dots .react-multi-carousel-dot-list button.custom-dot .MuiAvatar-root {
  width: 100px;
  height: 65px;
  border-radius: 0;
}

.RentalAppliCaro .control-dots {
  display: none;
}

.RentalAppliCaro .carousel-status {
  display: none;
}

.RentalAppliCaro .carousel.carousel-slider .control-arrow {
  top: auto;
  color: #2c2c2c;
  font-size: 26px;
  bottom: 16px;
  margin-top: 0;
  padding: 5px;
  width: 40px;
  height: 40px;
  background-color: rgb(255 255 255 / 70%);
  backdrop-filter: blur(2px);
  opacity: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RentalAppliCaro .carousel .control-arrow:before {
  margin: 0 !important;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: "";
  /* z-index: 2; */
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #2c2c2c !important;
}

.RentalAppliCaro .carousel.carousel-slider .control-arrow.control-prev {
  right: 66px !important;
  left: auto !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #2c2c2c !important;
}

.RentalAppliCaro .carousel.carousel-slider .control-arrow.control-next {
  right: 16px !important;
}

.RentalAppliCaro .carousel .slide img {
  height: 430px;
  object-fit: cover;
  object-position: center;
}

.RentalAppliCaro .carousel .thumbs-wrapper {
  margin: 0;
}

.RentalAppliCaro .carousel .thumbs-wrapper ul {
  padding: 0;
  display: flex;
  justify-content: center;
}

.RentalAppliCaro .carousel .thumbs-wrapper ul li {
  width: 130px !important;
  height: 80px;
  margin: 24px 12px;
  border: none !important;
  padding: 0 !important;
  opacity: 0.4;
}

.RentalAppliCaro .carousel .thumbs-wrapper ul li.thumb.selected {
  opacity: 1;
}

.RentalAppliCaro .carousel .thumbs-wrapper ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.CusSelect {
  position: relative;
  z-index: 2;
}

.CusSelect>div>div>div {
  min-height: 56px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2c2c2c;
  padding: 0.375rem 0.5rem 0.375rem 0.5rem;
  border: 1px solid #dfe1e9;
  border-radius: 0px;
}

.CusSelect2 {
  position: relative;
  z-index: 2;
}

.CusSelect2>div>div {
  border: 1px solid #dfe1e9;
  border-radius: 0px;
}

.CusSelect2>div>div>div {
  min-height: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2c2c2c;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  border: 0px solid #dfe1e9;
  border-radius: 0px;
}

.AppliAccoFormDate {
  border: 1px solid #dfe1e9;
  border-radius: 0px;
  position: relative;
}

.AppliAccoFormDate .MuiFormControl-root {
  width: 100%;
  position: relative;
  z-index: 1;
}

.AppliAccoFormDate label {
  display: none;
}

.AppliAccoFormDate .MuiInputBase-root {
  min-height: 56px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2c2c2c;
  padding: 0 0.75rem;
  margin: 0 !important;
}

.AppliAccoFormDate .MuiInputBase-root::after,
.AppliAccoFormDate .MuiInputBase-root::before {
  display: none;
}

.AppliCheckBox {
  -webkit-align-items: start !important;
  align-items: start !important;
  margin: 0;
}

.AppliCheckBox>span.MuiIconButton-root {
  padding: 0 12px 0 0 !important;
  font-weight: 500 !important;
  position: relative !important;
}

.AppliCheckBox>span.MuiIconButton-root svg {
  width: 25px;
  height: 25px;
}

.AppliCheckBox>span.MuiTypography-root {
  padding: 0;
  font-weight: 600 !important;
  font-size: 16px;
  color: #333;
}

.OnOffSwitch.Right {
  display: flex;
  justify-content: flex-end;
}

.OnOffSwitch .MuiSwitch-root {
  width: 44px;
  height: 26px;
  padding: 0;
  border-radius: 22px;
}

.OnOffSwitch .MuiSwitch-switchBase {
  top: 2px;
  transform: translateX(2px);
  padding: 0;
}

.OnOffSwitch .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(20px);
}

.OnOffSwitch .MuiSwitch-thumb {
  width: 20px;
  height: 20px;
}

.OnOffSwitch .MuiSwitch-track {
  width: 44px;
  height: 26px;
}

.OnOffSwitch .MuiFormControlLabel-root {
  margin: 0;
}

.OnOffSwitch .MuiFormControlLabel-label {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #333333;
  margin: 0 12px 0 0;
}

.OnOffSwitch.AddTenantT .MuiFormControlLabel-root {
  margin: 10px 0 5px 0;
}

.OnOffSwitch.AddTenantT .MuiFormControlLabel-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #2c2c2c;
  margin-bottom: 0px;
}

.OnOffSwitch.AddTenantT .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #a069c6;
  opacity: 1;
}

.OnOffSwitch.AddTenantT .MuiSwitch-colorSecondary.Mui-checked .MuiIconButton-label {
  border: 1px solid #a069c6;
  border-radius: 50%;
  color: #fff;
}

.WaterMeterSS .MuiSwitch-root {
  width: 100%;
  height: 50px;
  padding: 0;
  border-radius: 50px;
  overflow: unset;
}

.WaterMeterSS.Pulse .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(58, 164, 67, 0.6);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(58, 164, 67, 0);
  }
}

.WaterMeterSS.Pulse .MuiButtonBase-root .MuiSwitch-thumb {
  animation: pulse-animation-yellow 2s infinite;
}

@keyframes pulse-animation-yellow {
  0% {
    box-shadow: 0 0 0 0px rgba(248, 213, 37, 0.6);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(248, 213, 37, 0);
  }
}

.WaterMeterSS .MuiSwitch-switchBase {
  top: 0px;
  transform: translateX(0px);
  padding: 0;
}

.WaterMeterSS .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(261px);
}

.WaterMeterSS .MuiButtonBase-root.Mui-checked+.MuiSwitch-track {
  background-color: #eefcf0 !important;
  opacity: 1;
}

.WaterMeterSS .MuiSwitch-thumb {
  width: 50px;
  height: 50px;
}

.WaterMeterSS .MuiButtonBase-root .MuiIconButton-label {
  position: relative;
}

.WaterMeterSS .MuiButtonBase-root input {
  left: -300px;
  width: 600px;
}

.WaterMeterSS .MuiButtonBase-root .MuiIconButton-label {
  border: 1px solid #f8d525;
  border-radius: 50%;
}

.WaterMeterSS .MuiButtonBase-root.Mui-checked .MuiIconButton-label {
  border: 1px solid #3aa443;
  border-radius: 50%;
}

.WaterMeterSS .MuiButtonBase-root .MuiSwitch-thumb {
  background-color: #f8d525;
  border-color: #f8d525;
}

.WaterMeterSS .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb {
  background-color: #3aa443;
  border-color: #3aa443;
}

.WaterMeterSS .MuiSwitch-track {
  width: 100%;
  height: 50px;
  position: relative;
  background-color: #fff9db;
  border-radius: 30px;
  opacity: 1;
}

.WaterMeterSS .MuiSwitch-switchBase~.MuiSwitch-track:after {
  content: "Water Off";
  position: absolute;
  font-size: 13px;
  line-height: normal;
  font-weight: 500;
  color: #000;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
}

.WaterMeterSS .MuiSwitch-switchBase.Mui-checked~.MuiSwitch-track:after {
  content: "Water On";
  right: unset;
  left: 15px;
}

.WaterMeterSS .MuiFormControlLabel-root {
  margin: 0;
}

.WaterMeterSS .MuiFormControlLabel-label {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #333333;
  margin: 0 12px 0 0;
}

.WaterMeterSS.AddTenantT .MuiFormControlLabel-root {
  margin: 10px 0 5px 0;
}

.WaterMeterSS.AddTenantT .MuiFormControlLabel-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #2c2c2c;
  margin-bottom: 0px;
}

.WaterMeterSS.AddTenantT .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #a069c6;
  opacity: 1;
}

.WaterMeterSS.AddTenantT .MuiSwitch-colorSecondary.Mui-checked .MuiIconButton-label {
  border: 1px solid #a069c6;
  border-radius: 50%;
  color: #fff;
}

.WaterMeterSS .MuiButtonBase-root .MuiSwitch-thumb:after {
  content: "";
  position: absolute;
  background-image: url("./range-controller/ion_water.svg");
  width: 26px;
  height: 26px;
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.AddTenantT {
  position: relative;
}

.CusWithOverflowHide .MuiDialog-paper {
  overflow: unset !important;
}

.CusWithOverflowHide .MuiDialogActions-root {
  padding: 10px 20px 20px 20px;
}

.ContinuePopupBU {
  background: #6d4da0;
  border: 1px solid #6d4da0;
  color: #fff;
  transition: all ease-in-out 0.3s;
  min-width: 90px;
}

.ContinuePopupBU:hover {
  background: #230058;
  border: 1px solid #230058;
  color: #fff;
}

.MultiSelect .css-1rhbuit-multiValue {
  background-color: #f1ecf3;
  border-radius: 6px;
  margin: 0 4px;
}

.MultiSelect .css-12jo7m5 {
  padding: 7px;
  padding-left: 10px;
}

.MultiSelect .css-xb97g8 {
  padding-left: 6px;
  padding-right: 6px;
}

.MultiSelect .css-xb97g8 svg {
  width: 16px;
  height: 16px;
}

.MultiSelect .css-g1d714-ValueContainer {
  padding: 2px 3px;
}

/**************************************** Smart device widget *********************************************/

.DoorLock {
  height: 40px !important;
  color: #f5f0f0 !important;
  padding: 0 !important;
  border-radius: 4px !important;
  overflow: hidden;
  background-image: url("./range-controller/SlideArrowS.svg"),
    url("./range-controller/SlideArrowS.svg"),
    url("./range-controller/SlideArrowB.svg"),
    url("./range-controller/SlideArrowS.svg"),
    url("./range-controller/SlideArrowS.svg");
  background-repeat: no-repeat;
  background-size: 7px auto, 7px auto, 10px auto, 7px auto, 7px auto;
  background-position: 24%, 37%, 50%, 63%, 76%;
}

.DoorLock .MuiSlider-rail {
  height: 40px;
}

.DoorLock .MuiSlider-track {
  height: 40px;
  background-color: rgb(109, 77, 159);
  border: none;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
}

.DoorLock .MuiSlider-thumb {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-left: 20px;
  border-radius: 4px;
  background-color: #edf9ff;
  box-shadow: none !important;
  transition: all 0.2s ease-in-out;
}

.DoorLock .MuiSlider-thumb:hover {
  box-shadow: none;
}

.DoorLock .MuiSlider-thumb:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  /* background: #000; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("./range-controller/key.svg");
  background-size: cover;
  border-radius: 0;
  /* background-color: transparent; */
  box-shadow: none;
}

.DoorLock .MuiSlider-mark {
  opacity: 0;
}

/* .DoorLock input[value="1"]~.MuiSlider-thumb,
.DoorLock input[value="2"]~.MuiSlider-thumb,
.DoorLock input[value="3"]~.MuiSlider-thumb,
.DoorLock input[value="4"]~.MuiSlider-thumb,
.DoorLock input[value="5"]~.MuiSlider-thumb,
.DoorLock input[value="6"]~.MuiSlider-thumb,
.DoorLock input[value="7"]~.MuiSlider-thumb,
.DoorLock input[value="8"]~.MuiSlider-thumb,
.DoorLock input[value="9"]~.MuiSlider-thumb {
    left: 0 !important;
} */

.DoorlockSBU {
  width: 40px;
  height: 40px;
  background: #502688;
  border-radius: 4px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DoorlockSBU img {
  width: auto;
  height: 20px;
}

.DoorLockWidget {
  width: 100%;
  position: relative;
}

.CustomMenuWithBU.smartdevice .e-gridheader .e-headercontent table tr th div {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #230058;
  padding: 16px 10px;
  height: auto;
}

.CustomMenuWithBU.smartdevice .e-gridcontent .e-content table tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 16px 15px 16px 10px !important;
  height: auto;
  letter-spacing: 0.01em;
  color: #505050;
  opacity: 1;
}

/* .CustomMenuWithBU.smartdevice .e-gridcontent table tr:nth-child(odd) {

    background: #fff;
} */

/* .CustomMenuWithBU.smartdevice .e-grid.e-gridhover tr[role="row"]:not(.e-editedrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    background-color: #fff;
} */

.CustomMenuWithBU.smartdevice .e-gridpager .e-pagercontainer {
  margin-left: 0;
}

.CustomMenuWithBU.smartdevice .e-gridpager .e-parentmsgbar {
  padding-right: 0;
}

.ChooseColorSec {
  width: 100px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
}

.DimmerWidget {
  height: 492px;
  width: 200px;
  padding: 20px 0 0 0;
  overflow: hidden;
  position: relative;
  margin-right: 0px;
}

.DimmerWidget .MuiSlider-root.MuiSlider-vertical {
  margin: 0px 0 0px auto !important;
  margin-left: CalC(100% - 115px) !important;
  height: 450px !important;
  width: 3.8px !important;
  background-color: #502688 !important;
  box-shadow: none !important;
  position: relative;
  padding: 0;
}

.DimmerWidget.Thermostat .MuiSlider-root.MuiSlider-vertical {
  background-color: #ff9900 !important;
}

.DimmerWidget.Disable .MuiSlider-root.MuiSlider-vertical {
  background-color: #bcbcbc !important;
}

.Disable .RangeLabel {
  z-index: 9;
}

.DimmerWidget:after {
  content: "";
  width: 3.8px;
  height: 100px;
  background: #fff;
  background: linear-gradient(180deg, rgb(77 123 147) 17%, rgb(80 38 136) 60%, rgb(80 38 136) 100%);
  top: 0;
  left: 86px;
  position: absolute;
  z-index: 1;
}

.DimmerWidget.Thermostat:after {
  background: linear-gradient(180deg, rgb(77 123 147) 17%, rgb(255 153 0) 60%, rgb(255 153 0) 100%);
}

.DimmerWidget.Disable:after {
  background: linear-gradient(180deg, rgb(77 123 147) 17%, rgb(188 188 188) 60%, rgb(188 188 188) 100%);
}

.DimmerWidget:before {
  content: "";
  width: 3.8px;
  height: 100px;
  background: #fff;
  background: linear-gradient(0deg, rgb(77 123 147) 17%, rgb(80 38 136) 60%, rgb(80 38 136) 100%);
  bottom: 0;
  left: 86px;
  position: absolute;
  z-index: 2;
}

.DimmerWidget.Thermostat:before {
  background: linear-gradient(0deg, rgb(77 123 147) 17%, rgb(255 153 0) 60%, rgb(255 153 0) 100%);
}

.DimmerWidget.Disable:before {
  background: linear-gradient(0deg, rgb(77 123 147) 17%, rgb(188 188 188) 60%, rgb(188 188 188) 100%);
}

.DimmerWidget .MuiSlider-root.MuiSlider-vertical:before {
  content: "";
  width: 3px;
  height: 50px;
  background: #fff;
  background: linear-gradient(0deg,
      rgb(255 255 255) 0%,
      rgb(255 255 255) 30%,
      rgb(255 255 255 / 66%) 60%,
      rgba(255, 0, 0, 0) 100%);
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}

.DimmerWidget .MuiSlider-root.MuiSlider-vertical:after {
  content: "";
  width: 3px;
  height: 50px;
  background: #fff;
  background: linear-gradient(180deg,
      rgb(255 255 255) 0%,
      rgb(255 255 255) 30%,
      rgb(255 255 255 / 66%) 60%,
      rgba(255, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}

.DimmerWidget .MuiSlider-root.MuiSlider-vertical .MuiSlider-rail {
  color: #502688;
  width: 3px !important;
}

.DimmerWidget .MuiSlider-root.MuiSlider-vertical .MuiSlider-track {
  color: #502688;
  width: 3px !important;
}

.DimmerWidget.Thermostat .MuiSlider-root.MuiSlider-vertical .MuiSlider-rail {
  color: #ff9900;
}

.DimmerWidget.Thermostat .MuiSlider-root.MuiSlider-vertical .MuiSlider-track {
  color: #ff9900;
}

.DimmerWidget.Disable .MuiSlider-root.MuiSlider-vertical .MuiSlider-rail,
.DimmerWidget.Disable .MuiSlider-root.MuiSlider-vertical .MuiSlider-track {
  color: #bcbcbc;
}

.DimmerWidget .MuiSlider-thumb {
  border-radius: 50%;
  margin-top: 0px;
  z-index: 9;
  opacity: 1;
  border: none;
  bottom: 61%;
  width: 40px !important;
  height: 40px !important;
  left: -13px !important;
  background: rgb(255, 255, 255) !important;
  position: absolute;
}

.DimmerWidget .MuiSlider-thumb:hover {
  box-shadow: none;
}

.DimmerWidget .MuiSlider-thumb:after {
  content: "";
  position: absolute;
  width: 140px;
  height: 184px;
  /* background: #000; */
  top: 21px;
  left: 22px;
  background-image: url("./range-controller/Range-25.svg");
  border-radius: 0;
}

.DimmerWidget.Disable .MuiSlider-thumb:after {
  background-image: url("./range-controller/Range-25-D.svg");
}

.DimmerWidget.Thermostat .MuiSlider-thumb:after {
  content: "";
  background-image: url("./range-controller/RangeThermo-25.svg");
}

.DimmerWidget.Thermostat.Disable .MuiSlider-thumb:after {
  content: "";
  background-image: url("./range-controller/RangeThermo-25-D.svg");
}

.DimmerWidget .MuiSlider-root.MuiSlider-vertical .MuiSlider-thumb[aria-valuenow="100"]~.RangeLabel .Text100,
.DimmerWidget .MuiSlider-thumb[aria-valuenow="191"]~.RangeLabel .Text75,
.DimmerWidget .MuiSlider-thumb[aria-valuenow="127"]~.RangeLabel .Text50,
.DimmerWidget .MuiSlider-thumb[aria-valuenow="63"]~.RangeLabel .Text25,
.DimmerWidget .MuiSlider-thumb[aria-valuenow="1"]~.RangeLabel .Text0 {
  font-size: 24px;
  color: #272208;
  font-weight: 500;
}

.disabled .RangeLabel p {
  color: #bcbcbc !important;
}

.OnOffSwitchSmartDE .MuiSwitch-root {
  width: 32px !important;
  height: 22px !important;
  padding: 0 !important;
  border-radius: 22px !important;
}

.OnOffSwitchSmartDE .MuiSwitch-switchBase {
  top: 2px !important;
  transform: translateX(3px) !important;
  padding: 0 !important;
}

.OnOffSwitchSmartDE .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(12px) !important;
}

.OnOffSwitchSmartDE .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #2fa42b !important;
  opacity: 1 !important;
}

.OnOffSwitchSmartDE .MuiSwitch-colorSecondary.Mui-checked .MuiIconButton-label {
  border: 1px solid #2fa42b !important;
}

.OnOffSwitchSmartDE .MuiSwitch-thumb {
  width: 18px !important;
  height: 18px !important;
  border: 1px solid #eeeeee !important;
}

.OnOffSwitchSmartDE .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  border: 1px solid #ffffff !important;
}

.OnOffSwitchSmartDE .MuiSwitch-track {
  width: 32px !important;
  height: 22px !important;
  background-color: #000
}

.OnOffSwitchSmartDE .MuiFormControlLabel-root {
  margin: 0 !important;
}

.OnOffSwitchSmartDE .MuiFormControlLabel-label {
  font-size: 14px !important;
  line-height: 30px !important;
  font-weight: 600 !important;
  color: #333333 !important;
  margin: 0 12px 0 0 !important;
}

/**************************************** Smart device widget *********************************************/

.LeaseUpload .MuiChip-deleteIcon {
  display: block !important;
}

.ChatSideBar hr.MuiDivider-root {
  display: none;
}

.ApartmentListSec {
  padding-top: 0;
  padding-bottom: 0;
  margin: 12px 0 0 11px !important;
  border-left: 1px solid #ef8977;
}

.Private.ApartmentListSec {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0px 0 0 0px !important;
  border-left: 0px solid #ef8977;
}

.ApartmentListSec .MuiListItem-root {
  padding: 10px 16px 10px 26px;
}

.ApartmentListSec.Private .MuiListItem-root {
  padding: 16px 25px 16px 24px;
}

.ApartmentListSec .MuiListItem-root:hover,
.ApartmentListSec .MuiListItem-root.communication_activeList__3xsMq {
  background-color: rgba(251, 247, 252, 1);
}

.ApartmentListSec .MuiListItem-root .MuiAvatar-root {
  width: 44px;
  height: 44px;
  background: #f48272;
}

.ApartmentListSec .MuiListItem-root .MuiAvatar-root img {
  width: 22px;
  height: auto;
}

.ApartmentListSec .MuiListItem-root .GroupName .TenantName {
  margin: 0;
}

.ApartmentListSec .MuiListItem-root .GroupName .TenantName span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #343434;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ApartmentListSec .MuiListItem-root .GroupName .AptName {
  margin: 6px 0 0 0;
}

.ApartmentListSec .MuiListItem-root .GroupName .AptName span {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.13px;
  color: #666;
}

.MessageSecCommon.RightSideMessage {
  display: flex;
  justify-content: flex-end;
}

.MessageSecCommon.RightSideMessage .MuiTypography-root p {
  background: #502688 !important;
  color: #fff;
}

.MessageSecCommon {
  height: auto !important;
  padding: 16px 24px 5px 24px;
}

.PopupSecBody .MessageSecCommon {
  height: auto !important;
  padding: 16px 16px 10px 16px;
}

.MessageSecCommon .MuiAvatar-root {
  width: 44px;
  height: 44px;
  margin-right: 16px;
}

.ChatMessageSec {
  position: relative;
}

.MuiTooltip-tooltip {
  text-align: center;
  margin-top: 10px !important;
}

.ApartmentSarchSec .dropdown-menu {
  padding: 16px !important;
}

.ApartmentSarchSec .dropdown-menu .dropdown-item.disabled {
  padding: 16px 0 6px 0 !important;
  border-top: 1px solid #dfe2e6;
  margin-top: 12px;
}

.ApartmentSarchSec .dropdown-menu .dropdown-item.disabled:first-child {
  padding: 6px 0 6px 0 !important;
  border-top: 0px solid #dfe2e6;
  margin-top: 0px;
}

.ApartmentSarchSec .dropdown-menu .dropdown-item.disabled>div {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #ff8271;
}

.ApartmentSarchSec .dropdown-menu .dropdown-item {
  padding: 6px 0 6px 16px !important;
}

.ApartmentSarchSec .dropdown-menu .dropdown-item.disabled:hover {
  background-color: transparent !important;
}

.ApartmentSarchSec .dropdown-menu .dropdown-item:hover {
  background-color: #fbf7fc !important;
}

.ApartmentSarchSec .dropdown-menu .dropdown-item>div {
  margin: 0 !important;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #343434;
}

.ApartmentSarchSec .dropdown-menu .dropdown-item>div>div {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #666666;
}

.CheckPay {
  width: 100%;
  display: flex;
  align-items: center;
}

.RecText {
  cursor: pointer;
}

.RecText svg {
  width: 22px;
  margin-left: 12px;
}

.RecPayText {
  font-size: 13px;
  line-height: 18px;
  border: 1px solid #dfe1e9;
  border-radius: 6px;
  padding: 10px 15px;
  color: #1a1a1a;
}

.CheckPay .MuiCheckbox-colorSecondary .MuiSvgIcon-root {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.CheckPay .MuiCheckbox-colorSecondary.Mui-checked {
  color: #2a5bee;
}

.CheckPay .MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #343434;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.SmartDeTabs {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  flex-direction: unset;
}

.CustomBG path {
  fill: url(#MyGradient);
}

.CustomBG2 path {
  fill: url(#MyGradient2);
}

.CustomBG3 path {
  fill: url(#MyGradient3);
}

.CustomBG4 path {
  fill: url(#MyGradient4);
}

.CustomBG5 path {
  fill: url(#MyGradient5);
}

.CustomBG6 path {
  fill: url(#MyGradient6);
}

.CustomBG path:last-child,
.CustomBG2 path:last-child,
.CustomBG3 path:last-child,
.CustomBG4 path:last-child,
.CustomBG5 path:last-child,
.CustomBG6 path:last-child {
  fill: transparent;
}

.OnText {
  color: #505050;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.16px;
  position: absolute;
  right: 50px;
  top: 30px;
}

.OnText::after {
  content: "";
  width: 40px;
  height: 1px;
  background: #ccc;
  left: -50px;
  top: 50%;
  position: absolute;
}

.OffText {
  color: #505050;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.16px;
  position: absolute;
  left: 50px;
  top: 60px;
}

.OffText::after {
  content: "";
  width: 40px;
  height: 1px;
  background: #ccc;
  right: -45px;
  top: 50%;
  position: absolute;
}

.PopTokenText {
  color: #484848;
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
  margin: 15px 0 15px 0;
}

.PopTokenTText {
  color: #2FA42B;
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
  margin: 12px 0 -8px 0;
}

.Poplink {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
}

.PopTokenLink {
  color: #502688 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background: transparent;
  padding: 0;
  border-bottom: 1.5px solid;
  transition: all .3s ease-in-out;
}

.PopTokenLink:hover {
  color: #230058 !important;
}

.PopTokenFormSec {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dfe1e9;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.FormCodeSec {
  color: #0b0e13;
  font-size: 15px;
  font-weight: 500;
  margin: 0px 0 0 18px;
  padding: 0 0 0 12px;
  border-left: 1px solid #dfe1e9;
  line-height: 19px;
}

.PopTokenInput {
  width: 100%;
  color: #0b0e13;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  border: none;
  padding: 0 0 0 12px;
}

.NewPopUp .SubmitPopupBUPopPin {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  background: #502688;
  border: 1px solid #502688;
  border-radius: 8px;
  padding: 10px 16px;
  margin-right: 0px;
  margin-left: 0;
  width: 100%;
  transition: all .3s ease-in-out;
}

.NewPopUp .SubmitPopupBUPopPin:hover {
  background: #230058;
  border: 1px solid #230058;
}

.NewPopUp .CancelPopupBUPin {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  padding: 9.5px 16px !important;
  margin: 0 12px 0 0 !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .CancelPopupBUPin svg {
  width: 22px;
  height: auto;
}

.NewPopUp .CancelPopupBUPin:hover {
  background: #f1ecf3 !important;
  color: #343434 !important;
  border: 1px solid #e1e1e1 !important;
}

.PinArea {
  width: 100%;
}

.PinArea>div input {
  width: 100% !important;
  border: 1px solid #e1e1e1;
  height: 50px;
  border-radius: 4px;
}

.PinArea>div span {
  opacity: 0;
  padding: 0 5px;
}

.CheckPay .MuiFormControlLabel-root {
  margin-top: -3px;
  margin-right: 3px;
}

.DashFilterSec .rs-picker-toggle-wrapper .rs-picker-toggle {
  align-items: center;
  background: transparent !important;
  border: 1px solid #505050 !important;
  border-radius: 8px;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  height: 42px;
  padding: 7px 0px 7px 0px !important;
  width: auto;
}

.DashFilterSec.AddDevice .rs-picker-toggle-wrapper .rs-picker-toggle {
  border: 1px solid #e1e1e1 !important;
}

.DashFilterSec .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item {
  padding: 10px 12px;
}

.DashFilterSec .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item svg {
  width: 17px;
  height: 17px;
  top: 11px;
  right: 10px;
  color: #000;
  position: unset;
}

.DashFilterSec.AddDevice .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item svg {
  width: 17px;
  height: 17px;
  top: 11px;
  right: 10px;
  color: #505050;
  position: unset;
}

.DashFilterSec .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item .rs-btn-close {
  right: 6px;
  top: 11px;
  position: unset;
}

.DashFilterSec .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item .rs-picker-toggle-textbox {
  font-size: 14px !important;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  left: 1px;
  top: 0px;
  width: 105px;
  padding: 7px 0px;
  opacity: 1;
  position: unset;
  display: none;
}

.DashFilterSec .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item .rs-picker-toggle-placeholder,
.DashFilterSec .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item .rs-picker-toggle-value {
  font-size: 14px !important;
  font-weight: 500;
  line-height: 1.5;
  color: #000;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  left: 1px;
  top: 0px;
  width: 140px;
  padding: 7px 0px;
  margin-right: -17px;
}

.DashFilterSec.AddDevice .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item .rs-picker-toggle-placeholder,
.DashFilterSec.AddDevice .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item .rs-picker-toggle-value {
  font-size: 14px !important;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  left: 1px;
  top: 0px;
  width: 120px;
  padding: 10px 0px 7px 0;
  background: transparent;
}

.DashFilterSec .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item .rs-picker-toggle-textbox::placeholder {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
}

.DashFilterSec .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item .rs-picker-toggle-textbox::-ms-input-placeholder {
  /* Edge 12 -18 */
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
}

.DashFilterSec .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item .rs-picker-toggle-value {
  color: #000;
}

.DashFilterSec.AddDevice .rs-picker-toggle-wrapper .rs-picker-toggle .rs-stack-item .rs-picker-toggle-value {
  color: #555555;
}

p+p {
  margin-top: 0 !important;
}

.tox-notifications-container {
  display: none !important;
  width: 0px !important;
  height: 0px !important;
  overflow: hidden;
}

.tox-tinymce {
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
}

.tox-edit-area iframe {
  display: unset;
}

.tox .tox-editor-header {
  z-index: 0 !important;
  border-bottom: 1px solid #e1e1e1 !important;
}

.tox-statusbar {
  display: none !important;
}

.CusSelectFilter .MuiFormControl-root {
  width: 100%;
}

.CusSelectFilter .MuiFormLabel-root {
  font-size: 1em !important;
  margin-bottom: 0.5em !important;
  top: -4px;
}

.CusSelectFilter.Filter .MuiFormLabel-root {
  font-size: 1em !important;
  margin-bottom: 0.5em !important;
  top: -4px;
  color: #212529;
}

.CusSelectFilter.AddTour .MuiFormLabel-root {
  font-size: 1em !important;
  margin-bottom: 0.5em !important;
  top: 0px;
}

.CusSelectFilter .MuiFormLabel-root.MuiInputLabel-shrink {
  font-size: 1rem !important;
  top: 0px;
}

.CusSelectFilter .MuiFormControl-root .MuiOutlinedInput-root {
  border-radius: 8px;
}

.DashFilterSec .rs-picker .rs-input-group {
  align-items: center;
  background: transparent;
  border: 1px solid #502688 !important;
  border-radius: 8px;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  height: 42px;
  padding: 7px 0px 7px 12px !important;
  width: auto;
}

.DashFilterSec.AddDevice .rs-picker-toggle-wrapper .rs-picker-toggle,
.DashFilterSec.AddDevice .rs-picker .rs-input-group {
  border: 1px solid #e1e1e1 !important;
}

.DashFilterSec .rs-picker .rs-input-group:focus,
.DashFilterSec .rs-picker .rs-input-group:focus-within,
.DashFilterSec .rs-picker .rs-input-group:hover {
  outline: none;
  border: 1px solid #502688 !important;
}

.DashFilterSec .rs-picker .rs-input-group .rs-date-range-input {
  font-size: 14px !important;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  left: 1px;
  top: 0px;
  width: 120px;
  padding: 7px 0px;
  background-color: transparent;
}

.DashFilterSec .rs-date-range-input::placeholder {
  color: #502688;
  opacity: 1;
  /* Firefox */
}

.DashFilterSec .rs-date-range-input::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #502688;
}

.DashFilterSec .rs-picker .rs-input-group .rs-date-range-input::placeholder {
  font-size: 1em !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
}

.DashFilterSec .rs-picker .rs-input-group .rs-date-range-input::-ms-input-placeholder {
  /* Edge 12 -18 */
  font-size: 1em !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
}

.DashFilterSec .rs-picker .rs-input-group .rs-input-group-addon svg {
  height: 17px;
  right: 10px;
  top: 11px;
  width: 17px;
  fill: none;
  color: #502688;
}

.DashFilterSec .rs-picker .rs-input-group .rs-input-group-addon .rs-btn-close svg {
  fill: #8b8b8b;
  stroke: none !important;
}

.rs-picker-toggle-wrapper {
  max-width: 250px;
  width: 100%;
}

.DashFilterSec .rs-picker-toggle-wrapper {
  max-width: 100%;
  width: 100%;
}

.AddDevice .rs-picker-toggle-wrapper {
  max-width: 100%;
  width: 100%;
}

.rs-stack {
  width: 100%;
}

.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 1301;
}

.rs-picker-popup .rs-stack-item .rs-calendar-table-row .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content,
.rs-picker-menu .rs-stack-item .rs-calendar-table-row .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #502688 !important;
  color: #502688;
}

.rs-picker-popup .rs-stack-item .rs-calendar-table-row .rs-calendar-table-cell-content:hover,
.rs-picker-menu .rs-stack-item .rs-calendar-table-row .rs-calendar-table-cell-content:hover {
  background-color: #f8f3fe;
  color: #502688;
}

.rs-picker-popup .rs-stack-item .rs-calendar-table-row .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-picker-menu .rs-stack-item .rs-calendar-table-row .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #502688;
  color: #fff;
}

.rs-picker-popup .rs-stack-item .rs-calendar-table-row .rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-picker-menu .rs-stack-item .rs-calendar-table-row .rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
  color: #fff;
}

.rs-picker-popup .rs-stack-item .rs-calendar-table-row .rs-calendar-table-cell-in-range:before,
.rs-picker-menu .rs-stack-item .rs-calendar-table-row .rs-calendar-table-cell-in-range:before {
  background-color: #f8f3fe;
}

.rs-btn-link,
.rs-btn-link:hover {
  color: #502688;
}

.rs-btn-primary,
.rs-btn-primary:hover {
  background-color: #502688 !important;
}

.CusSelectFilter .MuiFormControl-root .MuiOutlinedInput-root #demo-simple-select {
  padding: 10.5px 30px 10.5px 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
}

.CusSelectFilter .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #e1e1e1;
}

.Filter .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #505050 !important;
}

.FormGroup.PinTypeSec .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #e1e1e1 !important;
}

.CusSelectFilter .MuiFormControl-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.CusSelectFilter .MuiFormControl-root .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #502688;
}

.CusSelectFilter .MuiFormControl-root .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.26) !important;
}

.CusSelectFilter .MuiFormControl-root label.Mui-focused {
  color: #502688;
}

.tox.tox-tinymce--disabled .tox-editor-header,
.tox.tox-tinymce--disabled .tox-toolbar-overlord,
.tox.tox-tinymce--disabled .tox-toolbar__primary,
.tox.tox-tinymce--disabled .tox-edit-area {
  background-color: #f2f2f2 !important;
}

.tox.tox-tinymce--disabled .tox-edit-area__iframe {
  background-color: #f2f2f2 !important;
  opacity: 0.5;
}

.tox.tox-tinymce--disabled .tox-edit-area__iframe p {
  color: #f40;
}

.tox .tox-toolbar__group {
  padding: 0 10px 0 10px !important;
}

/* .tox.tox-tinymce .tox-toolbar-overlord .tox-toolbar:nth-child(2) {
    background-size: unset;
    background-image: repeating-linear-gradient(#e3e3e3 0 1px, transparent 1px 50px);
} */

.tox.tox-tinymce .tox-toolbar-overlord .tox-toolbar {
  background-image: none;
}

.tox.tox-tinymce .tox-toolbar-overlord .tox-toolbar:nth-child(1) {
  border-bottom: 1px solid #e3e3e3;
}

.tox.tox-tinymce .tox-toolbar-overlord .tox-toolbar:nth-child(2) .tox-toolbar__group .tox-tbtn--select,
.tox.tox-tinymce .tox-toolbar-overlord .tox-toolbar:nth-child(3) .tox-toolbar__group .tox-tbtn--select {
  background-color: #f48272;
  margin: 8px 6px 4px 0;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}

.tox.tox-tinymce .tox-toolbar-overlord .tox-toolbar:nth-child(2) .tox-toolbar__group .tox-tbtn--select span,
.tox.tox-tinymce .tox-toolbar-overlord .tox-toolbar:nth-child(3) .tox-toolbar__group .tox-tbtn--select span {
  cursor: pointer;
}

.FormGroup .MuiFormControl-root .MuiOutlinedInput-root {
  border-radius: 8px;
  padding: 0;
}

.FormGroup.PinTypeSec .MuiFormControl-root .MuiOutlinedInput-root svg {
  color: rgb(255 255 255 / 90%);
}

.FormGroup.CusRental .MuiFormControl-root .MuiOutlinedInput-root {
  border-radius: 0px;
  padding: 0;
  width: 100%;
}

.FormGroup.CusRental .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline legend {
  margin-left: 6px;
  font-size: 0.8em;
}

.FormGroup.NoBorderRadius .MuiFormControl-root .MuiOutlinedInput-root {
  border-radius: 0px;
}

.FormGroup .MuiFormControl-root .MuiOutlinedInput-root #demo-simple-select,
.FormGroup .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
  padding: 13.5px 14px;
  padding-right: 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background: #fff;
  border-radius: 8px;
}

.FormGroup.Small .MuiFormControl-root .MuiOutlinedInput-root #demo-simple-select,
.FormGroup.Small .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
  padding: 10.5px 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background: #fff;
  border-radius: 8px;
}

.FormGroup.PinTypeSec .MuiFormControl-root .MuiOutlinedInput-root #demo-simple-select,
.FormGroup.PinTypeSec .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
  padding: 13.5px 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #f2f2f2;
  text-align: left;
  background: transparent;
  border-radius: 8px;
}

.FormGroup.CusRental .MuiFormControl-root .MuiOutlinedInput-root #demo-simple-select,
.FormGroup.CusRental .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
  padding: 17px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background: #fff;
  border-radius: 0px;
}

.FormGroup.AddDevice .MuiFormControl-root .MuiOutlinedInput-root #demo-simple-select,
.FormGroup.AddDevice .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
  padding: 10.5px 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background: #fff;
  border-radius: 8px;
}

.FormGroup.SelectRoom .MuiFormControl-root .MuiOutlinedInput-root #demo-simple-select,
.FormGroup.SelectRoom .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
  padding: 2px 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background: #fff;
  border-radius: 8px;
}

.FormGroup.SelectRoom.Two .MuiFormControl-root .MuiOutlinedInput-root #demo-simple-select,
.FormGroup.SelectRoom.Two .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
  width: auto;
  font-family: "Inter", sans-serif !important;
}

.FormGroup.SelectRoom.Two .MuiFormControl-root .MuiOutlinedInput-root svg.MuiSvgIcon-root {
  position: relative;
  left: 4px;
}

.FormGroup.SelectRoom.Active .MuiFormControl-root .MuiOutlinedInput-root #demo-simple-select,
.FormGroup.SelectRoom.Active .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
  background: #502688;
  color: #fff;
}

.FormGroup.SelectRoom.Active svg {
  color: #fff;
}

.FormGroup.SelectRoom .MuiOutlinedInput-notchedOutline,
.FormGroup.SelectRoom .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none;
  top: 0;
}

.FormGroup.SelectRoom .MuiOutlinedInput-notchedOutline legend {
  display: none;
}

.FormGroup.CusRental .MuiOutlinedInput-notchedOutline legend {
  opacity: 0;
  width: 0px;
}

.FormGroup.SelectRoom .MuiFormLabel-root {
  top: -12px;
}

.FormGroup.SelectRoom .MuiFormControl-root label.MuiInputLabel-shrink.MuiFormLabel-root {
  font-size: 14px !important;
  transform: translate(14px, 16px) scale(1);
}

.FormGroup.CusRental .MuiFormControl-root label.MuiInputLabel-shrink.MuiFormLabel-root {
  font-size: 15px !important;
  transform: translate(14px, 16px) scale(1);
}

.FormGroup.SelectRoom .MuiFormControl-root label.MuiInputLabel-shrink.MuiFormLabel-filled.MuiFormLabel-root {
  display: none;
}

.FormGroup.CusRental .MuiFormControl-root label.MuiInputLabel-shrink.MuiFormLabel-filled.MuiFormLabel-root {
  display: none;
}

.FormGroup.Contact .MuiFormControl-root .MuiOutlinedInput-root #demo-simple-select,
.FormGroup.Contact .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
  padding: 14.5px 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background: #fff;
  border-radius: 8px;
  width: 100%;
}

.FormGroup.NoBorderRadius .MuiFormControl-root .MuiOutlinedInput-root #demo-simple-select,
.FormGroup.NoBorderRadius .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
  padding: 17.5px 14px;
}

.FormGroup .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input img {
  display: none;
}

.FormGroup .MuiFormControl-root .MuiOutlinedInput-root .MuiButtonBase-root {
  padding: 5px;
  margin: 0;
  position: unset;
}

.FormGroup.PinTypeSec .MuiFormControl-root .MuiOutlinedInput-root .MuiButtonBase-root {
  padding: 5px;
  margin: 1px 0 0 0;
  position: unset;
}

.FormGroup.CusRental .MuiFormControl-root .MuiOutlinedInput-root .MuiButtonBase-root {
  padding: 10px;
  margin: 0 5px 0 0;
  position: unset;
  left: unset;
  right: unset;
  background: rgb(42 91 238 / 10%);
  border-radius: 0;
}

.FormGroup .MuiFormControl-root .MuiOutlinedInput-root .MuiButtonBase-root svg {
  width: 24px;
  height: 24px;
  stroke-width: 1;
  color: #838383;
}

.FormGroup.PinTypeSec .MuiFormControl-root .MuiOutlinedInput-root .MuiButtonBase-root svg {
  width: 24px;
  height: 24px;
  stroke-width: 1;
  color: #fff;
}

.FormGroup.CusRental .MuiFormControl-root .MuiOutlinedInput-root .MuiButtonBase-root svg {
  color: #2a5bee;
}

.CusSelectFilter.AddTour .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
  padding: 13.5px 14px !important;
}

.CusSelectFilter.AddTour .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input .MuiChip-root {
  margin: 3px !important;
  height: 27px !important;
}

.CusSelectFilter.AddTour .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input .MuiBox-root {
  display: flex !important;
  flex-wrap: wrap !important;
  margin: -5px -3px !important;
}

.CusSelectFilter.AddTour .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input .MuiBox-root.DropListNew {
  max-height: 110px;
  overflow: auto;
}

.FormGroup .MuiFormControl-root label.Mui-focused {
  color: #502688;
}

.FormGroup .MuiFormControl-root label.MuiInputLabel-shrink {
  color: rgb(0 0 0 / 60%);
}

.FormGroup.PinTypeSec .MuiFormControl-root label.MuiInputLabel-shrink {
  color: rgb(255 255 255 / 80%);
}

.FormGroup .MuiFormControl-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.FormGroup .MuiFormControl-root .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #502688;
}

.FormGroup .MuiFormControl-root .MuiOutlinedInput-root.Mui-disabled {
  padding-right: 0;
  cursor: not-allowed;
}

.FormGroup .MuiFormControl-root .MuiOutlinedInput-root.Mui-disabled .MuiInputAdornment-root {
  position: absolute;
  right: 16px;
}

.FormGroup .MuiFormControl-root .MuiOutlinedInput-root.Mui-disabled.Mui-focused .MuiOutlinedInput-notchedOutline,
.FormGroup .MuiFormControl-root .MuiOutlinedInput-root.Mui-disabled:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.26);
}

.FormGroup .MuiFormControl-root .MuiOutlinedInput-root.Mui-disabled #demo-simple-select,
.FormGroup .MuiFormControl-root .MuiOutlinedInput-root.Mui-disabled .MuiInputBase-input {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  cursor: not-allowed;
  text-align: left;
}

.FormGroup .MuiFormLabel-root.DisabledLabel {
  color: rgb(0 0 0 / 40%);
  cursor: not-allowed;
}

.FormGroup .MuiFormControl-root label.MuiInputLabel-shrink.DisabledLabel {
  color: rgb(0 0 0 / 60%);
  background: #fff;
  padding: 0 9px;
  transform: translate(9px, -9px) scale(0.75);
}

.MuiSnackbar-root {
  bottom: 42px !important;
  right: 24px !important;
  z-index: 99999 !important;
}

.MuiSnackbar-root .MuiPaper-root {
  background: #502688;
  max-width: 300px;
}

.MuiSnackbar-root .MuiPaper-root .MuiSnackbarContent-message {
  padding: 16px 30px 12px 14px;
  /* padding: 12px 5px 12px 5px; */
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.MuiSnackbar-root .MuiPaper-root .MuiSnackbarContent-action {
  position: absolute;
  top: 0px;
  right: 8px;
  color: #ffffff;
  padding: 0;
  background: #5c3993;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.MuiSnackbar-root .MuiPaper-root .MuiSnackbarContent-action svg {
  width: 18px;
  height: 18px;
}

.AddTourModal .MuiPickersLayout-root .MuiPickersToolbar-root,
.AddTourModal .MuiPickersLayout-root .MuiDialogActions-root {
  display: none;
}

.AddTourModal .MuiPickersLayout-root {
  min-width: 300px;
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root {
  width: 300px;
  max-height: 400px;
  height: 380px;
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersCalendarHeader-root {
  margin-bottom: 12px;
  padding-left: 0px;
  padding-right: 0;
  margin-top: 0;
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-labelContainer .MuiPickersCalendarHeader-label {
  font-size: 14px;
  line-height: 21px;
  color: #343434;
  font-weight: 400;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersCalendarHeader-root .MuiPickersArrowSwitcher-root button {
  color: #502688;
}

.AddTourModal.Reschedule .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersCalendarHeader-root .MuiPickersArrowSwitcher-root button {
  color: #2a5bee;
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiDayCalendar-header span {
  margin: 0px 4px;
  font-size: 14px;
  font-weight: 500;
  color: #343434;
  justify-content: center;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root button.MuiButtonBase-root {
  border-radius: 6px;
  margin: 4px 4px;
  font-size: 14px;
  line-height: 21px;
  color: #666;
  font-weight: 400;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root button.MuiButtonBase-root.MuiPickersDay-today {
  border: 1px solid #502688 !important;
}

.AddTourModal.Reschedule .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root button.MuiButtonBase-root.MuiPickersDay-today {
  border: 1px solid #2a5bee !important;
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root button.MuiButtonBase-root {
  background-color: #fbf7fc;
  color: #502688;
}

.AddTourModal.Reschedule .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root button.MuiButtonBase-root {
  background-color: #f0f4ff;
  color: #2a5bee;
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root button.MuiButtonBase-root.Mui-disabled {
  background-color: #f8f8f8;
  color: #d5d5d5;
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root button.MuiButtonBase-root:hover {
  background-color: rgb(109 77 159 / 10%);
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root button.MuiButtonBase-root.Mui-selected {
  background-color: #502688;
  color: #fff;
}

.AddTourModal.Reschedule .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root button.MuiButtonBase-root.Mui-selected {
  background-color: #2a5bee;
  color: #fff;
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root .MuiPickersDay-root {
  border-radius: 6px;
  margin: 4px 4px;
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root .MuiDayCalendar-weekContainer {
  justify-content: flex-start;
}

.AddTourModal .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersSlideTransition-root {
  min-height: 280px;
}

/******************* Full calendar **********************/
.FullCalendar {
  width: 100%;
  position: relative;
}

.FullCalendar .fc-header-toolbar {
  justify-content: flex-start !important;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  color: #505050;
  margin: 0;
  font-family: "Inter", sans-serif !important;
  min-width: 100px;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk button.fc-today-button {
  display: none;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button {
  background-color: #fff;
  border-color: #fff;
  color: #502688;
  font-size: 15px;
  padding: 6px;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button:focus {
  outline: none;
  box-shadow: none;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
  margin-left: auto;
  display: none;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group {
  border: 1px solid #502688;
  border-radius: 6px;
  overflow: hidden;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group button {
  background-color: #fff;
  border-color: #fff;
  color: #502688;
  font-size: 14px;
  padding: 8px 16px;
  border-right-color: #502688;
  margin: 0;
  text-transform: capitalize;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group button:last-child {
  border-right-color: #fff;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group button.fc-button-active {
  background-color: #502688;
  border-color: #502688;
  color: #fff;
  border-right-color: #502688;
}

.FullCalendar .fc-theme-standard .fc-scrollgrid-section>td {
  border: none !important;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid {
  border: 1px solid #e3e6e9 !important;
  border-radius: 16px;
  overflow: hidden;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody>tr>td.fc-timegrid-slot-label,
.FullCalendar .fc-view-harness table.fc-scrollgrid tbody>tr>td .fc-timegrid-slot-label-frame {
  background: #fbf7fc;
  text-align: center;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody>tr>td .fc-timegrid-slot-label-frame>div {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #505050;
  font-family: "Inter", sans-serif !important;
  text-align: center;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td,
.FullCalendar .fc-view-harness table.fc-scrollgrid table.fc-col-header th {
  border: 1px solid #e3e6e9;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td .fc-timegrid-axis-frame {
  background: #fbf7fc;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td .fc-timegrid-axis-frame span {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #505050;
  font-family: "Inter", sans-serif !important;
  text-align: center;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid table.fc-col-header th {
  padding: 10px;
  background-color: #fbf7fc;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid table.fc-col-header th a {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #505050;
  font-family: "Inter", sans-serif !important;
  text-align: center;
}

.FullCalendar .fc .fc-daygrid-day-top {
  justify-content: center;
  padding: 16px 12px 12px 12px;
}

.FullCalendar .fc .fc-daygrid-day-number {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #505050;
  font-family: "Inter", sans-serif !important;
  /* padding: 16px 12px 12px 12px; */
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-past.fc-day-other .fc-daygrid-day-frame:before,
.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-future.fc-day-other .fc-daygrid-day-frame:before {
  background-image: url("./range-controller/gradientIMG.svg");
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-today {
  background-color: #fff;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-today,
.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-future {
  cursor: pointer;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-today .fc-daygrid-day-top {
  padding: 14px 12px 12px 12px;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-today .fc-daygrid-day-number {
  width: 28px;
  height: 28px;
  background-color: #ff8271;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  font-family: "Inter", sans-serif !important;
}

.FullCalendar .fc-timegrid-col-events {
  margin: 0 !important;
}

.FullCalendar .fc-event {
  padding: 4px 8px;
  margin: 0 !important;
  border: none;
  transition: all 0.2s ease-in-out;
}

.FullCalendar .fc-event.fc-tour-custom {
  background-color: #dfe2e6;
}

.FullCalendar .fc-event.fc-tour-custom>div {
  color: #595959;
}

.FullCalendar .fc-event.fc-tour-custom:hover {
  background-color: #cbced2;
}

.FullCalendar .fc-event.fc-openhouse-custom {
  background-color: #efd763;
}

.FullCalendar .fc-event.fc-openhouse-custom:hover {
  background-color: #d2bd54;
}

.FullCalendar .fc-event.fc-tour-confirmed {
  background-color: #5ac55e;
}

.FullCalendar .fc-event.fc-tour-confirmed:hover {
  background-color: #4eaf52;
}

.FullCalendar .fc-event.fc-tour-completed {
  background-color: #502688;
}

.FullCalendar .fc-event.fc-tour-completed:hover {
  background-color: #361a5d;
}

.FullCalendar .fc-event.fc-tour-custom.fc-openhouse-custom>div,
.FullCalendar .fc-event.fc-tour-custom.fc-tour-confirmed>div,
.FullCalendar .fc-event.fc-tour-custom.fc-tour-completed>div {
  color: #fff;
}

.FullCalendar .fc-event .fc-daygrid-event-dot {
  display: none;
}

.FullCalendar .fc-event .fc-event-time,
.FullCalendar .fc-event .fc-event-title {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  font-family: "Inter", sans-serif !important;
  flex-grow: unset;
}

.FullCalendar .fc-event .fc-event-title {
  width: auto;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  margin-right: 4px;
}

.FullCalendar .fc-event.fc-openhouse-custom .fc-event-time,
.FullCalendar .fc-event.fc-openhouse-custom .fc-event-title {
  color: #343434;
  font-weight: 600;
}

.FullCalendar .fc-more-link {
  color: #ff8271;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  border: 1px solid;
  background: #ffe8e5;
  border-radius: 10px;
  padding: 3px 4px;
  margin: 5px 0 0 5px;
}

.FullCalendar .fc .fc-popover {
  box-shadow: #3e557826 0px 4px 20px 0;
  position: absolute;
  z-index: 99;
  width: 14.3%;
  border-radius: 8px;
  border: none !important;
}

.FullCalendar .fc .fc-more-popover .fc-popover-body {
  min-width: 130px;
  padding: 0px 0px 5px 0px;
}

.FullCalendar .fc .fc-popover-header {
  background: transparent;
}

.FullCalendar .fc .fc-popover-title {
  margin: 4px 2px 0px 3px;
  font-size: 12px;
  font-weight: 500;
  color: #505050;
}

/******************* Full calendar **********************/

.SearchSecUnder .rbt {
  width: 100%;
}

.SearchSecUnder .rbt .rbt-input-main.form-control {
  border: none;
  width: 100%;
}

.SearchSecUnder .rbt .rbt-input-main.form-control:focus,
.SearchSecUnder .rbt .rbt-input-main.form-control:active,
.SearchSecUnder .rbt .rbt-input-main.form-control:hover {
  outline: none;
  box-shadow: none;
}

.SearchSecUnder .dropdown-menu .dropdown-item {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 6px 10px !important;
  min-width: 80px;
  display: flex;
  align-items: center;
}

.SearchSecUnder .dropdown-menu .dropdown-item:hover,
.SearchSecUnder .dropdown-menu .dropdown-item:focus,
.SearchSecUnder .dropdown-menu .dropdown-item:active {
  color: #343434 !important;
  background: #f8f8f8 !important;
}

.TimeLI {
  list-style: none;
  width: 100%;
  border: 1px solid #502688;
  border-radius: 8px;
  padding: 10px 16px;
  margin: 0 0 14px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.TimeLI:hover {
  background-color: rgb(109 77 159 / 10%);
}

.Reschedule .TimeLI {
  border: 1px solid #2a5bee;
}

.Reschedule .TimeLI:hover {
  background-color: #f0f4ff;
}

.AddTourTime {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #502688;
  margin: 0;
}

.Reschedule .TimeLI .AddTourTime {
  color: #2a5bee;
}

.TimeSlotTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #343434;
  margin: 0 0 15px 0;
}

.AddTourPopTT {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #343434;
  margin: 20px 0 0 0;
}

.TimeSelected {
  background-color: #502688 !important;
}

.Reschedule .TimeLI.TimeSelected {
  background-color: #2a5bee !important;
}

.TimeSlotBooked {
  /* border: 1px solid #efefef;
  background-color: #efefef;
  cursor: context-menu; */
  background-color: #502688;
}

.TimeSelected .AddTourTime {
  color: #fff;
}

.Reschedule .TimeLI.TimeSelected .AddTourTime {
  color: #fff;
}

.TimeSlotBooked .AddTourTime {
  color: #666666;
}

.AddTourOpenHouse {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666;
}

.AddTourOpenHouse svg {
  width: 18px;
  color: #efd763;
  fill: #efd763;
  margin-right: 5px;
}

.TimeSelected .AddTourOpenHouse {
  color: #efefef;
}

.AddTourScheduled {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666666;
}

.AddTourScheduled svg {
  width: 18px;
  color: #666666;
  margin-right: 6px;
}

.TimeSelected .AddTourScheduled svg {
  color: #efefef;
}

.AddTourScheduled .ScheduledTag {
  background-color: rgba(161, 105, 198, 0.3);
  color: #502688 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 10px !important;
  border-radius: 15px;
  margin: 0 -10px 0 0;
  height: 25px;
  line-height: normal;
}

.TimeSelected .AddTourScheduled .ScheduledTag {
  background-color: rgb(255 255 255);
  color: #502688 !important;
}

.CustomCheck .MuiTypography-root {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.CustomCheck {
  margin-right: 5px !important;
}

.RequestCarouselSec .carousel-root .carousel-slider {
  overflow: unset;
}

.RequestCarouselSec .carousel-root .carousel-slider .control-dots {
  bottom: -35px;
}

.RequestCarouselSec .carousel-root .carousel-slider .slider-wrapper ul li img {
  width: 100%;
  border-radius: 4px;
  max-height: 200px;
  height: 200px;
  min-height: 180px;
  object-fit: cover;
  object-position: center;
}

.SettingsTabSec .MuiPaper-root {
  border: none;
  box-shadow: none;
  border-top: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
  margin: 0;
}

.SettingsTabSec .MuiPaper-root::before {
  display: none;
}

.SettingsTabSec .MuiPaper-root:first-child {
  border-top: none;
}

.SettingsTabSec .MuiPaper-root .MuiButtonBase-root {
  padding: 0 0px;
}

.SettingsTabSec .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content {
  margin: 10px 0;
}

@media screen and (min-width: 360px) {
  .RequestCarouselSec .carousel-root .carousel-slider .slider-wrapper ul li img {
    max-height: 230px;
    height: 230px;
  }

  .CustomMenuWithBU .e-toolbar.e-control[class*="e-toolbar"] {
    width: 55% !important;
  }

  .CustomMenuWithBU.FullSearch .e-toolbar.e-control[class*="e-toolbar"] {
    width: 100% !important;
  }

  .ReferExpMain .MuiExpansionPanelSummary-root,
  .ReferExpMain .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 110px;
  }

  .ReferExpMain .MuiExpansionPanelSummary-root.Mui-expanded .MuiExpansionPanelSummary-content.Mui-expanded::after {
    bottom: -23px;
  }

  .ReferExpMain .MuiExpansionPanelSummary-root,
  .ReferExpMain .MuiExpansionPanelSummary-root.Mui-expanded {
    padding: 0 15px 0 15px;
  }

  /* .MuiTab-root {
        min-width: auto !important;
    } */
  .toasts-holder {
    max-height: calc(112px - 0px) !important;
    max-width: 97%;
  }

  .FormGroup .MuiFormControl-root,
  .FormGroup .MuiTextField-root {
    width: 100%;
    margin: 0 !important;
  }

  .FormGroup input,
  .FormGroup textarea {
    width: 100%;
  }

  .RentalAppliCaro .carousel .thumbs-wrapper ul li {
    width: 20% !important;
    height: 40px;
  }

  .RentalAppliCaro .carousel .slide img {
    height: 220px;
  }
}

@media screen and (min-width: 480px) {
  .CustomMenuWithBU .e-toolbar.e-control[class*="e-toolbar"] {
    width: 65% !important;
  }
}

@media screen and (min-width: 576px) {
  .RequestCarouselSec .carousel-root .carousel-slider .slider-wrapper ul li img {
    max-height: 250px;
    height: 250px;
  }

  .CustomMenuWithBU .e-toolbar.e-control[class*="e-toolbar"] {
    width: 72% !important;
  }

  .MuiTab-root {
    min-width: 60px !important;
  }

  .RentalAppliCaro .carousel .thumbs-wrapper ul li {
    width: 20% !important;
    height: 60px;
  }

  .RentalAppliCaro .carousel .slide img {
    height: 300px;
  }
}

@media screen and (min-width: 720px) {
  .CustomMenuWithBU .e-toolbar.e-control[class*="e-toolbar"] {
    width: 74% !important;
  }

  .ReferExpMain .MuiExpansionPanelSummary-root,
  .ReferExpMain .MuiExpansionPanelSummary-root.Mui-expanded {
    padding: 0 15px 0 25px;
  }
}

@media screen and (min-width: 768px) {
  .RequestCarouselSec .carousel-root .carousel-slider .slider-wrapper ul li img {
    max-height: 300px;
    height: 300px;
  }

  .DeleteBU {
    min-width: 60px;
  }

  /* .FormGroup .MuiFormControl-root,
    .FormGroup .MuiTextField-root {
        width: 95%;
    } */

  .FormGroup input,
  .FormGroup textarea {
    width: 95%;
  }

  .RentalAppliCaro .carousel .thumbs-wrapper ul li {
    width: 20% !important;
    height: 60px;
  }

  .RentalAppliCaro .carousel .slide img {
    height: 350px;
  }

  .SignaturePopupBody canvas {
    max-width: 550px;
  }
}

@media screen and (min-width: 960px) {
  .AppContent {
    padding-left: 85px;
  }

  .Dashboard__AppContent-sc-1ndgjm1-2 {
    padding-left: 85px;
  }

  .toasts-holder {
    max-height: calc(65px - 0px) !important;
    max-width: 80%;
  }

  .NewPopUp.CustomWidthPops .MuiPaper-root {
    max-width: 750px;
    padding: 24px 20px;
  }

  .NewPopUp.CustomWidthPops .MuiDialogContent-root {
    padding-left: 12px;
    padding-right: 12px;
  }

  .NewPopUp.CustomWidthPops .MuiTypography-root {
    padding-left: 12px;
    padding-right: 12px;
  }

  .NewPopUp.CustomWidthLeasePops .MuiPaper-root {
    max-width: 750px;
  }
}

@media screen and (min-width: 1100px) {
  .RequestCarouselSec .carousel-root .carousel-slider .slider-wrapper ul li img {
    max-height: 200px;
    height: 200px;
  }

  .LineChart .MuiCardContent-root>div {
    height: 339px !important;
  }

  .RentalAppliCaro .carousel .thumbs-wrapper ul li {
    width: 130px !important;
    height: 80px;
  }

  .RentalAppliCaro .carousel .slide img {
    height: 430px;
  }

  .ChatSideBar {
    flex-grow: 0;
    max-width: 340px;
    flex-basis: 340px;
  }

  .ChatMessageSec {
    flex-grow: 0;
    max-width: CalC(100% - 340px);
    flex-basis: CalC(100% - 340px);
  }

  .DimmerWidget:after {
    left: 84px;
  }

  .DimmerWidget:before {
    left: 84px;
  }
}

@media screen and (min-width: 1200px) {

  /* .FullCalendar .fc-view-harness table.fc-scrollgrid thead td,
  .FullCalendar .fc-view-harness table.fc-scrollgrid tbody>tr>td {
    border: none;
  } */
  .FullCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
    display: unset;
  }

  .FullCalendar .fc-event {
    margin: 6px 6px 0 6px !important;
  }

  .FullCalendar .fc-timegrid .fc-event {
    margin: 0px 0px 0 0px !important;
  }

  .ReferExpMain .MuiExpansionPanelSummary-root,
  .ReferExpMain .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 90px;
  }

  .ReferExpMain .MuiExpansionPanelSummary-root.Mui-expanded .MuiExpansionPanelSummary-content.Mui-expanded::after {
    bottom: -35px;
  }

  .CustomMenuWithBU .e-toolbar.e-control[class*="e-toolbar"] {
    width: calc(100% - 170px) !important;
  }

  .CustomMenuWithBU.User .e-toolbar.e-control[class*="e-toolbar"] {
    width: calc(100% - 700px) !important;
  }

  .CustomMenuWithBU.AddDevice .e-toolbar.e-control[class*="e-toolbar"] {
    width: calc(100% - 250px) !important;
    margin-bottom: 10px;
  }

  /* .ChatSideBar.Private {
    flex-grow: 0;
    max-width: 280px;
    flex-basis: 280px;
  } */

  .ChatMessageSec.Private {
    flex-grow: 0;
    max-width: CalC(100% - 0px);
    flex-basis: CalC(100% - 0px);
  }

  .DimmerWidget:after {
    left: 85px;
  }

  .DimmerWidget:before {
    left: 85px;
  }
}

@media screen and (min-width: 1280px) {
  /* .CustomMenuWithBU .e-toolbar.e-control[class*="e-toolbar"] {
        width: 76% !important;
    } */

  .DoughnutChart .MuiCardContent-root>div {
    height: 270px !important;
  }

  .ChartTitle span {
    font-size: 18px;
  }

  .ChatSideBar {
    flex-grow: 0;
    max-width: 380px;
    flex-basis: 380px;
  }

  .ChatMessageSec {
    flex-grow: 0;
    max-width: CalC(100% - 380px);
    flex-basis: CalC(100% - 380px);
  }

  /* .ChatSideBar.Private {
    flex-grow: 0;
    max-width: 300px;
    flex-basis: 300px;
  } */

  .ChatMessageSec.Private {
    flex-grow: 0;
    max-width: CalC(100% - 0px);
    flex-basis: CalC(100% - 0px);
  }
}

@media screen and (min-width: 1300px) {
  .NewPopUp.CustomWidthPops .MuiPaper-root {
    max-width: 1100px;
  }

  .NewPopUp.CustomWidthLeasePops .MuiPaper-root {
    max-width: 1100px;
  }
}

@media screen and (min-width: 1440px) {
  /* .CustomMenuWithBU .e-toolbar.e-control[class*="e-toolbar"] {
        width: 82% !important;
    } */

  /* .MuiTab-root {
        min-width: 160px !important;
    } */
}

@media screen and (min-width: 1600px) {
  /* .ChatSideBar.Private {
    flex-grow: 0;
    max-width: 340px;
    flex-basis: 340px;
  } */

  .ChatMessageSec.Private {
    flex-grow: 0;
    max-width: CalC(100% - 0px);
    flex-basis: CalC(100% - 0px);
  }
}

@media screen and (min-width: 1700px) {
  /* .CustomMenuWithBU .e-toolbar.e-control[class*="e-toolbar"] {
        width: 85% !important;
    } */
}

@media screen and (min-width: 1800px) {
  /* .CustomMenuWithBU .e-toolbar.e-control[class*="e-toolbar"] {
        width: 85% !important;
    } */
}

.rs-picker-menu {
  z-index: 1300;
}

@media screen and (max-width: 959px) {
  .TopHeader,
  .FooterMain

  /* #root div.CommunicationPopupSec  */
    {
    display: none !important;
  }

  .NewPopUp.CustomWidthPops .MuiPaper-root {
    padding: 24px 10px;
  }

  .NewPopUp.CustomWidthPops .MuiDialogContent-root {
    padding-left: 12px;
    padding-right: 12px;
  }

  .NewPopUp.CustomWidthPops .MuiTypography-root {
    padding-left: 12px;
    padding-right: 12px;
  }

  #root>div.CommunicationPopupSec {
    max-width: 100% !important;
    z-index: -1;
  }

  #root>div.CommunicationPopupSec.Open {
    z-index: 998;
  }
}

@media screen and (max-width: 767px) {
  .AppContent.Details header.TopHeader {
    display: none;
  }

  .CustomWidthPopUs.MobileForm,
  .MobileForm {
    z-index: 999;
  }

  .CustomWidthPopUs.MobileForm .MuiPaper-root,
  .MobileForm .MuiPaper-root {
    max-width: 100%;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
    max-height: 100%;
  }

  .NewPopUp.MobileForm .MuiTypography-root button {
    display: none;
  }

  .NewPopUp.MobileForm .MuiTypography-root {
    align-items: center;
    justify-content: flex-start;
  }

  .NewPopUp.MobileForm .MuiDialogContent-root::-webkit-scrollbar {
    display: none;
  }

  .NewPopUp.MobileForm .MuiDialogContent-root {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .MuiDialog-root.MuiModal-root {
    z-index: 999;
  }

  .SignaturePopupBody canvas {
    max-width: 450px;
  }
}

@media screen and (max-width: 575px) {

  .CustomWidthPopUs.MobileForm .MuiPaper-root,
  .MobileForm .MuiPaper-root {
    padding: 20px 20px;
    width: 100% !important;
  }

  .NewPopUp .MuiPaper-root {
    padding: 15px 15px;
    width: calc(100% - 35px);
    margin: 0px;
  }

  .FullCalendar .fc-view-harness {
    height: 600px !important;
  }
}

@media screen and (max-width: 479px) {
  .NewPopUp.MobileForm .CancelPopupBU {
    width: 48%;
    padding: 12px 16px !important;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .NewPopUp.MobileForm .SubmitPopupBU {
    width: 48%;
    margin: 0;
    padding: 12px 16px !important;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .SignaturePopupBody canvas {
    max-width: 300px;
  }

  .PinArea>div span {
    padding: 0 2px;
  }
}