.pageTitleWrap {
  width: 100%;
  padding: 1.8rem 1.8rem 1.2rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.pageTitle svg {
  width: 22px;
  height: auto;
  margin-left: 4px;
}

.DropdownPageTitleMenu {
  padding: 0 !important;
}

.DropdownPageTitleMenu a {
  color: #343434;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 15px;
  font-family: "Inter", sans-serif !important;
  width: 120px;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem+.breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem+.breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 0px;
  border-radius: 0.5rem;
  margin: 0px 1.8rem 0 1.8rem;
  position: relative;
  /* background: white; */
  border: none;
  /* box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1); */
  margin-bottom: 20px;
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: 110px;
  float: right;
  display: flex;
  justify-content: flex-end;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #230058;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #56328c;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.ReferStatusBU {
  padding: 0px 0px 1px 0px !important;
  background: #e2e2e2 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  /* color: #9f9f9f; */
  transition: all 0.3s !important;
  margin: 0 !important;
  padding-top: 1px !important;
  line-height: unset !important;
  height: 22px !important;
}

.ReferStatusBU:after {
  display: none !important;
}

.ReferStatusBU:focus {
  border: none;
  box-shadow: none !important;
}

.ReferStatusBU svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
  margin-bottom: 4px;
  margin-left: 8px;
  margin-right: 5px;
}

.ReferStatusBU svg.DownArrowStatus {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.ReferStatusBU.StatusBuRed,
.ReferStatusBU.StatusBuRed:active,
.ReferStatusBU.StatusBuRed:checked {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
  color: #fff !important;
}

.ReferStatusBU.StatusBuYellow,
.ReferStatusBU.StatusBuYellow:active,
.ReferStatusBU.StatusBuYellow:checked {
  background-color: #ffbb33 !important;
  border-color: #ffbb33 !important;
  color: #000 !important;
}

.ApplicantFilterSec {
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  align-items: center;
}

.ApplicantFilterSec p {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: table;
  width: 100%;
}

.ClearFilter {
  font-size: 13px;
  font-weight: 500;
  color: #ff8271;
  width: 55px;
  margin-left: 0;
  display: table-cell;
  vertical-align: middle;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ApplicantFilterSec .formControl {
  margin-top: 10px;
}

.NotesRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.NotesCard {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 10px 0;
  /*overflow: hidden;*/
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.06);
  transition: all 0.3s ease-in-out;
}

.NotesCard:hover {
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.15);
}

.InsideConName {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  width: 20%;
  margin-bottom: 0;
  padding: 8px 15px;
  display: flex;
  align-items: center;
}

.InsideConName span {
  color: #b7b7b7;
  font-weight: 500;
}

span.ConNameTrancate {
  color: #32007e;
  padding-right: 8px;
  font-weight: 600;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  /* margin-bottom: -4px; */
}

.InsideConText {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
  width: 25%;
  padding: 8px 15px;
}

.InsideConUnit {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  /*width: 25%;*/
  padding: 8px 15px;
  text-align: center;
}

.InsideConCheck {
  /*width: 25%;*/
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px 0;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  margin-top: 15px;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 15px;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: -webkit-flex;
  display: flex;
  min-height: 0;
  color: #fff !important;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  outline: none !important;
  border: none;
  height: 42px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
  background-color: #e97565;
  color: #fff;
}

.AddNoteBu svg {
  min-width: 20px;
  height: 20px;
  margin-right: 10px;
}

.FilterBu {
  align-items: center;
  padding: 9px 14px;
  background: #502688;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 8px;
  transition: all 0.3s;
  outline: none;
  border: none;
  height: 42px;
  margin-left: 10px;
  margin-right: 10px;
}

.FilterBu svg {
  min-width: 20px;
  height: 20px;
  margin-right: 0px;
}

.FilterBu:hover,
.FilterBu:active,
.FilterBu:focus {
  background: #230058;
  background-color: #230058;
  color: #fff;
}

.PropeCardInFR {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.BodyCon {
  display: flex;
}

.FilterList {
  margin-bottom: 20px;
  font-size: 0.875rem;
}

.FilterLabel {
  font-size: 13px;
  line-height: 35px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.FilterOnOffBU {
  position: absolute;
  left: -30px;
  top: 0px;
  /* height: 30px; */
  background: #230058;
  color: #fff;
  padding: 4px 8px;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

.ApplicantDiv {
  padding-top: 0px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 25%;
}

/*.ApplicantDiv::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #f1f1f1;
    top: 25px;
  left: 0;
}*/

.ApplicantDivTitle {
  margin: 0;
  font-size: 14px;
  color: #ff8271;
  font-weight: 400;
  width: auto;
  display: inline-block;
  /* background: #fff; */
  padding: 8px 0;
  position: relative;
  /* z-index: 1; */
}

.ApplicantDiv .InsideConUnit:first-child {
  text-align: left;
}

.ApplicantDiv .InsideConUnit:last-child {
  text-align: right;
}

.ApplicantName {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.ApplicantNameDe {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
}

.ApplicantNameTel {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
  min-width: 120px;
}

.ApplicantNameMail {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 240px;
}

.ApplicantDivHalf {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ApplicantAction {
  display: flex;
  align-items: center;
  width: 20%;
}

.mainCardDiv {
  width: 100%;
  padding-right: 280px;
  padding-bottom: 50px;
}

.BodyFilterArea {
  min-width: 280px;
  max-width: 280px;
  background: #fff;
  position: fixed;
  right: 0;
  height: 100vh;
  padding: 90px 30px 120px 30px;
  overflow: auto;
  top: 60px;
  box-shadow: 0 0px 7px 2px rgb(62 85 120 / 10%);
  transition: all ease-in-out 0.5s;
  z-index: 10;
  display: none;
}

.FilterTitle {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #340380;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.FilterTitle svg {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}

.FilterList {
  margin-bottom: 20px;
}

.FilterLabel {
  font-size: 13px;
  line-height: 35px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 220px;
  height: 42px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.FilterOnOffBU {
  position: absolute;
  left: -30px;
  top: 70px;
  /* height: 30px; */
  background: #230058;
  color: #fff;
  padding: 4px 8px;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

.DatePickerCustom {
  position: relative;
  z-index: 1;
}

/* .DatePickerCustom svg {
  position: absolute;
  top: 12px;
  color: #000;
  width: 18px;
  height: 18px;
  right: 12px;
  z-index: -1;
  opacity: 0.8;
} */

.NoDataMain {
  width: calc(100% - 24px);
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: #fff;
  position: relative;
  margin: auto;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 35px;
  margin: 0 auto 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoDataIMG img {
  width: 100%;
  opacity: .4;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 0px 0 !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.NoDataTextSub {
  text-align: center;
  font-size: 22px;
  padding: 0px 0 !important;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.AddLeaseBU {
  padding: 9px 25px 9px 21px;
  background-color: #ff8271;
  margin: 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.AddLeaseBU:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.AddLeaseBU svg {
  width: 22px;
  margin-right: 7px;
}

.FilterShowingDiv {
  background: #f1f1f1;
  width: 100%;
  margin-top: 5px;
  padding: 10px 15px;
  border-radius: 5px;
  display: none;
}

.TitleFilterPill {
  font-size: 13px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: inline-block;
}

.FilterPill {
  margin-top: 5px;
  margin-right: 5px;
  background: #230058;
  color: #fff;
  padding: 4px 6px;
  display: inline-block;
  width: max-content;
  border-radius: 3px;
  font-size: 13px;
}

.FilterPill span {
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -5px;
}

.FilterPill a {
  color: #fff;
  border-left: 1px solid #49149a;
  margin-left: 7px;
  padding-left: 3px;
  display: inline-block;
}

.FilterPill a:hover {
  color: #fff;
}

.FilterPill a svg {
  width: 17px;
  height: 17px;
  margin-bottom: 1px;
}

.BadgeNew {
  background: #f48272;
  color: #fff !important;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 3px;
  margin-left: 8px;
  text-transform: uppercase;
}

.SearchFilterSec {
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;
  padding: 5px 0 20px 0;
  justify-content: space-between;
}

.SearchCon {
  width: 50%;
  padding: 0 10px;
}

.SearchConSec {
  width: 100%;
  border: 1px solid #e1e1e1;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  background: #fff;
}

.SearchConSec input {
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  width: 100%;
  border: none;
}

.SearchConSec svg {
  width: 20px;
  opacity: 0.8;
  margin-right: 10px;
}

.FilterSec {
  width: 50%;
  display: flex;
}

.FilterCard {
  width: 33%;
  padding: 0 10px;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterBUCard {
  width: 30%;
  padding: 0 10px;
  max-width: 150px;
}

.FilterCard.Select>div>div {
  min-height: 42px;
  border-color: #e1e1e1;
  border-radius: 8px;
}

.ApplyFilterBU {
  width: 100%;
  background: #502688;
  color: #fff;
  height: 38px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.ApplyFilterBU:hover {
  background: #230058;
}

.ReffStatus {
  margin: 0 10px 0 0;
  font-size: 14px;
  white-space: nowrap;
  /* display: table-cell; */
  display: inline-block;
  vertical-align: middle;
}

.ReffStatus.Archived {
  background: #87858b;
  color: #fff;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 12px;
}

.AcrhivedLabel {
  background: #e5e5e5;
  color: #505050 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0 0 0 10px;
  display: inline-block;
}

.ProspectCard {
  width: 25%;
  padding: 15px 12px;
}

.ProspectCardUnder {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 20px 0 #1f025412;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.3s ease-in-out;
}

.ProspectCardUnder:hover {
  box-shadow: 0 0 28px 0 #1f02541f;
}

.ProspectCardHeadSection {
  display: flex;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 16px;
  margin-bottom: 8px;
  position: relative;
}

.ProspectCardHeadSection .ProsIMGSec {
  min-width: 90px;
  width: 90px;
  min-height: 90px;
  height: 90px;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  background-color: #fbf7fc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ProsIMGSec .ProspectCardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ProsIMGSec p {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
  color: #502688;
  letter-spacing: 1px;
}

.ProspectCardHeadSection .HeadConSec {
  width: calc(100% - 106px);
}

.ProsUserName {
  font-size: 17px;
  font-weight: 600;
  line-height: 150%;
  margin: 0 0 7px 0;
  color: #505050;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.ProsUserName svg {
  width: 19px;
  height: 19px;
  margin-right: 10px;
}

.ProsUserName.CallLink,
.ProsUserName.CallLink:hover {
  margin: 0;
  color: #ff8271;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}

.ProsUserName.CallLink::after {
  content: '';
  position: absolute;
  /* width: calc(100% - 30px); */
  height: 1px;
  background-color: #ff8271;
  right: 0;
  bottom: -1px;
  opacity: 0;
  transition: all .2s ease-in-out;
}

.ProsUserName.CallLink:hover::after {
  opacity: 1;
}

.ProsUserName.LimitName {
  width: calc(100% - 85px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  margin: 0 12px 0 0;
}

.ProsUserMandateSec {
  width: 100%;
  display: flex;
  padding-top: 4px;
}

.MandateCheckSec {
  width: 24px;
  height: 24px;
  background-color: #dfe2e6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.MandateCheckSec img {
  filter: brightness(1) invert(1);
  opacity: .6;
}

.MandateCheckSec.Active img,
.MandateCheckSec.Fail img {
  filter: brightness(0) invert(1);
  opacity: 1;
}

.defaultImg {
  width: 100%;
}

.MandateCheckSec:last-child {
  margin-right: 0;
}

.MandateCheckSec.Active {
  background-color: #27c27b;
}

.MandateCheckSec.Fail {
  background-color: #ff8271;
}

.MandateCheckSec svg {
  width: 14px;
  height: auto;
  color: #fff;
  stroke-width: 3px;
}

.ProspectCardBodySec {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.ProspectCardBodySec ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.ProspectCardBodySec ul li {
  list-style: none;
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.ProspectCardBodySec ul li p.Title {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  color: #666666;
  min-width: 110px;
}

.ProspectCardBodySec ul li p.Content {
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  color: #343434;
  display: flex;
  align-items: center;
}

.ProspectCardBodySec ul li p.Content.Hover {
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  color: #343434;
  position: relative;
  transition: all .3s ease-in-out;
}

.ProspectCardBodySec ul li p.Content.Hover:hover {
  color: #f48272;
}

.ProspectCardBodySec ul li p.Content.Hover::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  background: #fff;
  bottom: 0;
  left: 0%;
  /* transform: translate(-50%, 0); */
  transition: all .3s ease-in-out;
}

.ProspectCardBodySec ul li p.Content.Hover:hover::after {
  width: 100%;
  background: #f48272;
}

.ProspectCardFootSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.ProsCardDate {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  color: #666666;
}

.ProsCardMailBU {
  width: 50px;
  height: 30px;
  background: #fff;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  position: relative;
}

.ProsCardMailBU:focus {
  outline: none;
}

.ProsCardMailBU span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ef8977;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  line-height: 16px;
  position: absolute;
  top: -4px;
  right: -8px;
}

.ProsCardMailBU svg {
  width: 28px;
  height: auto;
  fill: #dfe2e6;
  stroke: #fff;
}

.ActionsBU {
  width: 30px;
  height: 25px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #502688 !important;
  background-color: #ffffff !important;
  margin: 0 !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 8px !important;
  /* position: absolute; */
  right: -8px;
  top: 0;
}

.show>.btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058;
  border: none;
}

.ActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 22px;
  height: 22px;
  margin-bottom: 0;
  margin-left: 0;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 10px 10px !important;
  min-width: 80px;
  display: flex !important;
  align-items: center;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus,
.ActionsBUListItem:active {
  color: #343434 !important;
  background: #f8f8f8 !important;
  background-color: #f8f8f8 !important;
}

.ActionsBUListItem.Delete {
  color: #f44336 !important;
}

.ActionsBUListItem svg {
  width: 17px;
  height: auto;
  margin-right: 12px;
  stroke-width: 2px;
  color: #343434;
}

.ActionsBUListItem.Delete svg {
  color: #f44336;
}

/* .PagiPaddi {
  padding: 10px 15px 0 15px;
} */

.DashFilterSec {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.Width220 {
  min-width: 190px;
}

.Width180 {
  min-width: 160px;
}

.Width150 {
  min-width: 150px;
}

.ProspectNonAppliedBadge {
  background-color: #dfe2e6;
  color: #595959 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0;
  height: 25px;
  line-height: normal;
  white-space: nowrap;
}

.ProspectAppliedBadge {
  background-color: rgba(161, 105, 198, 0.3);
  color: #502688 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0;
  height: 25px;
  line-height: normal;
}

.ProspectScreenedBadge {
  background-color: #fff3e8;
  color: #e99a37 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0;
  height: 25px;
  line-height: normal;
}

.ProspectApprovedBadge {
  background-color: rgb(232, 255, 242);
  color: #5ac55e !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0;
  height: 25px;
  line-height: normal;
  white-space: nowrap;
}

.ProspectDeclinedBadge {
  background-color: #ffe8e8;
  color: #e23725 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0;
  height: 25px;
  line-height: normal;
  white-space: nowrap;
}

.AddprospectFirstStepForm {
  width: 100% !important;
  padding-top: 20px !important;
}

.AddprospectFirstStepFormTitle {
  font-size: 16px;
  font-weight: 500;
  color: #343434;
  line-height: 21px;
}

.FirstStepFormFullSec {
  width: 100% !important;
  padding-bottom: 20px !important;
}

.HalfStepFormFullSec {
  width: 48% !important;
  padding-bottom: 20px !important;
}

.HalfStepFormRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Width100 {
  width: 100%;
  position: relative;
}

.AccoFormSec .Width100 {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
}

.AddTourPopTT {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #343434;
  margin: 20px 0 0 0;
}

.ModalFormGroup {
  width: 100%;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ModalFormGroupH {
  width: 48%;
  padding-bottom: 10px;
  font-size: 0.875rem;
  position: relative;
}

.AddTourModal {
  display: flex;
  flex-wrap: wrap;
}

.AddTourTimeArea {
  width: 250px;
}

.AddTourTimeArea ul {
  width: 100%;
  margin: 0;
  padding: 0 15px 0 0 !important;
  height: 340px;
  overflow: auto;
}

.AddTourTimeArea ul li {
  list-style: none;
  width: 100%;
  border: 1px solid #502688;
  border-radius: 8px;
  padding: 10px 16px;
  margin: 0 0 14px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.AddTourTimeArea ul li:hover {
  background-color: rgb(109 77 159 / 10%);
}

.AddTourTime {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #502688;
  margin: 0;
}

.TimeSlotTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #343434;
  margin: 0 0 15px 0;
}

.AddTourPopTT {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #343434;
  margin: 20px 0 0 0;
}

.AddTourTimeArea ul li.TimeSelected {
  background-color: #502688;
}

.AddTourTimeArea ul li.TimeSlotBooked {
  /* border: 1px solid #efefef;
  background-color: #efefef;
  cursor: context-menu; */
  background-color: #502688;
}

.AddTourTimeArea ul li.TimeSelected .AddTourTime {
  color: #fff;
}

.AddTourTimeArea ul li.TimeSlotBooked .AddTourTime {
  color: #666666;
}

.AddTourOpenHouse {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666;
}

.AddTourOpenHouse svg {
  width: 18px;
  color: #efd763;
  fill: #efd763;
  margin-right: 5px;
}

.AddTourTimeArea ul li.TimeSelected .AddTourOpenHouse {
  color: #efefef;
}

.AddTourScheduled {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666666;
}

.AddTourScheduled svg {
  width: 18px;
  color: #666666;
  margin-right: 5px;
}

.AddTourTimeArea ul li.TimeSelected .AddTourScheduled svg {
  color: #efefef;
}

.FilterCard.SelectPros {
  width: 100%;
  padding: 25px 15px 5px 0;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterCard.SelectPros>div>div {
  min-height: 42px;
  border-color: #e1e1e1;
  border-radius: 8px;
}

.AddTourScheduled .ScheduledTag {
  background-color: rgba(161, 105, 198, 0.3);
  color: #502688 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 10px !important;
  border-radius: 15px;
  margin: 0 -10px 0 0;
  height: 25px;
  line-height: normal;
}

.AddTourTimeArea ul li.TimeSelected .AddTourScheduled .ScheduledTag {
  background-color: rgb(255 255 255);
  color: #502688 !important;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
  line-height: normal;
  font-weight: 400;
}

.prospectNameActionSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
}

.FilterFieldSec {
  width: 100%;
  display: flex;
}

.BackAddBU {
  width: 35px;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 4px;
  color: #505050 !important;
  background: #f0f0f0 !important;
}

.BackAddBU svg {
  width: 20px !important;
  height: auto !important;
  stroke-width: 2 !important;
}

.ButtonAction {
  width: 100%;
  padding-top: 15px;
  display: flex;
  align-items: center;
}

.PageMainTTSec {
  display: flex;
  align-items: center;
}

.PageMainTTSec .CountText {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  margin: 0 0 0 15px;
  color: #666666;
}

@media screen and (min-width: 360px) {
  .InsideConName {
    width: 20%;
  }

  .ApplicantDiv {
    width: 25%;
  }

  .ApplicantAction {
    width: 20%;
  }

  .mainCardDiv {
    padding-right: 0px;
  }

  .FilterOnOffBU {
    display: block;
  }

  .BodyFilterArea {
    right: -280px;
  }

  .BodyFilterArea.Open {
    right: 0px;
  }

  .InsideConName {
    width: 100%;
  }

  .ApplicantDiv {
    width: 100%;
    padding: 0px 15px;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }

  .ApplicantName {
    width: 100%;
  }

  .ApplicantNameMail {
    width: 100%;
  }

  span.ConNameTrancate {
    max-width: 100%;
  }

  .ProspectCard {
    width: 100%;
  }
}

@media screen and (min-width: 480px) {
  .FilterPill span {
    max-width: 100%;
    display: unset;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 576px) {

  /* .InsideConName {
    width: 100%;
  } */
  .NotesCard {
    padding: 15px 15px;
  }

  .ApplicantName {
    width: 150px;
  }

  .ApplicantNameMail {
    width: 150px;
  }

  span.ConNameTrancate {
    max-width: 300px;
  }
}

@media screen and (min-width: 768px) {

  /* .InsideConName {
    width: 100%;
  } */
  .ApplicantDiv {
    width: 100%;
    padding: 0px 15px;
  }

  .ApplicantAction {
    width: 60%;
    flex-wrap: wrap;
    justify-content: unset;
  }

  .ApplicantName {
    width: 180px;
  }

  .ApplicantNameMail {
    width: 280px;
  }

  span.ConNameTrancate {
    max-width: 400px;
  }

  .ProspectCard {
    width: 50%;
  }
}

@media screen and (min-width: 960px) {
  /* .InsideConName {
    width: 100%;
  } */
}

@media screen and (min-width: 1100px) {

  /* .InsideConName {
    width: 100%;
  } */
  .ApplicantDiv {
    width: 67%;
    padding: 0px 15px;
  }

  .ApplicantAction {
    width: 33%;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  .ApplicantName {
    width: 150px;
  }

  .ApplicantNameMail {
    width: 250px;
  }

  span.ConNameTrancate {
    max-width: 500px;
  }
}

@media screen and (min-width: 1200px) {
  .Width220 {
    min-width: 190px;
  }

  .Width180 {
    min-width: 160px;
  }

  .ProsUserName .LimitName {
    width: 170px;
  }

  /* .InsideConName {
    width: 100%;
  } */
  /* .ApplicantDiv {
    width: 50%;
  }
  .ApplicantDivHalf {
    width: 100%;
  }
  .ApplicantDivHalf:last-child {
    width: 100%;
  }
  .ApplicantAction {
      width: 100%;
      flex-wrap: wrap;
  } */
  .SearchCon {
    width: 100%;
    margin-bottom: 20px;
  }

  .FilterSec {
    width: 100%;
  }

  .FilterBu {
    display: none;
  }

  .FilterTitle {
    display: none;
  }
}

@media screen and (min-width: 1280px) {

  /* .InsideConName {
    width: 30%;
  } */
  .ApplicantDiv {
    width: 70%;
  }

  .ApplicantAction {
    width: 30%;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .ApplicantName {
    width: 180px;
  }

  .ApplicantNameMail {
    width: 280px;
  }

  span.ConNameTrancate {
    max-width: 600px;
  }
}

@media screen and (min-width: 1300px) {
  .ProsUserName .LimitName {
    width: calc(100% - 80px);
  }
}

@media screen and (min-width: 1360px) {
  .ProspectCard {
    width: 33.33%;
  }

  .Width220 {
    min-width: 220px;
  }

  .Width180 {
    min-width: 180px;
  }
}

@media screen and (min-width: 1500px) {
  /* .InsideConName {
    width: 20%;
  } */
  /* .mainCardDiv {
    padding-right: 280px;
  } */

  .FilterOnOffBU {
    display: none;
  }

  .BodyFilterArea {
    right: 0px;
  }

  /* .AddNoteBu {
    display: none;
  } */

  .ApplicantDiv {
    width: 70%;
  }

  .ApplicantAction {
    width: 30%;
  }

  .FilterTitle svg {
    display: none;
  }
}

@media screen and (min-width: 1600px) {

  /* .InsideConName {
    width: 30%;
  } */
  .ApplicantDiv {
    width: 70%;
  }

  .ApplicantAction {
    width: 30%;
    flex-wrap: wrap;
  }

  .SearchCon {
    width: 25%;
    margin-bottom: 0;
  }

  .FilterSec {
    width: 75%;
  }
}

@media screen and (min-width: 1750px) {
  .ProspectCard {
    width: 25%;
  }
}

@media screen and (min-width: 1800px) {

  /* .InsideConName {
    width: 20%;
  } */
  .ApplicantDiv {
    width: 75%;
  }

  .ApplicantAction {
    width: 25%;
    flex-wrap: wrap;
  }

  .ApplicantName {
    width: 250px;
  }

  .ApplicantNameMail {
    width: 350px;
  }

  .SearchCon {
    width: 35%;
  }

  .FilterSec {
    width: 65%;
  }
}

@media screen and (min-width: 1700px) {
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 250px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }
}

@media (max-width: 1199px) {
  .FilterSec {
    width: 220px;
  }

  .SearchCon {
    width: calc(100% - 220px);
  }

  .FilterFieldSec {
    width: 280px;
    height: calc(100% - 68px);
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    background: #fff;
    top: 60px;
    z-index: 99;
    right: -300px;
    align-content: flex-start;
    padding: 40px 15px;
    box-shadow: -3px 0px 20px rgb(62 85 120 / 8%);
    border-left: 1px solid #ebebeb;
    transition: all 0.2s ease-in-out;
  }

  .FilterFieldSec.Open {
    right: 0;
  }

  .FilterCard {
    width: 100%;
    padding: 10px 10px;
  }
}

@media (max-width: 991px) {
  .ProsUserName.CallLink {
    width: 100%;
  }
  .ProspectCardHeadSection {
    flex-wrap: wrap;
    justify-content: center;
  }

  .ProspectCardHeadSection .HeadConSec {
    width: 100%;
  }

  .ProspectCardHeadSection .ProsIMGSec {
    min-width: 90px;
    width: 90px;
    min-height: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 0px;
    margin-bottom: 16px;
  }

  .ProsUserName.LimitName {
    width: 100%;
    margin: 0 0 0 0;
    text-align: center;
  }

  .ProspectNonAppliedBadge,
  .ProspectAppliedBadge,
  .ProspectScreenedBadge,
  .ProspectApprovedBadge,
  .ProspectDeclinedBadge {
    position: absolute;
    top: 0;
    left: 0;
  }

  .AffordBadge {
    top: 32px;
  }

  .ProsUserName {
    justify-content: center;
  }

  .ProsUserMandateSec {
    justify-content: center;
  }

  .ActionsBU {
    position: absolute;
    right: -10px;
    top: -119px;
  }
}

@media (max-width: 959px) {
  .pageTitle.Small {
    display: none;
  }
  .pageTitle.Mobile {
    display: flex;
  }

  .pageTitle.Big {
    display: none;
  }

  .breadcrumb {
    display: none;
  }

  .pageTitleWrap {
    padding: 1rem 1.8rem 0.5rem 1.8rem;
  }

  .FilterFieldSec {
    top: 0;
  }
}

@media (max-width: 767px) {
  .AddNoteBu span {
    display: none;
  }

  .AddNoteBu svg {
    margin-right: 0;
  }

  .AddNoteBu,
  .FilterBu {
    margin: 0 5px;
  }

  .FilterSec {
    width: 130px;
  }

  .SearchCon {
    width: calc(100% - 130px);
  }

  .mainCardDiv {
    padding-bottom: 80px;
  }

  .BackAddBU {
    display: flex !important;
  }

  .AddprospectFirstStepForm {
    padding-top: 25px;
  }

  .FilterCard.SelectPros {
    padding: 25px 0px 5px 0;
  }

  .AddTourTimeArea ul {
    padding: 0 0px 0 0 !important;
  }

  .AddTourTimeArea ul::-webkit-scrollbar {
    display: none;
  }

  .AddTourTimeArea ul {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@media (max-width: 649px) {
  .AddTourTimeArea {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .HalfStepFormFullSec {
    width: 100% !important;
  }
}

@media (max-width: 479px) {
  .mainCard {
    padding: 0;
    margin: 0 0.8rem;
    background: transparent;
    box-shadow: unset;
  }

  .ProspectCardHeadSection .ProsIMGSec {
    min-width: 60px;
    width: 70px;
    min-height: 60px;
    height: 70px;
  }

  .ActionsBU {
    top: -99px;
  }

  .PagiPaddi {
    justify-content: center;
  }

  .PagiPaddi nav {
    width: 100%;
  }

  .PagiPaddi nav ul {
    justify-content: center;
  }

  .pageTitle {
    width: 100%;
    order: 2;
  }

  .breadcrumb {
    width: 100%;
    order: 1;
    padding-bottom: 12px;
  }

  .ButtonAction {
    display: flex;
    justify-content: space-between;
  }

  .pageTitleWrap {
    padding: 1rem 0.8rem 0.5rem 0.8rem;
  }
}

img.Varified {
  width: 20px;
  margin-left: 8px;
}