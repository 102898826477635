.pageTitleWrap {
    width: 100%;
    padding: 1.8rem 1.8rem 1.2rem 1.8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.pageTitle {
    margin: 0;
    color: #505050;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 27px !important;
    font-family: "Inter", sans-serif !important;
    letter-spacing: 0.01em !important;
}

.pageTitle svg {
    width: 22px;
    height: auto;
    margin-left: 4px;
}

.PageTitleSec {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 0 0;
    position: relative;
}

.PageTitleSec>div {
    display: flex;
    align-items: center;
}

.BackArrow {
    color: #505055 !important;
    background: #fff;
    border-radius: 3px;
    width: 35px;
    height: 35px;
    margin-right: 16px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.BackArrow:hover {
    color: #333 !important;
}

.BackArrow svg {
    width: 20px;
    height: 20px;
}

.DropdownPageTitleMenu {
    color: #343434;
    font-size: 14px !important;
    font-weight: 500;
    padding: 10px 18px;
    font-family: "Inter", sans-serif !important;
    width: auto;
    min-width: 180px;
}

/* .DropdownPageTitleMenu a {
  color: #343434;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 15px;
  font-family: "Inter", sans-serif !important;
  width: 120px;
} */

.breadcrumb {
    background: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    font-size: 13px;
    color: #ff8271;
    align-self: center;
    font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25ch;
    color: #505050;
    font-weight: 400;
}

.breadcrumbItem a {
    text-decoration: none;
    color: #505050;
}

.breadcrumbItem a:hover {
    text-decoration: underline;
}

.breadcrumbItem+.breadcrumbItem {
    padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem+.breadcrumbItem::before {
    /* font-size: 14px; */
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb_menuIconsSize {
    width: 14px;
    height: auto;
    padding-right: 5px;
}

.breadcrumbItem span svg {
    width: 16px;
    height: 16px;
    stroke-width: 2;
    margin-right: 8px;
    margin-top: -4px;
}

.actionClass a {
    color: #666666;
}

.imgDiv img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid white;
    object-fit: cover;
    display: block;
    margin: 0 auto;
}

.imgDiv a {
    text-decoration: none;
}

.allClientsNameInner {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.allClientsNameInner span {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #505050;
    display: block;
    text-align: left;
    width: 100%;
    padding-top: 5px;
}

.allClientsNameInner span:hover {
    color: #1565c0 !important;
}

.MuiAvatar-img {
    color: transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
    text-indent: 10000px;
}

.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    /* display: none; */
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    max-width: 500px;
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: #1565c0;
}

.mainCard {
    padding: 0px;
    border-radius: 0.5rem;
    margin: 0px 1.8rem 0 1.8rem;
    position: relative;
    /* background: white; */
    border: none;
    /* box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1); */
    margin-bottom: 0px;
}

.actionClass svg {
    margin-right: 10px;
    stroke-width: 1px;
    stroke: #000;
    width: 21px;
    height: 21px;
    cursor: pointer;
}

.actionClass button {
    background: none;
    border: none;
}

.clientDoubleButton {
    width: 110px;
    float: right;
    display: flex;
    justify-content: flex-end;
}

.TableAddBU {
    float: right;
}

.TableAddBU a {
    align-items: center;
    padding: 9px 14px;
    background: #ea983e;
    display: flex;
    min-height: 0;
    color: #fff;
    border-radius: 5px;
    transition: all 0.3s;
    outline: none;
}

.TableAddBUSecondary {
    float: right;
}

.TableAddBUSecondary button {
    align-items: center;
    padding: 9px 14px;
    background: #230058;
    display: flex;
    min-height: 0;
    color: #fff;
    border-radius: 5px;
    transition: all 0.3s;
    outline: none;
    border: none;
}

.TableAddBUSecondary button:hover {
    background: #56328c;
}

.TableAddBUSecondary svg {
    width: 20px;
    height: 20px;
}

.customFieldsetTitle {
    padding: 15px;
    font-size: 15px;
    font-weight: 500;
    color: #1565c0;
}

.noMarginBottom {
    margin-bottom: 0 !important;
    width: 100%;
    margin: 0;
    padding-left: 5px;
    padding-right: 16px;
}

.noPaddingTop li {
    padding-top: 0 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.noPaddingBottom li {
    padding-bottom: 0 !important;
}

.ReferStatusBU {
  padding: 5px 8px 5px 13px !important;
  background: #e2e2e2 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  /* color: #9f9f9f; */
  transition: all 0.3s !important;
  margin: 0 7px 0 7px !important;
  line-height: unset !important;
  /* height: 26px !important; */
}

.ReferStatusBUNew {
  padding-right: 7px !important;
}

.ReferStatusBU:after {
  display: none !important;
}

.ReferStatusBU:focus {
  border: none;
  box-shadow: none !important;
}

.ReferStatusBU svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-right: 5px;
}

.ReferStatusBU svg.DownArrowStatus {
  width: 18px;
  height: 18px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.ReferStatusBU.StatusBuRed,
.ReferStatusBU.StatusBuRed:active,
.ReferStatusBU.StatusBuRed:checked {
  background: #ffe8e8 !important;
  color: #e23725 !important;
  border-color: #ffe8e8 !important;
}

.ReferStatusBU.StatusBuYellow,
.ReferStatusBU.StatusBuYellow:active,
.ReferStatusBU.StatusBuYellow:checked {
  background-color: #fff3e8 !important;
  border-color: #fff3e8 !important;
  color: #e99a37 !important;
}

.ReferStatusBU.Approved,
.ReferStatusBU.Approved:active,
.ReferStatusBU.Approved:checked {
  background-color: #e8fff2 !important;
  border-color: #e8fff2 !important;
  color: #5ac55e !important;
}

.ReferStatusBU.Prequalified,
.ReferStatusBU.Prequalified:active,
.ReferStatusBU.Prequalified:checked {
  background-color: #5026884d !important;
  border-color: #5026884d !important;
  color: #502688 !important;
}

.ReferStatusBU.StatusBuGreen,
.ReferStatusBU.StatusBuGreen:active,
.ReferStatusBU.StatusBuGreen:checked {
  background-color: #e8fff2 !important;
  border-color: #e8fff2 !important;
  color: #5ac55e !important;
}

.ReferStatusBU.Open,
.ReferStatusBU.Open:active,
.ReferStatusBU.Open:checked {
  background-color: #eb575766 !important;
  border-color: #eb575766 !important;
  color: #a63434 !important;
}

.ReferStatusBU.InProgress,
.ReferStatusBU.InProgress:active,
.ReferStatusBU.InProgress:checked {
  background-color: #f8d52466 !important;
  border-color: #f8d52466 !important;
  color: #71621b !important;
}

.ReferStatusBU.Resolved,
.ReferStatusBU.Resolved:active,
.ReferStatusBU.Resolved:checked {
  background-color: #00808066 !important;
  border-color: #00808066 !important;
  color: #006060 !important;
}

.ReferStatusBU.Cancelled,
.ReferStatusBU.Cancelled:active,
.ReferStatusBU.Cancelled:checked {
  background-color: #eb575766 !important;
  border-color: #eb575766 !important;
  color: #a63434 !important;
}

.ReferStatusBUNotApplied {
  padding: 5px 13px 5px 13px !important;
  background: #dfe2e6 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #595959;
  transition: all 0.3s !important;
  margin: 0 !important;
  line-height: unset !important;
}

.ApplicantFilterSec {
    width: 280px;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 20px;
    align-items: center;
}

.ApplicantFilterSec p {
    font-size: 14px;
    font-weight: 500;
    color: #666;
    min-width: 70px;
    margin: 0;
    display: table;
    width: 100%;
}

.ClearFilter {
    font-size: 13px;
    font-weight: 500;
    color: #ff8271;
    width: 55px;
    margin-left: 0;
    display: table-cell;
    vertical-align: middle;
}

.formControl {
    display: block;
    width: 100%;
    min-width: 80px;
    min-height: 38px;
    height: 56px;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #8b8b8b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dfe1e9;
    border-radius: 0px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ApplicantFilterSec .formControl {
    margin-top: 10px;
}

.Dollar .formControl {
  padding: 0.375rem 0.75rem 0.375rem 4.3rem;
}

.formControl:focus,
.formControlTextarea:focus {
  box-shadow: 0px 0px 10px 0px #649bdd38;
}

.formControlTextarea {
  display: block;
  width: 100%;
  min-width: 80px;
  min-height: 38px;
  height: 56px;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dfe1e9;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 150px;
  resize: none;
}

.ProfileEditFormSec .formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  min-height: 21px;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ProfileEditFormSec .formControl.Error {
  border: 1px solid #f40;
}

.NotesRow {
    display: flex;
    flex-wrap: wrap;
}

.NotesCard {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 10px 0;
  /* overflow: hidden; */
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.06);
  transition: all 0.3s ease-in-out;
}

.NotesCard:hover {
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.15);
}

.NotesCardInside {
  display: flex;
  padding-top: 5px;
}

.NotesCardInside img {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
}

.NotesCardInside .Avatar {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
  background: #faf6fb;
  color: #502688;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InsideConName {
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    color: #32007e;
    width: 20%;
    margin-bottom: 0;
    padding: 8px 15px;
    display: flex;
    align-items: center;
}

.InsideConName span {
    color: #b7b7b7;
    font-weight: 500;
}

span.ConNameTrancate {
    color: #32007e;
    padding-right: 8px;
    font-weight: 600;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    /* margin-bottom: -4px; */
}

.InsideConText {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #333;
    margin-bottom: 0;
    width: 25%;
    padding: 8px 15px;
}

.InsideConUnit {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #666;
    margin-bottom: 0;
    /*width: 25%;*/
    padding: 8px 15px;
    text-align: center;
}

.InsideConCheck {
    /*width: 25%;*/
    padding: 0 15px;
    display: flex;
    justify-content: flex-end;
}

.InsideConCheck p {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #999;
    margin-bottom: 0;
    padding: 8px 0;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 12px 0 12px 0;
}

.NotesSearchMain {
    width: 100%;
    position: relative;
    border: 1px solid #e1e1e1;
    margin-bottom: 0;
    margin-top: 0;
    height: 44px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 11px 12px;
    margin-right: 15px;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
    position: relative;
    left: 0;
    bottom: 0;
    color: #8c8c8c;
    width: 20px;
    margin-right: 10px;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 14px 14px;
  background: #ff8271;
  display: -webkit-flex;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  height: 43px;
  margin-left: 15px;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
}

.AddNoteBu svg {
  width: 20px;
  height: 20px;
}

.FilterBu {
    align-items: center;
    padding: 9px 14px;
    background: #502688;
    display: flex;
    min-height: 0;
    color: #fff;
    border-radius: 8px;
    transition: all 0.3s;
    outline: none;
    border: none;
    height: 42px;
    margin-left: 10px;
    margin-right: 10px;
}

.FilterBu svg {
    min-width: 20px;
    height: 20px;
    margin-right: 0px;
}

.FilterBu:hover,
.FilterBu:active,
.FilterBu:focus {
    background: #230058;
    background-color: #230058;
    color: #fff;
}

.PropeCardInFR {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.BodyCon {
    display: flex;
}

.FilterList {
    margin-bottom: 20px;
    font-size: 0.875rem;
}

.FilterLabel {
    font-size: 13px;
    line-height: 35px;
    font-weight: 500;
    color: #333333;
    margin: 0;
}

.FilterOnOffBU {
    position: absolute;
    left: -30px;
    top: 0px;
    /* height: 30px; */
    background: #230058;
    color: #fff;
    padding: 4px 8px;
    /* border-radius: 5px; */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
    width: 18px;
    height: 18px;
    margin-top: 4px;
}

.ApplicantDiv {
    padding-top: 0px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 25%;
}

/*.ApplicantDiv::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #f1f1f1;
    top: 25px;
  left: 0;
}*/

.ApplicantDivTitle {
    margin: 0;
    font-size: 14px;
    color: #ff8271;
    font-weight: 400;
    width: auto;
    display: inline-block;
    /* background: #fff; */
    padding: 8px 0;
    position: relative;
    /* z-index: 1; */
}

.ApplicantDiv .InsideConUnit:first-child {
    text-align: left;
}

.ApplicantDiv .InsideConUnit:last-child {
    text-align: right;
}

.ApplicantName {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #333;
    margin-bottom: 0;
    padding: 8px 0px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.ApplicantNameDe {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #666;
    margin-bottom: 0;
    padding: 8px 0px;
    text-align: left;
}

.ApplicantNameTel {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #666;
    margin-bottom: 0;
    padding: 8px 0px;
    text-align: left;
    min-width: 120px;
}

.ApplicantNameMail {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #666;
    margin-bottom: 0;
    padding: 8px 0px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 240px;
}

.ApplicantDivHalf {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ApplicantAction {
    display: flex;
    align-items: center;
    width: 20%;
}

.mainCardDiv {
    width: 100%;
    padding-right: 280px;
    padding-bottom: 0px;
}

.BodyFilterArea {
    min-width: 280px;
    max-width: 280px;
    background: #fff;
    position: fixed;
    right: 0;
    height: 100vh;
    padding: 90px 30px 120px 30px;
    overflow: auto;
    top: 60px;
    box-shadow: 0 0px 7px 2px rgb(62 85 120 / 10%);
    transition: all ease-in-out 0.5s;
    z-index: 10;
    display: none;
}

.FilterTitle {
    font-size: 14px;
    line-height: 30px;
    font-weight: 600;
    color: #340380;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
}

.FilterTitle svg {
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
}

.FilterList {
    margin-bottom: 20px;
}

.FilterLabel {
    font-size: 13px;
    line-height: 35px;
    font-weight: 500;
    color: #333333;
    margin: 0;
}

.FilterOnOffBU {
    position: absolute;
    left: -30px;
    top: 70px;
    /* height: 30px; */
    background: #230058;
    color: #fff;
    padding: 4px 8px;
    /* border-radius: 5px; */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
    width: 18px;
    height: 18px;
    margin-top: 4px;
}

.DatePickerCustom {
    position: relative;
    z-index: 1;
}

/* .DatePickerCustom svg {
  position: absolute;
  top: 12px;
  color: #000;
  width: 18px;
  height: 18px;
  right: 12px;
  z-index: -1;
  opacity: 0.8;
} */

.NoDataMain {
    width: calc(100% - 24px);
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background: #fff;
    position: relative;
    margin: auto;
}

.NoDataIMG {
    width: 150px;
    height: 150px;
    background: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 35px;
    margin: 0 auto 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NoDataIMG img {
    width: 100%;
    opacity: .4;
}

.NoDataText {
    text-align: center;
    font-size: 36px;
    padding: 0px 0 !important;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
    line-height: unset;
    text-transform: capitalize;
    width: 100%;
}

.NoDataTextSub {
    text-align: center;
    font-size: 22px;
    padding: 0px 0 !important;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.3);
    line-height: unset;
    text-transform: capitalize;
    width: 100%;
}

.AddLeaseBU {
    padding: 9px 25px 9px 21px;
    background-color: #ff8271;
    margin: 0;
    font-size: 14px;
    width: auto;
    font-weight: 600;
    line-height: 14px;
    border-radius: 0.5rem;
    color: #fff;
    text-align: center;
    border: 1px solid #ff8271;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
    outline: none;
}

.AddLeaseBU:hover {
    background-color: #e16e5f;
    border: 1px solid #e16e5f;
}

.AddLeaseBU svg {
    width: 22px;
    margin-right: 7px;
}

.FilterShowingDiv {
    background: #f1f1f1;
    width: 100%;
    margin-top: 5px;
    padding: 10px 15px;
    border-radius: 5px;
    display: none;
}

.TitleFilterPill {
    font-size: 13px;
    font-weight: 500;
    color: #666;
    min-width: 70px;
    margin: 0;
    display: inline-block;
}

.FilterPill {
    margin-top: 5px;
    margin-right: 5px;
    background: #230058;
    color: #fff;
    padding: 4px 6px;
    display: inline-block;
    width: max-content;
    border-radius: 3px;
    font-size: 13px;
}

.FilterPill span {
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    margin-bottom: -5px;
}

.FilterPill a {
    color: #fff;
    border-left: 1px solid #49149a;
    margin-left: 7px;
    padding-left: 3px;
    display: inline-block;
}

.FilterPill a:hover {
    color: #fff;
}

.FilterPill a svg {
    width: 17px;
    height: 17px;
    margin-bottom: 1px;
}

.BadgeNew {
    background: #f48272;
    color: #fff !important;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 3px;
    margin-left: 8px;
    text-transform: uppercase;
}

.SearchFilterSec {
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -10px;
    padding: 5px 0 20px 0;
    justify-content: space-between;
}

.SearchCon {
    width: 50%;
    padding: 0 10px;
}

.SearchConSec {
    width: 100%;
    border: 1px solid #e1e1e1;
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 8px;
    background: #fff;
}

.SearchConSec input {
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    width: 100%;
    border: none;
}

.SearchConSec svg {
    width: 20px;
    opacity: 0.8;
    margin-right: 10px;
}

.FilterSec {
    width: 50%;
    display: flex;
}

.FilterCard {
    width: 33%;
    padding: 0 10px;
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.FilterBUCard {
    width: 30%;
    padding: 0 10px;
    max-width: 150px;
}

.FilterCard.Select>div>div {
    min-height: 42px;
    border-color: #e1e1e1;
    border-radius: 8px;
}

.ApplyFilterBU {
    width: 100%;
    background: #502688;
    color: #fff;
    height: 38px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

.ApplyFilterBU:hover {
    background: #230058;
}

.ReffStatus {
    margin: 0 10px 0 0;
    font-size: 14px;
    white-space: nowrap;
    /* display: table-cell; */
    display: inline-block;
    vertical-align: middle;
}

.ReffStatus.Archived {
    background: #87858b;
    color: #fff;
    padding: 2px 8px;
    border-radius: 3px;
    font-size: 12px;
}

.AcrhivedLabel {
    background: #e5e5e5;
    color: #505050 !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 12px !important;
    border-radius: 15px;
    margin: 0 0 0 10px;
    display: inline-block;
}

.ProspectCardUnder {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 20px 0 #1f025412;
    border-radius: 8px;
    padding: 16px;
    transition: all 0.3s ease-in-out;
}

.ProspectCardUnder:hover {
    box-shadow: 0 0 28px 0 #1f02541f;
}

.TrelloLeaderCardRow .ProspectCardUnder {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 20px 0 #1f025412;
    border-radius: 8px;
    padding: 10px;
    transition: all 0.3s ease-in-out;
}

.ProspectCardHeadSection {
    display: flex;
    border-bottom: 1px solid #dfe2e6;
    padding-bottom: 16px;
    margin-bottom: 8px;
    position: relative;
}

.ProspectCardHeadSectionSS {
    display: flex;
    position: relative;
}

.ProspectCardHeadSection .ProsIMGSec {
    min-width: 90px;
    width: 90px;
    min-height: 90px;
    height: 90px;
    border-radius: 8px;
    margin-right: 16px;
    overflow: hidden;
    background-color: #fbf7fc;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.ProspectCardHeadSectionSS .ProsIMGSec {
    min-width: 80px;
    width: 80px;
    min-height: 80px;
    height: 80px;
    border-radius: 8px;
    margin-right: 15px;
    overflow: hidden;
    background-color: #fbf7fc;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.ProsIMGSec .ProspectCardImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.ProsIMGSec p {
    font-size: 24px;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
    color: #502688;
    letter-spacing: 1px;
}

.ProspectCardHeadSection .HeadConSec {
    width: calc(100% - 106px);
}

.ProspectCardHeadSectionSS .HeadConSec {
    width: calc(100% - 95px);
}

.ProsUserName {
    font-size: 17px;
    font-weight: 600;
    line-height: 150%;
    margin: 0 0 7px 0;
    color: #505050;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.ProspectCardHeadSectionSS .ProsUserName {
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 5px 0;
}

.ProspectCardHeadSectionSS .ProsUserName svg {
    width: 17px;
    height: 17px;
    margin-right: 9px;
}

.ProspectCardHeadSectionSS .ProsUserName.CallLink,
.ProspectCardHeadSectionSS .ProsUserName.CallLink:hover {
    margin: 0;
    color: #ff8271;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
}

.ProspectCardHeadSectionSS .ProsUserName.CallLink::after {
    content: '';
    position: absolute;
    width: calc(100% - 26px);
    height: 1px;
    background-color: #ff8271;
    right: 0;
    bottom: -4px;
    opacity: 0;
    transition: all .2s ease-in-out;
}

.ProspectCardHeadSectionSS .ProsUserName.CallLink:hover::after {
    opacity: 1;
}

.ProsUserName.LimitName {
    width: calc(100% - 85px);
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    margin: 0 12px 0 0;
}

.ProsUserMandateSec {
  width: 100%;
  display: flex;
  padding-top: 4px;
}

.ProsUserMandateSecBig {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  padding-top: 15px;
  padding-bottom: 0px;
}

.MandateCheckSec {
    width: 24px;
    height: 24px;
    background-color: #dfe2e6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.ProspectCardHeadSectionSS .MandateCheckSec {
    width: 22px;
    height: 22px;
    margin-right: 6px;
}

.MandateCheckSec img {
    filter: brightness(1) invert(1);
    opacity: .6;
}

.MandateCheckSec.Active img,
.MandateCheckSec.Fail img {
    filter: brightness(0) invert(1);
    opacity: 1;
}

.defaultImg {
    width: 100%;
}

.MandateCheckSec:last-child {
    margin-right: 0;
}

.MandateCheckSec.Active {
    background-color: #27c27b;
}

.MandateCheckSec.Fail {
    background-color: #ff8271;
}

.MandateCheckSec svg {
    width: 14px;
    height: auto;
    color: #fff;
    stroke-width: 3px;
}

.ProspectCardBodySec {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #dfe2e6;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.ProspectCardBodySec ul {
    margin: 0;
    padding: 0;
    width: 100%;
}

.ProspectCardBodySec ul li {
    list-style: none;
    padding: 5px 0;
    display: flex;
    align-items: center;
}

.ProspectCardBodySec ul li p.Title {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    color: #666666;
    min-width: 110px;
}

.ProspectCardBodySec ul li p.Content {
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
    color: #343434;
    display: flex;
    align-items: center;
}

.ProspectCardBodySec ul li p.Content.Hover {
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
    color: #343434;
    position: relative;
    transition: all .3s ease-in-out;
}

.ProspectCardBodySec ul li p.Content.Hover:hover {
    color: #f48272;
}

.ProspectCardBodySec ul li p.Content.Hover::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    background: #fff;
    bottom: 0;
    left: 0%;
    /* transform: translate(-50%, 0); */
    transition: all .3s ease-in-out;
}

.ProspectCardBodySec ul li p.Content.Hover:hover::after {
    width: 100%;
    background: #f48272;
}

.ProspectCardFootSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
}

.ProsCardDate {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
    color: #666666;
}

.ProsCardMailBU {
    width: 50px;
    height: 30px;
    background: #fff;
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    position: relative;
}

.ProsCardMailBU:focus {
    outline: none;
}

.ProsCardMailBU span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ef8977;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    line-height: 16px;
    position: absolute;
    top: -4px;
    right: -8px;
}

.ProsCardMailBU svg {
    width: 28px;
    height: auto;
    fill: #dfe2e6;
    stroke: #fff;
}

.ActionsBU {
    width: 30px;
    height: 25px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    color: #502688 !important;
    background-color: #ffffff !important;
    margin: 0 !important;
    border: none !important;
    padding: 0 !important;
    border-radius: 8px !important;
    /* position: absolute; */
    right: -8px;
    top: 0;
}

.ActionsBUTrelloList {
    width: 30px;
    height: 25px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    color: #343434 !important;
    background-color: transparent !important;
    margin: 0 !important;
    border: none !important;
    padding: 0 !important;
    border-radius: 8px !important;
    /* position: absolute; */
    right: 0;
    top: 0;
}

.show>.btn-primary.dropdown-toggle.ActionsBU {
    background-color: #230058;
    border: none;
}

.ActionsBU svg {
    min-width: 16px;
    min-height: 16px;
    width: 22px;
    height: 22px;
    margin-bottom: 0;
    margin-left: 0;
}

.ActionsBUTrelloList svg {
    min-width: 16px;
    min-height: 16px;
    width: 22px;
    height: 22px;
    margin-bottom: 0;
    margin-left: 0;
}

.ActionsBUListItem {
    font-size: 14px;
    font-weight: 500 !important;
    color: #343434 !important;
    /* border-bottom: 1px solid #e0e0e0; */
    padding: 10px 10px !important;
    min-width: 80px;
    display: flex !important;
    align-items: center;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus,
.ActionsBUListItem:active {
    color: #343434 !important;
    background: #f8f8f8 !important;
    background-color: #f8f8f8 !important;
}

.ActionsBUListItem.Delete {
    color: #f44336 !important;
}

.ActionsBUListItem svg {
    width: 17px;
    height: auto;
    margin-right: 12px;
    stroke-width: 2px;
    color: #343434;
}

.ActionsBUListItem.Delete svg {
    color: #f44336;
}

/* .PagiPaddi {
  padding: 10px 15px 0 15px;
} */

.DashFilterSec {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.Width220 {
    min-width: 190px;
}

.Width180 {
    min-width: 160px;
}

.Width150 {
    min-width: 150px;
}

.ProspectNonAppliedBadge {
    background-color: #dfe2e6;
    color: #595959 !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 12px !important;
    border-radius: 15px;
    margin: 0;
    height: 25px;
    line-height: normal;
    white-space: nowrap;
}

.ProspectAppliedBadge {
    background-color: rgba(161, 105, 198, 0.3);
    color: #502688 !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 12px !important;
    border-radius: 15px;
    margin: 0;
    height: 25px;
    line-height: normal;
}

.ProspectScreenedBadge {
    background-color: #fff3e8;
    color: #e99a37 !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 12px !important;
    border-radius: 15px;
    margin: 0;
    height: 25px;
    line-height: normal;
}

.ProspectApprovedBadge {
    background-color: rgb(232, 255, 242);
    color: #5ac55e !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 12px !important;
    border-radius: 15px;
    margin: 0;
    height: 25px;
    line-height: normal;
    white-space: nowrap;
}

.ProspectDeclinedBadge {
    background-color: #ffe8e8;
    color: #e23725 !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 12px !important;
    border-radius: 15px;
    margin: 0;
    height: 25px;
    line-height: normal;
    white-space: nowrap;
}

.ProspectCardHeadSectionSS .ProspectNonAppliedBadge,
.ProspectCardHeadSectionSS .ProspectAppliedBadge,
.ProspectCardHeadSectionSS .ProspectScreenedBadge,
.ProspectCardHeadSectionSS .ProspectApprovedBadge,
.ProspectCardHeadSectionSS .ProspectDeclinedBadge {
    font-size: 11px;
    padding: 5px 8px !important;
    height: 24px;
}

.AddprospectFirstStepForm {
    width: 100% !important;
    padding-top: 20px !important;
}

.AddprospectFirstStepFormTitle {
    font-size: 16px;
    font-weight: 500;
    color: #343434;
    line-height: 21px;
}

.FirstStepFormFullSec {
    width: 100% !important;
    padding-bottom: 20px !important;
}

.HalfStepFormFullSec {
    width: 48% !important;
    padding-bottom: 20px !important;
}

.HalfStepFormRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.AccoFormSec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Width100 {
    width: 100%;
    position: relative;
}

.AccoFormSec .Width100 {
    width: 100%;
    position: relative;
    padding-bottom: 0px;
}

.AddTourPopTT {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #343434;
    margin: 20px 0 0 0;
}

.ModalFormGroup {
    width: 100%;
    padding-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ModalFormGroupH {
    width: 48%;
    padding-bottom: 10px;
    font-size: 0.875rem;
    position: relative;
}

.ModalFormGroupAdd {
    max-width: 1100px;
}

.AddTourModal {
    display: flex;
    flex-wrap: wrap;
}

.AddTourTimeArea {
    width: 250px;
}

.AddTourTimeArea ul {
    width: 100%;
    margin: 0;
    padding: 0 15px 0 0 !important;
    height: 340px;
    overflow: auto;
}

.AddTourTimeArea ul li {
    list-style: none;
    width: 100%;
    border: 1px solid #502688;
    border-radius: 8px;
    padding: 10px 16px;
    margin: 0 0 14px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.AddTourTimeArea ul li:hover {
    background-color: rgb(109 77 159 / 10%);
}

.AddTourTime {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #502688;
    margin: 0;
}

.TimeSlotTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #343434;
    margin: 0 0 15px 0;
}

.AddTourPopTT {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #343434;
    margin: 20px 0 0 0;
}

.AddTourTimeArea ul li.TimeSelected {
    background-color: #502688;
}

.AddTourTimeArea ul li.TimeSlotBooked {
    /* border: 1px solid #efefef;
  background-color: #efefef;
  cursor: context-menu; */
    background-color: #502688;
}

.AddTourTimeArea ul li.TimeSelected .AddTourTime {
    color: #fff;
}

.AddTourTimeArea ul li.TimeSlotBooked .AddTourTime {
    color: #666666;
}

.AddTourOpenHouse {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #666;
}

.AddTourOpenHouse svg {
    width: 18px;
    color: #efd763;
    fill: #efd763;
    margin-right: 5px;
}

.AddTourTimeArea ul li.TimeSelected .AddTourOpenHouse {
    color: #efefef;
}

.AddTourScheduled {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #666666;
}

.AddTourScheduled svg {
    width: 18px;
    color: #666666;
    margin-right: 5px;
}

.AddTourTimeArea ul li.TimeSelected .AddTourScheduled svg {
    color: #efefef;
}

.FilterCard.SelectPros {
    width: 100%;
    padding: 25px 15px 5px 0;
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.FilterCard.SelectPros>div>div {
    min-height: 42px;
    border-color: #e1e1e1;
    border-radius: 8px;
}

.AddTourScheduled .ScheduledTag {
    background-color: rgba(161, 105, 198, 0.3);
    color: #502688 !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 10px !important;
    border-radius: 15px;
    margin: 0 -10px 0 0;
    height: 25px;
    line-height: normal;
}

.AddTourTimeArea ul li.TimeSelected .AddTourScheduled .ScheduledTag {
    background-color: rgb(255 255 255);
    color: #502688 !important;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 0;
    line-height: normal;
    font-weight: 400;
}

.prospectNameActionSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
}

.ProspectCardHeadSectionSS .prospectNameActionSec {
    margin-bottom: 5px;
}

.FilterFieldSec {
    width: 100%;
    display: flex;
}

.BackAddBU {
    width: 35px;
    height: 35px;
    display: none;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border-radius: 4px;
    color: #505050 !important;
    background: #f0f0f0 !important;
}

.BackAddBU svg {
    width: 20px !important;
    height: auto !important;
    stroke-width: 2 !important;
}

.ButtonAction {
    width: 100%;
    padding-top: 15px;
    display: flex;
    align-items: center;
}

.PageMainTTSec {
    display: flex;
    align-items: center;
}

.PageMainTTSec .CountText {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    margin: 0 0 0 15px;
    color: #666666;
}

.AddDeviceSec {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 14px;
}

.AddDeviceSec .SearchConSec {
    width: 100%;
    border: 1px solid #e1e1e1;
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 8px;
    background: #fff;
}

.AddDeviceSec .SearchConSec input {
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    width: 100%;
    border: none;
}

.AddDeviceSec .SearchConSec svg {
    width: 20px;
    opacity: 0.8;
    margin-right: 10px;
}

.AddDeviceRow {
    display: flex;
    flex-wrap: wrap;
    margin: 5px -12px 25px -12px;
    padding: 0 4px;
    align-content: space-between;
    max-height: 550px;
    overflow: auto;
}

.ProspectCardPop {
    width: 33.33%;
    padding: 12px 10px;
}

.ProspectCardUnderPop {
    width: 100%;
    background: #fff;
    box-shadow: 0 1px 4px #00000029;
    border-radius: 8px;
    padding: 12px;
}

.CustomCheck>span {
    padding: 0 !important;
}

.LeaderBadge {
    margin-left: auto;
    position: relative;
}

.LeaderBadge svg {
    width: 30px;
    height: 30px;
    stroke-width: 1.5px;
    color: #502688;
    fill: #e3d2ee69;
}

.LeaderBadge span {
    font-size: 11px;
    position: absolute;
    font-weight: 600;
    transform: translate(-50%, 0);
    top: 2px;
    left: 50%;
    color: #502688;
}

.TrelloRow {
    display: flex;
    margin: 0 -12px;
    overflow: auto;
    /* -webkit-transform: translate3d(0, 0, 0); */
    scrollbar-color: var(--ds-background-neutral-hovered, #091e4224) var(--ds-background-neutral, #091e420f);
    height: calc(100vh - 182px);
}

.LeaderboardListSec {
    width: 440px;
    min-width: 440px;
    height: auto;
    padding: 0 12px 10px 12px;
}

.LeaderboardListSecUnder {
    width: 100%;
    max-height: 100%;
    padding: 0px;
    border-radius: 12px;
    background-color: #eff2fa;
}

.TrelloLeaderCardRow {
    width: auto;
    overflow: auto;
    max-height: calc(100vh - 308px);
    padding: 10px 12px 12px 12px;
    margin: 0px 5px;
    /* -webkit-transform: translate3d(0, 0, 0); */
    scrollbar-color: var(--ds-background-neutral-hovered, #091e4224) var(--ds-background-neutral, #091e420f);
    scrollbar-width: thin;
}

.LeaderboardListSec .Head {
    width: 100%;
    padding: 15px 12px 0px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.LeaderboardListSec .Head p {
    margin-bottom: 5px;
    padding: 0px 5px;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #343434;
}

.LeaderboardListSec .ProspectCard {
    width: 100%;
    padding: 5px 0;
}

.TrelloAddProspect {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    color: #44546f !important;
    font-size: 16px;
    font-weight: 500;
    transition: all .2s ease-in-out;
}

.TrelloAddProspect svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.TrelloAddProspect:hover {
    color: #502688 !important;
}

.AddTrelloListBU {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 18px;
    color: #748095 !important;
    font-size: 18px;
    font-weight: 500;
    transition: all .2s ease-in-out;
}

.AddTrelloListBU svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

.AddTrelloListBU:hover {
    color: #44546f !important;
}

.AddTrelloListBUEdit,
.AddTrelloListBUEdit:hover {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px;
    margin: 0 5px;
    color: #343434 !important;
    font-size: 18px;
    font-weight: 600;
    transition: all .2s ease-in-out;
}

.CardListAddForm {
    width: 100%;
    padding: 18px;
    display: flex;
    flex-wrap: wrap;
}

.CardListAddForm.Edit {
    padding: 0px;
}

.CardListAddForm input {
    width: 100%;
    border: 1px solid #e1e1e1;
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
}

.CardListAddAddBU {
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    color: #ffffff !important;
    background: #ff8271 !important;
    border: 1px solid #ff8271 !important;
    border-radius: 8px !important;
    padding: 10px 14px !important;
    margin: 12px 0 0 0 !important;
    text-transform: capitalize !important;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.3s;
}

.CardListAddAddBU:hover {
    background: #e16e5f !important;
    border: 1px solid #e16e5f !important;
}

.CardListAddCanBU {
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    color: #343434 !important;
    background: #fff !important;
    border: 1px solid #fff !important;
    border-radius: 8px !important;
    padding: 10px 14px !important;
    margin: 12px 8px 0 0 !important;
    text-transform: capitalize !important;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.3s;
}

.CardListAddCanBU:hover {
    background: #f1ecf3 !important;
    color: #343434 !important;
    border: 1px solid #f1ecf3 !important;
}

.ProspectDetailsPanel {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.ProspectDetailsPanel.Open {
    z-index: 999;
}

.ProspectDetailsPanel::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    top: 0;
    left: 0;
    transition: all .3s ease-in-out;
}

.ProspectDetailsPanel.Open::after {
    background-color: rgba(0, 0, 0, 0.4);
}

.PanelUnder {
    width: 1000px;
    height: 100%;
    background-color: #fff;
    padding: 25px 20px;
    position: absolute;
    top: 0;
    right: -1100px;
    z-index: 1;
    overflow: auto;
    transition: all .3s ease-in-out;
}

.ProspectDetailsPanel.Open .PanelUnder {
    right: 0;
}

.SMProCardHead {
    width: 100%;
    padding: 15px 20px 15px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SMProCardHead .avatar {
  margin-bottom: 0;
  position: absolute;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
}

.SMProCardHead .SMProPicMainDubble:hover .avatar {
  cursor: pointer;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.SMProCardHead .avatar.active {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
  z-index: 1;
}

.SMProCardHead .avatar img {
  position: relative;
  z-index: 2;
  border: 2px solid white;
}

.SMProCardHead h5 {
  margin: 0;
  line-height: normal;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #505050;
  /* position: absolute; */
  cursor: pointer;
  padding: 0 5px;
  /* text-align: center; */
  left: 0;
  /* display: none; */
}

.SMProCardHead h5 span {
  position: relative;
}

.SMProCardHead h5 span>span {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ef8977;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  line-height: 16px;
  position: absolute;
  top: -4px;
  right: -6px;
}

.SMProCardHead h5 svg {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  /* color: #5e5e5e; */
  fill: #fff;
  stroke: #406b81;
  stroke-width: 2.5px;
}

.SMProPicMainDubble {
  position: relative;
  width: 90px;
  margin: 0;
  display: flex;
  justify-content: center;
  height: 90px;
  transition: all 0.3s ease-in-out;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  min-height: 58px;
  min-width: 58px;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 2px solid #fbf7fc;
  background: #fbf7fc;
  /*margin: 0 0 1rem;*/
}

.avatar p {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbf7fc;
  margin: 0;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
  color: #502688;
  letter-spacing: 1px;
  border: 2px solid #fff;
}

.avatar.avatarxl {
  height: 5.8rem;
  width: 5.8rem;
}

.roundedcircle {
  border-radius: 50% !important;
}

.avatar>a>img,
.header form .input-group .input-group-append .avatar>button.btn>img,
.chat-block .chat-sidebar .chat-sidebar-content .list-group .avatar>.list-group-item.active>img,
.avatar>img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar.avatarlg {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar.avatarlg .avatartitle {
  font-size: 29px;
}

.avatar .avatartitle {
  background: #d7d7d7;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-transform: uppercase;
  /*font-size: 19px;*/
}

.SMProCardHeadTab .avatar {
  margin-bottom: 15px;
}

.SMProCardHead .avatar.active .UploadIcon {
  display: flex;
}

.PropertyImageSec.Avatar {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AvatarSec {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.AvatarHalfSec {
  width: Calc(50% - 1.2px);
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.AvatarSec.Full .AvatarHalfSec {
  width: 100%;
}

.AvatarHalfIcon {
  width: 100%;
  height: Calc(50% - 1px);
  display: flex;
}

.AvatarHalfSec.Full .AvatarHalfIcon,
.AvatarSec.Full .AvatarHalfIcon {
  height: 100%;
}

.AvatarHalfIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.AvatarHalfIcon span {
  width: 100%;
  height: 100%;
  background: #f48272;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.CummunicationChatBU {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: -5px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  z-index: 2;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.CummunicationChatBU svg {
  width: 20px;
  height: 19px;
  fill: #2d2d2e;
  stroke: #fff;
  stroke-width: 2.5px;
}

.CummunicationChatBU span {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ef8977;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  font-weight: 500;
  color: #fff;
  line-height: 16px;
  position: absolute;
  top: -7px;
  right: -5px;
}

.MandateCheckSecBig {
  width: 30px;
  height: 30px;
  background-color: #dfe2e6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
}

.MandateCheckSecBig img {
  filter: brightness(1) invert(1);
  opacity: .8;
}

.MandateCheckSecBig.Active img,
.MandateCheckSecBig.Fail img {
  filter: brightness(0) invert(1);
  opacity: 1;
}

.MandateCheckSecBig.Active {
  background-color: #27c27b;
}

.MandateCheckSecBig.Fail {
  background-color: #f48272;
}

.MandateCheckSecBig svg {
  width: 14px;
  height: auto;
  color: #fff;
  stroke-width: 3px;
}

.HeadConText {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.01em;
    margin: 10px 0 0 5px;
    color: #94c5df;
    text-align: left;
}

.ProEditSec {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.EditActionsBU {
    width: 40px !important;
    height: 40px;
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    color: #87959d !important;
    background-color: rgb(242 242 242 / 21%) !important;
    margin: 0 !important;
    border: 1px solid #f2f2f2 !important;
    padding: 0 !important;
    border-radius: 50% !important;
}

.EditActionsBU:hover {
  background-color: #fbf7fc !important;
  color: #505050 !important;
}

.EditActionsBU.Table {
  background-color: transparent !important;
  color: #502688 !important;
  border: none !important;
  background-color: transparent !important;
}

.EditActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 24px;
  height: 24px;
  margin-bottom: 0;
  margin-left: 0;
}

.EditActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 10px 10px !important;
  min-width: 80px;
  display: flex;
  align-items: center;
}

.EditActionsBUListItemSmall {
  font-size: 13px;
  padding: 8px 10px !important;
}

.EditActionsBUListItem.Delete,
.EditActionsBUListItem.Delete:hover,
.EditActionsBUListItem.Delete:focus,
.EditActionsBUListItem.Delete:active {
  color: #f44336 !important;
}

.EditActionsBUListItem svg {
  width: 17px;
  height: auto;
  margin-right: 12px;
  stroke-width: 2px;
  color: #343434;
}

.EditActionsBUListItem.Delete svg {
  color: #f44336;
}

.EditActionsBUListItem:hover,
.EditActionsBUListItem:focus,
.EditActionsBUListItem:active,
.EditActionsBUListItemSmall:hover,
.EditActionsBUListItemSmall:focus,
.EditActionsBUListItemSmall:active {
  color: #343434 !important;
  background: #f8f8f8 !important;
}

.PanelCloasesec {
    width: 100%;
    margin-bottom: 10px;
}

.PanelCloasesec button {
    color: #505050;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 27px !important;
    font-family: "Inter", sans-serif !important;
    letter-spacing: 0.01em !important;
    display: flex;
    align-items: center;
    background: transparent;
    padding: 10px 30px 20px 0;
}

.PanelCloasesec button svg {
    width: 24px;
    margin-right: 12px;
    color: #767272;
}

.SMProCardHeadCon {
    width: calc(100% - 190px);
    padding: 0 20px;
}

.SMProCardHeadRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #f2f2f2;
    margin-bottom: 50px;
}

.SMProCardHeadBuSec {
    display: flex;
    align-items: center;
    position: relative;
    top: -30px;
}

.PanelMailBU {
     width: 40px !important;
    height: 40px;
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    color: #87959d !important;
    background-color: rgb(242 242 242 / 21%) !important;
    margin: 0 10px !important;
    border: 1px solid #f2f2f2 !important;
    padding: 9px !important;
    border-radius: 50% !important;
}

.PanelMailBU:hover {
  background-color: #fbf7fc !important;
  color: #505050 !important;
}

.SMProCardHeadProCon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.SMProCardHeadProCon .HeadConCard {
    width: 25%;
    border-top: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    padding: 15px 25px;
}

.SMProCardHeadProCon .HeadConCard:last-child {
    border-right: none;
}

.SMProCardHeadProCon .HeadConCard .ProsDeTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    color: #666666;
}

.SMProCardHeadProCon .HeadConCard .ProsDeCon {
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
    color: #343434;
    display: flex;
    align-items: center;
}

.SMProCardHeadProCon .HeadConCard .ProsDeCon.Link,
.SMProCardHeadProCon .HeadConCard .ProsDeCon.Link:hover {
    color: #ff8271;
    cursor: pointer;
}

.SMProCardHeadProCon .HeadConCard .ProsDeCon span {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    position: relative;
}

.SMProCardHeadProCon .HeadConCard .ProsDeCon.Link span::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #f48272;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: all .2s ease-in-out;
}

.SMProCardHeadProCon .HeadConCard .ProsDeCon.Link span:hover::after {
    opacity: 1;
}

.SMProCardHeadProCon .HeadConCard .ProsDeCon svg {
    min-width: 18px;
    height: 18px;
    margin-right: 10px;
}

.SMBigSideHead {
  display: flex;
  /*justify-content: space-between;*/
  margin-bottom: 20px;
  align-items: center;
  padding: 0 5px;
}

.SMBigSideHead h5 {
  margin-bottom: 0;
  padding-top: 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-top: 0;
  margin-right: 25px;
  color: #343434;
}

.SMBigSideBody {
  position: relative;
}

.FormAccoHeadTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-transform: capitalize;
  color: #505050;
  margin: 0;
}

.FormAccoHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormAccoHeadTitlePro {
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  letter-spacing: 0.01em;
}

.CussAccoRow {
  display: flex;
  flex-wrap: wrap;
}

.ProspectTaskTable,
.ProspectTaskTable table {
  width: 100%;
}

.ProspectTaskTable table tr th {
  padding: 11px 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #230058;
  text-transform: uppercase;
  font-family: "Inter", sans-serif !important;
  cursor: pointer;
}

.ProspectTaskTable table tr th svg {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

.ProspectTaskTable table tr th svg.Normal {
  transform: rotate(90deg);
  width: 15px;
}

.ProspectTaskTable table tr th:nth-child(1) {
  width: 50%;
}

.ProspectTaskTable table tr th:nth-child(2),
.ProspectTaskTable table tr th:nth-child(3) {
  width: 25%;
}

.ProspectTaskTable.Two table tr th:nth-child(1) {
  width: 40%;
}

.ProspectTaskTable.Two table tr th:nth-child(2),
.ProspectTaskTable.Two table tr th:nth-child(3),
.ProspectTaskTable.Two table tr th:nth-child(4) {
  width: 20%;
}

.ProspectTaskTable table tr td {
  height: 40px;
  border-top: 1px solid #e3e3e4;
  padding: 10px 10px;
  position: relative;
}

.ProspectTaskTable table tr:nth-child(even) td {
  background-color: #fbf7fc;
}

.ProspectTaskTable table tr td p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #343434;
  margin: 0;
  padding: 10px 0;
  font-family: "Inter", sans-serif !important;
}

.ProspectTaskTable.Two table tr td p.Des {
  color: #666666;
}

.ProspectTaskTable.Two table tr td p.Credit {
    color: #2fa42b;
    font-weight: 500;
}

.ProspectTaskTable table tr td p span {
  margin-left: 15px;
}

.ProspectTaskTable table tr td p.Emoji {
  width: 40px;
  height: 40px;
  background-color: #ef8977;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #fff;
  text-transform: uppercase;
}

.ProspectTaskTable table tr td p.TourCretorName {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #343434;
  margin: 0 0 0 12px;
  padding: 10px 0;
}

.TableSmallTitle {
  text-align: left !important;
  min-width: 150px;
}

.ProsCheckSec {
  margin: 0 !important;
}

.ReportBU {
  font-size: 13px;
  padding: 10px;
  background: transparent;
  color: #f48272;
  margin: 0 0 0 -5px;
  position: relative;
}

.ReportBU svg {
  width: 18px;
  margin-right: 8px;
}

.ReportBU:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  background-color: #f48272;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: all 0.2s ease-in-out;
}

.ReportBU:hover:after {
  width: 85%;
}

.TourconfirmedBadge {
  position: absolute;
  right: 55px;
  top: 16px;
  padding: 5px 13px 5px 13px;
  background: #5ac55e;
  border: 0px solid #5ac55e;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin: 0 !important;
  line-height: inherit;
  font-family: 'Inter', sans-serif !important;
}

.AccoCheck {
  width: 24px;
  height: 24px;
  border: 1px solid #dfe1e9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AccoCheck.Active {
  border: none;
  background: #2fa42b;
}

.AccoCheck svg {
  width: 16px;
  height: 20px;
  display: none;
  color: #fff;
  stroke-width: 3px;
}

.AccoCheck.Active svg {
  display: block;
}

.FormGroupHalf {
  width: 48%;
  padding: 15px 0;
  font-size: 14px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.FormGroupFull {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  position: relative;
}

.FormGroupFull>div>div>div,
.FormGroupHalf>div>div>div {
  font-size: 14px !important;
}

.ProfileCardEditSec .FormGroupFull {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
  padding: 0 0px 0px 0px;
  font-weight: 400;
  color: #919191;
  margin-bottom: 22px;
}

.ProfileCardEditSec .FormGroupFull.FormTimeZone {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.ToggleBUHalf {
  width: 48%;
  padding: 15px 0;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.FormLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 8px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormLabel span {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #bec1ca;
}

.FormLabelSmall {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 12px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AppliFormToggleBU {
  width: 50%;
  height: 56px;
  background: #fff;
  border: 1px solid #dfe1e9;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  cursor: auto !important;
}

.AppliFormToggleBU.Active {
  width: 50%;
  height: 56px;
  background: #f2f2f2;
  border: 1.5px solid #b9b8b8;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #b9b8b8;
}

.AppliAccoFormDateTenantDE>div>div {
  font-size: 14px !important;
  color: #8b8b8b !important;
}

.CalendarIcon {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 44px;
  height: 44px;
  background: #f2f2f2;
  color: #bab9b9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CalendarIcon svg {
  width: 20px;
  height: 20px;
}

.ShowHideIcon {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 44px;
  height: 44px;
  background: #fff;
  color: #bec1ca;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.ShowHideIcon svg {
  width: 20px;
  height: 20px;
}

.CusRadioRow {
  width: 100%;
  display: flex;
  position: relative;
  padding-bottom: 0px;
}

.CusRadio {
  width: 50%;
  cursor: auto;
}

.checkmark {
  width: 100%;
  height: 56px;
  background: #fff;
  border: 1px solid #dfe1e9;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8c8c8c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CusRadio input:checked~.checkmark {
  background: #e2e2e2;
  border: 1.5px solid #b9b8b8;
  color: #b9b8b8;
}

.CusRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.DivFull {
  width: 100%;
}

.DivFull.Padd {
  padding: 0 15px;
}

.DivFullTerm {
  border: 3px solid #1a1a1a;
  height: 600px;
  overflow: auto;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.FileUpTitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 6px 0;
}

.FileUpList {
  margin: 0;
  padding: 0;
}

.FileUpList li {
  list-style: none;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  padding: 6px 0 6px 25px;
}

.FileUpList li::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #2a5bee;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.FileIMGDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 12px;
}

.FileUpDuBUDiv {
  width: 100%;
  height: 280px;
  margin: 0 0 20px 0;
}

.FileUpDuBUDiv input {
  display: none;
}

.UploadLabel {
  padding: 0;
  background-color: transparent;
  font-size: 14px;
  width: 100%;
  display: inline-flex;
  font-weight: 500;
  flex-wrap: wrap;
  align-content: center;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #a069c6;
  text-align: center;
  vertical-align: middle;
  border: 1px dashed #a069c6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
}

.UploadLabel svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.UploadLabel div {
  display: none;
}

.UploadLabel span {
  width: 100%;
  height: 100%;
  padding: 5px 15px 5px 20px;
  display: inline-flex;
  flex-wrap: wrap;
  align-content: center;
}

.UploadLabel div.UpIcon {
  display: block;
  width: 100%;
}

.UpIcon img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.UploadLabel span p {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
}

.UploadText,
.UploadLabel span p.UploadText {
  margin: 0 0 5px 0;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #666666;
}

.ImgFile {
  /* min-width: 180px; */
  width: 180px;
  height: 180px;
  position: relative;
  margin: 0px;
  padding: 10px;
}

.ImgFile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.ImgFile a {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  width: 23px;
  height: 23px;
  text-align: center;
}

.ImgFile a svg {
  width: 15px;
  height: 15px;
  margin-top: 0px;
}

.ImgFile button {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: 0px;
  right: 0px;
  min-width: 23px;
  min-height: 23px;
  text-align: center;
  padding: 0;
  width: 23px;
  height: 23px;
}

.ImgFile button svg {
  width: 15px;
  height: 15px;
  margin-top: 0px;
}

.FileListRow {
  display: flex;
  flex-wrap: wrap;
  /* margin: 0 -15px; */
  justify-content: space-between;
  width: 100%;
}

.FileListRename {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0.01em !important;
  color: #343434 !important;
  padding: 6px 11px !important;
  min-width: 20px !important;
  transition: all 0.2s ease-in-out !important;
}

.FileListDelete {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0.01em !important;
  color: #eb534a !important;
  padding: 6px 11px !important;
  min-width: 20px !important;
  transition: all 0.2s ease-in-out !important;
}

.FileListRename:hover,
.FileListDelete:hover,
.FileListCancel:hover {
  background: transparent !important;
}

.FileListRename,
.FileListDelete {
  opacity: 0;
}

.UpFileList {
  width: 50%;
  width: CalC(50% - 15px);
  padding: 15px 0;
}

.UpFileListUnder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px #ececec;
  border-radius: 8px;
  padding: 16px 8px 16px 16px;
  /* margin-bottom: 16px; */
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 67px;
}

.UpFileListUnder:hover {
  background-color: rgba(42, 91, 238, 0.1);
}

.UpFileListUnder:hover .FileListRename,
.UpFileListUnder:hover .FileListDelete,
.UpFileListUnder.Active .FileListRename,
.UpFileListUnder.Active .FileListDelete {
  opacity: 1;
}

.FileName {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0;
  display: flex;
  align-items: center;
  width: 300px;
  min-width: 300px;
}

.FileName span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.FileName svg {
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  color: #2a5bee;
  margin-right: 12px;
  stroke-width: 1.5;
}

.FileSize {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #949494;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  position: relative;
  width: 180px;
  min-width: 180px;
  text-align: center;
}

.FileListRight {
  display: flex;
  align-items: center;
}

.FileListRight p.FileSize:last-of-type {
  margin: 0;
  padding: 0;
}

.FileListRight p::after {
  content: "";
  width: 2px;
  height: 10px;
  background: #cdcdcd;
  position: absolute;
  top: 53%;
  right: -2px;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  border-radius: 0;
}

.FileListRight p:last-of-type:after {
  display: none;
}

/* .FileListRight a,
.FileListRight button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2C2C2C;
  background-color: #fff;
  margin-top: -3px;
  border: none;
  padding: 0;
  margin-left: 8px;
} */

.FileListRight a svg,
.FileListRight button svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.5;
}

.TermText {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 0 0;
  padding-bottom: 20px;
}

.TermTextTitle {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0 0 0;
  color: #000;
  padding-bottom: 20px;
}

.TermText li {
  padding: 5px 0;
}

.TermCheckBox {
  margin-bottom: 40px;
  width: 100%;
}

.SignedButton {
  width: 241px;
  height: 56px;
  background: #2fa42b;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.SignedButton .Icon {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background: #fff;
  border-radius: 50%;
  color: #2fa42b;
}

.SignedButton .Icon svg {
  width: 16px;
  height: auto;
  margin-top: 2px;
  stroke-width: 3px;
}

.DahhboardHead {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 20px 0 25px 0;
}

.SwitchDiv {
  width: 220px;
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.SwitchDiv p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
}

.SwitchDiv > div {
  width: 100%;
  font-size: 0.875rem;
}

.AccBalanceSec {
  display: flex;
  margin-right: auto;
}

.AccBalanceSec>div {
  padding: 0 20px;
}

.AccBalanceTT {
  margin: 0;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  margin-bottom: 3px;
}

.AccBalanceAmount {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #343434;
}

.ActionsBULine {
  width: 150px;
  height: 48px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #502688 !important;
  background-color: #fff !important;
  margin: 0;
  border: none !important;
  padding: 11px 15px 11px 18px !important;
  border-radius: 8px !important;
  border: 1px solid #502688 !important;
}

.ActionsBULine:after {
  display: none;
}

.ActionsBULine:focus {
  outline: none;
}

.ActionsBULine svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: -2px;
  margin-left: 10px;
}

.ActionsBULine:after {
  display: none !important;
}

.CheckFilter {
  display: flex;
  flex-wrap: wrap;
}

.CheckFilter label {
  width: 100%;
  margin: 0;
}

.AccountHolderDesSec {
  width: 100%;
  margin-bottom: 15px;
}

.AccountHolderTitle {
  width: 100%;
  margin: 0 0 8px 0;
  font-size: 15px;
  color: #343434;
  font-weight: 600;
  font-family: "Inter", sans-serif !important;
}

.AccountHolderDesRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
}

.AccountHolderDesRow:last-child {
  border-bottom: none;
}

.DesSmallSec {
  width: 24%;
  padding: 5px 0;
}

.DesBigSec {
  width: 49%;
  padding: 5px 0;
}

.DesSmallSec p,
.DesBigSec p {
  margin: 0;
  font-size: 14.5px;
  color: #343434;
  font-weight: 500;
}

.DesSmallSec p span,
.DesBigSec p span {
  margin: 0 8px 0 0;
  color: #666;
  font-weight: 400;
}

.CardInsideCon {
  width: 100%;
  padding-left: 25px;
  position: relative;
}

.InsideConNameNotes {
  font-size: 15px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 3px;
  padding: 0px 20px 0px 0;
  /* min-width: 350px; */
}

.InsideConNameNotes span {
  color: #b7b7b7;
  font-weight: 500;
}

.DropDownMain {
  position: absolute !important;
  right: 0;
  top: 0;
}

.FilesUpCardDD {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 20px;
  margin: 0 !important;
  padding: 6px 8px;
  background-color: transparent !important;
  color: #000 !important;
  border: none;
  border-color: transparent !important;
  box-shadow: none !important;
}

.FilesUpCardDD:active,
.FilesUpCardDD:checked,
.FilesUpCardDD:hover,
.FilesUpCardDD:focus {
  border: none;
  box-shadow: none !important;
}

.FilesUpCardDD.NoPadd,
.FilesUpCardDD.NoPadd:active,
.FilesUpCardDD.NoPadd:checked,
.FilesUpCardDD.NoPadd:hover,
.FilesUpCardDD.NoPadd:focus {
  padding: 0;
}

.FilesUpCardDD:after {
  display: none !important;
}

.FilesUpCardDD svg {
  width: 18px !important;
}

.InsideConDate {
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  color: #a7abc3;
}

.InsideConTextNote {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #505050;
  margin-bottom: 0;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.ProfileConSec {
    width: 100%;
    border-bottom: 1px solid #dee2e6;
    padding: 0 0 10px 0;
    margin: 0 0 15px 0;
}

.ProfileConSec:last-child {
    border-bottom: none;
    margin: 0;
    padding: 0;
}

.SMScoreCardDe {
  text-align: center;
  padding: 12px 4px;
  /* margin: 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.SMScoreCardDeTitle {
  font-size: 16px;
  padding: 0 0 8px 0;
  font-weight: 600;
  color: #505050;
  line-height: 150%;
  margin: 0;
  text-align: left;
}

.SMScoreCardDe ul {
  margin: 0;
  padding: 0;
}

.SMScoreCardDe ul li {
  display: flex;
  font-size: 14px;
  padding: 8px 0;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  list-style: none;
}

.SMScoreCardDe ul li .SMProDETag {
  /* color: #b2b5cb; */
  margin-right: 10px;
  display: flex;
  align-items: center;
  min-width: 150px;
  white-space: nowrap;
}

.SMScoreCardDe ul li .SMProDE {
  /* margin-left: AUTO; */
  text-align: left;
  color: #343434;
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.SMScoreCardDe ul li .SMProDE svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 2px;
}

.SMScoreCardDe ul li a.SMProDE:hover,
.SMScoreCardDe ul li a.SMProDE {
  color: #f48272;
  position: relative;
  transition: all .3s ease-in-out;
}

.SMScoreCardDe ul li a.SMProDE::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #f48272;
  bottom: 0;
  left: 0%;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.SMScoreCardDe ul li a.SMProDE:hover::after {
  opacity: 1;
}

@media screen and (min-width: 360px) {
    .InsideConNameNotes {
        width: 100%;
        padding-right: 0;
    }
    .CardInsideCon {
        padding-left: 0;
        padding-top: 25px;
    }
    .NotesCard {
        padding: 15px 15px;
    }
    .NotesCardInside {
        flex-wrap: wrap;
    }
    .FileListRename,
    .FileListDelete {
        padding: 6px 6px !important;
    }
    .FileListRight {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0px, -50%);
    }
    .FileSize {
        padding: 0 0 0 37px;
        width: 190px;
        min-width: 180px;
        text-align: left;
    }
    .FileName {
        width: 190px;
        min-width: 100px;
    }
    .UpFileList {
        width: 100%;
    }
    .UpFileListUnder {
        flex-wrap: wrap;
        position: relative;
        padding: 10px 8px 10px 16px;
        ;
    }
    .FileIMGDiv {
        padding: 12px 0;
    }
    .InsideConName {
        width: 20%;
    }

    .ApplicantDiv {
        width: 25%;
    }

    .ApplicantAction {
        width: 20%;
    }

    .mainCardDiv {
        padding-right: 0px;
    }

    .FilterOnOffBU {
        display: block;
    }

    .BodyFilterArea {
        right: -280px;
    }

    .BodyFilterArea.Open {
        right: 0px;
    }

    .InsideConName {
        width: 100%;
    }

    .ApplicantDiv {
        width: 100%;
        padding: 0px 15px;
    }

    .ApplicantAction {
        width: 100%;
        flex-wrap: wrap;
    }

    .ApplicantName {
        width: 100%;
    }

    .ApplicantNameMail {
        width: 100%;
    }

    span.ConNameTrancate {
        max-width: 100%;
    }
}

@media screen and (min-width: 480px) {
    .FilterPill span {
        max-width: 100%;
        display: unset;
        margin-bottom: 0px;
    }
    .FileName {
        width: 300px;
        min-width: 100px;
    }
}

@media screen and (min-width: 576px) {
    .InsideConNameNotes {
        width: auto;
        padding-right: 20px;
    }
    .NotesCard {
        padding: 15px 25px;
    }
    .FileListRename,
    .FileListDelete {
        padding: 6px 10px !important;
    }
    .FileListRight {
        position: unset;
        top: unset;
        right: unset;
        transform: unset;
    }
    .FileSize {
        padding: 0 0 0 0px;
        width: 160px;
        min-width: 160px;
        text-align: center;
    }
    .FileName {
        width: 200px;
        min-width: 100px;
    }
    .UpFileListUnder {
        flex-wrap: nowrap;
    }
    /* .InsideConName {
    width: 100%;
  } */

    .ApplicantName {
        width: 150px;
    }

    .ApplicantNameMail {
        width: 150px;
    }

    span.ConNameTrancate {
        max-width: 300px;
    }
}

@media screen and (min-width: 768px) {
    .CardInsideCon {
        padding-left: 25px;
        padding-top: 0;
    }
    .NotesCardInside {
        flex-wrap: nowrap;
    }
    .AccBalanceSec>div {
        padding: 0 15px;
    }
    .SwitchDiv {
        width: 200px !important;
    }
    .TableSmallTitle {
        display: none;
    }
    .ProspectTaskTable table tr th:nth-child(1) {
        width: 20%;
    }
    /* .InsideConName {
    width: 100%;
  } */
    .ApplicantDiv {
        width: 100%;
        padding: 0px 15px;
    }

    .ApplicantAction {
        width: 60%;
        flex-wrap: wrap;
        justify-content: unset;
    }

    .ApplicantName {
        width: 180px;
    }

    .ApplicantNameMail {
        width: 280px;
    }

    span.ConNameTrancate {
        max-width: 400px;
    }
}

@media screen and (min-width: 992px) {
    .ProspectCardPop {
        width: 50%;
    }
    .UpFileList {
        width: CalC(50% - 15px);
    }
    .SwitchDiv {
        width: 260px !important;
    }
    .AccBalanceSec>div {
    padding: 0 20px;
  }
  .DesSmallSec {
    width: 24%;
  }
  .DesBigSec {
    width: 49%;
  }
}

@media screen and (min-width: 1100px) {
    .DesSmallSec {
        width: 49%;
    }
    .DesBigSec {
        width: 100%;
    }
    .AccBalanceSec{
        order: 3;
        width: 300px;
        justify-content: space-between;
    }
    .AccBalanceSec>div {
        padding: 0 0;
        margin: 25px 0 0 0;
    }
    .UpFileList {
        width: 100%;
    }
    /* .InsideConName {
    width: 100%;
  } */
    .ApplicantDiv {
        width: 67%;
        padding: 0px 15px;
    }

    .ApplicantAction {
        width: 33%;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .ApplicantName {
        width: 150px;
    }

    .ApplicantNameMail {
        width: 250px;
    }

    span.ConNameTrancate {
        max-width: 500px;
    }
}

@media screen and (min-width: 1200px) {
    .AccBalanceSec{
        order: unset;
        width: auto;
        justify-content: unset;
    }
    .AccBalanceSec>div {
        padding: 0 20px;
        margin: 0 0 0 0;
    }
    .SwitchDiv {
      width: 220px !important;
    }
    .Width220 {
        min-width: 190px;
    }

    .Width180 {
        min-width: 160px;
    }

    .ProsUserName .LimitName {
        width: 170px;
    }

    /* .InsideConName {
    width: 100%;
  } */
    /* .ApplicantDiv {
    width: 50%;
  }
  .ApplicantDivHalf {
    width: 100%;
  }
  .ApplicantDivHalf:last-child {
    width: 100%;
  }
  .ApplicantAction {
      width: 100%;
      flex-wrap: wrap;
  } */
    .SearchCon {
        width: 100%;
        margin-bottom: 20px;
    }

    .FilterSec {
        width: 100%;
    }

    .FilterBu {
        display: none;
    }

    .FilterTitle {
        display: none;
    }
}

@media screen and (min-width: 1280px) {
    .AccBalanceSec>div {
        padding: 0 20px;
    }
    /* .InsideConName {
    width: 30%;
  } */
    .ApplicantDiv {
        width: 70%;
    }

    .ApplicantAction {
        width: 30%;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .ApplicantName {
        width: 180px;
    }

    .ApplicantNameMail {
        width: 280px;
    }

    span.ConNameTrancate {
        max-width: 600px;
    }
}

@media screen and (min-width: 1300px) {
    .DesSmallSec {
        width: 24%;
    }
    .DesBigSec {
        width: 49%;
    }
    .ProsUserName .LimitName {
        width: calc(100% - 80px);
    }

    .ModalFormGroupAdd {
        max-width: 1120px;
        min-width: 1036px;
    }

    .ProspectCardPop {
        width: 33.33%;
    }
}

@media screen and (min-width: 1400px) {
    .ProspectTaskTable table tr th:nth-child(1) {
        width: 40%;
    }
    .UpFileList {
        width: CalC(50% - 15px);
    }
}

@media screen and (min-width: 1360px) {
    .Width220 {
        min-width: 220px;
    }

    .Width180 {
        min-width: 180px;
    }
}

@media screen and (min-width: 1500px) {
    .NotesCard {
        padding: 15px 25px;
    }
    /* .InsideConName {
    width: 20%;
  } */
    /* .mainCardDiv {
    padding-right: 280px;
  } */

    .FilterOnOffBU {
        display: none;
    }

    .BodyFilterArea {
        right: 0px;
    }

    /* .AddNoteBu {
    display: none;
  } */

    .ApplicantDiv {
        width: 70%;
    }

    .ApplicantAction {
        width: 30%;
    }

    .FilterTitle svg {
        display: none;
    }
}

@media screen and (min-width: 1600px) {
    .FileIMGDiv {
        padding: 12px;
    }
    .ProspectTaskTable table tr th:nth-child(1) {
        width: 50%;
    }
    /* .InsideConName {
    width: 30%;
  } */
    .ApplicantDiv {
        width: 70%;
    }

    .ApplicantAction {
        width: 30%;
        flex-wrap: wrap;
    }

    .SearchCon {
        width: 25%;
        margin-bottom: 0;
    }

    .FilterSec {
        width: 75%;
    }
}

@media screen and (min-width: 1800px) {

    /* .InsideConName {
    width: 20%;
  } */
    .ApplicantDiv {
        width: 75%;
    }

    .ApplicantAction {
        width: 25%;
        flex-wrap: wrap;
    }

    .ApplicantName {
        width: 250px;
    }

    .ApplicantNameMail {
        width: 350px;
    }

    .SearchCon {
        width: 35%;
    }

    .FilterSec {
        width: 65%;
    }
}

@media screen and (min-width: 1700px) {
    .imgDiv img {
        margin: 0;
    }

    .allClientsNameInner span {
        width: 250px;
        text-align: left;
        display: inline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 0;
    }
}

@media (max-width: 1199px) {
    .PanelUnder {
        width: calc(100% - 85px);
        right: -100%;
    }
    .FilterSec {
        width: 220px;
    }

    .SearchCon {
        width: calc(100% - 220px);
    }

    .FilterFieldSec {
        width: 280px;
        height: calc(100% - 68px);
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        background: #fff;
        top: 60px;
        z-index: 99;
        right: -300px;
        align-content: flex-start;
        padding: 40px 15px;
        box-shadow: -3px 0px 20px rgb(62 85 120 / 8%);
        border-left: 1px solid #ebebeb;
        transition: all 0.2s ease-in-out;
    }

    .FilterFieldSec.Open {
        right: 0;
    }

    .FilterCard {
        width: 100%;
        padding: 10px 10px;
    }
}

@media (max-width: 991px) {
    .DesSmallSec {
        width: 49%;
    }
    .DesBigSec {
        width: 100%;
    }
    .ProspectCardPop {
        width: 50%;
    }

    .ProspectCardHeadSection {
        flex-wrap: wrap;
        justify-content: center;
    }

    .ProspectCardHeadSection .HeadConSec {
        width: 100%;
    }

    .ProspectCardHeadSection .ProsIMGSec {
        min-width: 90px;
        width: 90px;
        min-height: 90px;
        height: 90px;
        border-radius: 50%;
        margin-right: 0px;
        margin-bottom: 16px;
    }

    .AffordBadge {
        top: 32px;
    }
}

@media (max-width: 959px) {
    .PanelUnder {
        width: 100%;
    }
    .pageTitle.Mobile {
        display: flex;
    }

    .pageTitle.Big {
        display: none;
    }

    .breadcrumb {
        display: none;
    }

    .pageTitleWrap {
        padding: 1rem 1.8rem 1.5rem 1.8rem;
    }

    .FilterFieldSec {
        top: 0;
    }
}

@media (max-width: 767px) {
    .DropDownMain {
        top: -50px;
    }
    .ActionsBULine {
        width: 100%;
    }
    .AccBalanceSec{
        order: 3;
        width: 300px;
        justify-content: space-between;
    }
    .AccBalanceSec>div {
        padding: 0 2px;
        margin: 25px 0 0 0;
    }
    .SwitchDiv {
        width: 220px !important;
    }
    .DahhboardHead {
        flex-wrap: wrap;
    }
    .UpFileList {
        width: 100%;
    }
    .FormGroupHalf {
        width: 100%;
    }
    .ToggleBUHalf {
        width: 100%;
    }
    .ProEditSec.Table {
        right: -4px;
        top: -100px;
    }
    .TourconfirmedBadge,
    .TourpendingBadge {
        right: 65px;
        top: 12px;
    }
    .TableSmallTitle {
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 22px !important;
        color: #230058 !important;
        text-transform: uppercase;
    }
    .ProspectTaskTable table tr:nth-child(1) {
        display: none;
    }

    .ProspectTaskTable table tr {
        display: table;
        width: 100%;
    }

    .ProspectTaskTable table tr td {
        display: flex;
        width: 100%;
        height: auto;
        padding: 5px 15px;
        justify-content: space-between;
    }

    .ProspectTaskTable table tr td:nth-child(2),
    .ProspectTaskTable table tr td:nth-child(3) {
        border-top: 1px solid #f8f8f8;
    }

    .ProspectTaskTable table tr:nth-child(even) td:nth-child(2),
    .ProspectTaskTable table tr:nth-child(even) td:nth-child(3) {
        border-top: 1px solid #f2f2f2;
    }
    .ProspectTaskTable table tr td p.Emoji {
        margin-right: 25px;
    }

    .ProspectTaskTable table tr td p {
        text-align: right;
    }
    .ProspectCardPop {
        width: 100%;
    }

    .AddNoteBu span {
        display: none;
    }

    .AddNoteBu svg {
        margin-right: 0;
    }

    .AddNoteBu,
    .FilterBu {
        margin: 0 5px;
    }

    .FilterSec {
        width: 130px;
    }

    .SearchCon {
        width: calc(100% - 130px);
    }

    .mainCardDiv {
        padding-bottom: 80px;
    }

    .BackAddBU {
        display: flex !important;
    }

    .AddprospectFirstStepForm {
        padding-top: 25px;
    }

    .FilterCard.SelectPros {
        padding: 25px 0px 5px 0;
    }

    .AddTourTimeArea ul {
        padding: 0 0px 0 0 !important;
    }

    .AddTourTimeArea ul::-webkit-scrollbar {
        display: none;
    }

    .AddTourTimeArea ul {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
}

@media (max-width: 649px) {
    .AddTourTimeArea {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .HalfStepFormFullSec {
        width: 100% !important;
    }
    .SwitchDiv {
        width: 200px !important;
    }
    .AccBalanceSec{
        width: 100%;
    }
}

@media (max-width: 479px) {
    .DesSmallSec {
        width: 100%;
    }
    .AccountDropdown {
        width: 100%;
        margin: 15px 0 0 0;
    }
    .SwitchDiv {
        width: 100% !important;
    }
    .LeaderboardListSec {
        width: 400px;
        min-width: 400px;
    }
    .PageMainTTSec .CountText {
        margin: 0 0 0 50px;
    }
    .PageMainTTSec {
        flex-wrap: wrap;
    }
    .mainCard {
        padding: 0;
        margin: 0 0.8rem;
        background: transparent;
        box-shadow: unset;
    }

    .ProspectCardHeadSection .ProsIMGSec {
        min-width: 60px;
        width: 70px;
        min-height: 60px;
        height: 70px;
    }

    .PagiPaddi {
        justify-content: center;
    }

    .PagiPaddi nav {
        width: 100%;
    }

    .PagiPaddi nav ul {
        justify-content: center;
    }

    .pageTitle {
        width: auto;
    }

    .breadcrumb {
        width: 100%;
        order: 1;
        padding-bottom: 12px;
    }

    .ButtonAction {
        display: flex;
        justify-content: space-between;
    }

    .pageTitleWrap {
        padding: 1rem 0.8rem 1rem 0.8rem;
    }
}

img.Varified {
    width: 20px;
    margin-left: 8px;
}